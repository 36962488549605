import VacancyAPi from "@/api/vacancies.js";
const state = {
  isLoading: false, //ожидание запроса
  validationErrors: null, //ошибки
  vacancies: [],
};

export const mutationTypes = {
  getVacanciesStart: "[vacancies] getDepartmentStart",
  getVacanciesSuccess: "[vacancies] getDepartmentSuccess",
  getVacanciesFailure: "[vacancies] getDepartmentFailure",
};
export const actionTypesVacancies = {
  getVacancies: "[vacancies] getVacancies",
};

const mutations = {
  //Получение вакансий
  [mutationTypes.getVacanciesStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getVacanciesSuccess](state, info) {
    state.isLoading = false;
    state.vacancies = info
  },
  [mutationTypes.getVacanciesFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
};
const actions = {
  [actionTypesVacancies.getVacancies](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getVacanciesStart);
      VacancyAPi.getVacancies()
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.getVacanciesSuccess,
                  response.data.result
              );
            } else {
              context.commit(mutationTypes.getVacanciesFailure, response);
            }
            resolve(response.data);
          })
          .catch((response) => {
            context.commit(mutationTypes.getVacanciesFailure, response);
            console.log("ошибка", response);
          });
    });
  },
};

export default {
  state,
  mutations,
  actions,
};
