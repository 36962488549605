<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row general-info__align-items">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title">
            <h1 class="text-TTDrugs-Bold-40 color-green left text-uppercase">Молитвенная комната</h1>
          </div>
          <div class="general-info__block-row">
            <div class="">
              <p>В центре "ДАР" пациенты могут посетить молитвенную комнату.</p>
              <p>Каждую среду в 13:30 священник приглашает Вас на беседу, молитвенный чин о помощи в родах, вынашивании и т.п. Вы также можете задать волнующие Вас вопросы.</p>
              <p>Для совершения таинства исповеди и причастия Вы можете самостоятельно пригласить священника:</p>
              <ul class="under-department-content__list">
                <li>Священник Михаил Бень, тел. 8 (913) 213-64-15</li>
                <li>Священник Сергий Прохоров, тел. 8 (913) 259-37-27</li>
              </ul>
              <div class="general-info__index-mg-mini">
                <img src="../../assets/img/room.jpg" alt="room">
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="under-department-content">

      <div class="article-item__images">
        <div class="article-item__image--detail">
          <img src="../../assets/img/room1.jpg"  alt="room1" class="under-department-content__image">
        </div>
        <div class="article-item__image--detail">
          <img src="../../assets/img/room2.jpg"  alt="room2" class="under-department-content__image">
        </div>
        <div class="article-item__image--detail">
          <img src="../../assets/img/room3.jpg"  alt="room3" class="under-department-content__image">

        </div>
      </div>

      </div>
    </div>
  </div>

</template>
<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  export default {
    name: "AppPrayerRoom",
    components: {   DynamicMenu },
    metaInfo() {
      return {
        title: 'Молитвенная комната | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Подробная информация о Алтайском краевом клиническом перинатальном центре ДАР'
          },
          {
            name: 'keywords',
            content: 'пациентам, алтайский краевой клинический перинатальный центр, перинатальный центр, дар'
          }
        ]
      };
    },
    data() {
      return {
        nameDepartment: 'Молитвенная комната',
        linksDepartment: 'prayer-room"',
        menuItems: [
          { text: "Услуги ОМС", link: "/oms", active: false },
          { text: "Правила записи на прием", link: "/rules", active: false },
          { text: "Анализы", link: "/tests", active: false },
          { text: "Госпитализация", link: "/hospitalization", active: false },
          { text: "Иностранным гражданам", link: "/foreigners", active: false },
          { text: "Справка для налогового вычета", link: "/tax-refund", active: false },
          { text: "График прима граждан", link: "/schedule", active: false },
          { text: "Список продуктов для передачи", link: "/list-product", active: false },
          { text: "Молитвенная комната", link: "/prayer-room", active: true },
          { text: "Медицинский туризм", link: "/medical-tourism", active: false }
        ],

      }
    },

  };
</script>

