<template>
  <div class="general-info">
    <div class="general-info__container">
      <AppBreadCrumbs/>
      <div class="general-info__title-left">
        <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">Персональный врач на роды</h1>
      </div>

        <div class="under-department-content">
          <div class="under-department-content__first-block">
            <h2 class="bold">Персональный врач на роды</h2>

            <p>Перинатальный центр «ДАР» всегда стремится соответствовать пожеланиям пациенток, поэтому теперь у нас есть возможность выбора врача, который будет принимать роды. Женщины могут детально обсудить предстоящее самое важное событие в их жизни с понравившимся врачом акушер-гинекологом и чувствовать себя более спокойно и уверенно.</p>

            <p>Стоимость услуги «Персональный врач на роды» составляет 40 000 рублей.</p>

            <p>Договор заключается после 36 недель беременности.</p>

            <p>С полным перечнем оказываемых услуг и действующими ценами на них вы можете ознакомиться на нашем сайте в разделе «Услуги», подраздел «Платные услуги».</p>

          </div>
          <div class="under-department-content__second-block">
            <div class="under-department-content__second-block-image">
              <img src="../../assets/img/rody/rody1.jpg" alt="Роды 1" class="under-department-content__image" />
            </div>
            <div class="under-department-content__second-block-text">
              <h2 class="bold">Наши специалисты, осуществляющие персональное ведение родов</h2>
              <ul class="under-department-content__list-max">
                <li><b>Буркова Татьяна Викторовна</b>, заместитель главного врача по акушерско-гинекологической помощи. Общий стаж 18 лет, врачом акушером-гинекологом 9 лет, кандидат медицинских наук, высшая категория.</li>
                <li><b>Колядо Ольга Викторовна</b>, заместитель главного врача по контролю качества медицинской помощи. Общий стаж 14 лет, врачом акушером-гинекологом 9 лет, кандидат медицинских наук, высшая категория.</li>
                <li><b>Баталина Ирина Витальевна</b>, заведующий отделением, врач акушер-гинеколог. Общий стаж 21 год, врачом акушером-гинекологом 19 лет, высшая категория.</li>
                <li><b>Хабаров Евгений Федорович</b>, врач акушер-гинеколог, общий стаж 36 лет, врачом акушером-гинекологом 28 лет, высшая категория.</li>
                <li><b>Андрияхова Маргарита Александровна</b>, заведующий отделением, врач акушер-гинеколог. Общий стаж 9 лет, врачом акушером-гинекологом 9 лет, первая категория.</li>
                <li><b>Гузненко Светлана Григорьевна</b>, врач акушер-гинеколог. Общий стаж 12 лет, врачом акушером-гинекологом 8 лет, высшая категория.</li>
                <li><b>Кобзева Дарья Аркадьевна</b>, врач акушер-гинеколог. Общий стаж 7 лет, врачом акушером-гинекологом 6 лет.</li>
                <li><b>Санаева Мария Дмитриевна</b>, врач акушер-гинеколог. Общий стаж 11 лет, врачом акушером-гинекологом 6 лет.</li>
                <li><b>Тарасенко Александр Павлович</b>, врач акушер-гинеколог. Общий стаж 15 лет, врачом акушером-гинекологом 9 лет.</li>
                <li><b>Дурягина Светлана Геннадьевна</b>, заведующий отделением, врач акушер-гинеколог, высшая категория, кандидат медицинских наук. Общий стаж 21 год.</li>
                <li><b>Чукмасова Анна Юрьевна</b>, врач акушер-гинеколог. Общий стаж работы врачом акушером-гинекологом 25 лет.</li>
                <li><b>Федорова Анастасия Владимировна</b>, врач акушер-гинеколог. Общий стаж 10 лет, врачом акушером-гинекологом 5 лет.</li>
                <li><b>Смолихина Валентина Юрьевна</b>, врач акушер-гинеколог. Общий стаж 27 лет, высшая категория.</li>
              </ul>

            </div>
          </div>
        </div>
      <div class="general-info-department">
        <div class="general-info-department__info-block">
          <div class="general-info-department__title-left">
            <p class="text-TTDrugs-Bold-40 color-green center text-uppercase">Специалисты</p>
          </div>
          <div class="general-info-department__description">
            <p class="text-circe-20 color-gray">Наши специалисты </p>
          </div>
        </div>
        <div class="general-info-department__thank">
          <BlockThankYou/>
        </div>
      </div>
      <AppDoctorsPeopleList :people_list="doctors"/>
    </div>
  </div>
</template>

<script>
  import AppBreadCrumbs from '@/components/BreadCrumbs.vue'
  import AppDoctorsPeopleList from '@/components/People/DoctorsPeopleList.vue'
  import BlockThankYou from '@/components/BlockThankYou.vue'
  import { actionTypesDepartment } from '@/store/modules/department'
  import { mapState } from 'vuex'

  export default {
    name: "AppPersonalDoctors",
    data() {
      return {
        nameDepartment: 'Акушерский стационар'
      }
    },
    metaInfo() {
      return {
        title: 'Персональный врач на роды | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Подготовьтесь к родам вместе с Алтайским краевым клиническим перинатальным центром ДАР. Узнайте о наших услугах по сопровождению родов, условиях пребывания в родильном доме, партнерских родах и послеродовом уходе. Мы обеспечим вам комфорт и безопасность на всех этапах родов.'
          },
          {
            name: 'keywords',
            content: 'роды, сопровождение родов, родильный дом, партнерские роды, послеродовой уход, перинатальный центр ДАР'
          }
        ]
      };
    },
    components: { BlockThankYou, AppDoctorsPeopleList,  AppBreadCrumbs },
    props: {

    },
    computed: {
      ...mapState({
        doctors: (state) => state.department.doctors,
        isLoading: (state) => state.department.isLoading,
      }),
    },
    methods: {
      getDoctorsUnderDepartment() {
        this.$store.dispatch(actionTypesDepartment.getDoctorsDepartment,this.nameDepartment)
            .then(() => {
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      }
    },
    mounted() {
      this.getDoctorsUnderDepartment()
    }

  };
</script>

<style scoped>

</style>
