<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row general-info__align-items">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title"><h1 class="text-TTDrugs-Bold-40 color-green  text-uppercase">Центр медико-социальной поддержки женщин, оказавшихся в трудной жизненной ситуации</h1></div>
          <div class="general-info__index-mg">
            <AppSwiperDepartments :list_image="slides"/>
          </div>
        </div>
      </div>
      <div class="under-department-content">
        <div class="under-department-content__first-block">
          <p><span class="bold">Всех будущих мам волнует</span> вопрос: кем станет мой ребенок - будет он аутсайдером или победителем? Какой будет его жизнь – активной и наполненной яркими событиями или в «ожидании у моря погоды»?</p>
          <p>Научные исследования последних лет убедительно подтверждают <span class="bold">влияние перинатального опыта</span> на последующую жизнь человека. Плод имеет долговременную память, которая запечатлевает события, происходящие во время беременности и родов и эмоциональное состояние беременной мамы. Эта информация «хранится» в его памяти на протяжении всей последующей жизни, определяя стиль жизни и <span class="bold">способность найти свое «место под солнцем».</span></p>
          <p><span class="bold">Влияние эмоционального состояния женщины</span> на течение беременности, родов и, следовательно, на здоровье еще не родившегося ребенка, а также зависимость психического развития ребенка от психологических проблем его родителей, определяют важность работы перинатального психолога и специалиста по социальной работе с семьей, ожидающей рождения малыша.</p>
          <p><span class="bold">Спокойствие и уверенность матери в себе</span>, общение с близкими, дарящими будущему члену семьи заботу и внимание, <span class="bold">способствуют гармоничному формированию здорового ребенка.</span></p>
          <p><span class="bold">Работа психолога с родителями</span> по повышению родительской осознанности и ответственности, а также по развитию их личностных ресурсов, имеет важное значение в профилактике нарушений здоровья и поведения ребенка и сразу после его рождения, и в дальнейшем. Только <span class="bold">комплексный подход</span> к столь серьезному событию – рождению ребенка, может гарантировать благополучную беременность, <span class="bold">появление на свет здорового малыша, полноценное чувство радости и гармонии от материнства и отцовства.</span></p>
          <p>Кроме того, специалисты Центра координируют работу психологов и специалистов по социальной работе районных медицинских учреждений и активно помогают коллегам на местах в оказании помощи и поддержки женщинам, планирующим беременность или находящимся в ситуации репродуктивного выбора.</p>
        </div>
        <div class="under-department-content__second-block">
          <div class="under-department-content__second-block-image">
            <img src="../../assets/img/departments/support/support5.jpg" alt="Центр поддержки" class="under-department-content__image" />
          </div>
          <div class="under-department-content__second-block-text">
         <AppTest/>
          </div>
        </div>
      </div>
      <div class="general-info-department">

        <div class="general-info-department__info-block">
          <div class="general-info-department__title-left">
            <p class="text-TTDrugs-Bold-40 color-green center text-uppercase">Специалисты</p>
          </div>
        </div>
        <div class="general-info-department__thank">
          <BlockThankYou/>
        </div>
      </div>
      <AppDoctorsPeopleList :people_list="doctors"/>
    </div>
    <div class="general-info-article-under-department">
      <app-article-departments-list :name_department="linksDepartment" />
    </div>
  </div>


</template>
<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  import BlockThankYou from '@/components/BlockThankYou.vue'
  import AppSwiperDepartments from '@/components/Gallery/SwiperDepartments.vue'
  import { actionTypesDepartment } from '@/store/modules/department'
  import { mapState } from 'vuex'
  import AppTest from '@/components/TestOne.vue'
  import AppArticleDepartmentsList from '@/components/Article/ArticleDepartmentsList.vue'
  import AppDoctorsPeopleList from '@/components/People/DoctorsPeopleList.vue'

  export default {
    name: "AppSupportCenter",
    components: { AppDoctorsPeopleList, AppArticleDepartmentsList, AppTest, AppSwiperDepartments, BlockThankYou,   DynamicMenu },
    metaInfo() {
      return {
        title: 'Центр поддержки | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Отделения Алтайского краевого клинического перинатального центра ДАР. Центр поддержки. Подготовьтесь к родам вместе с Алтайским краевым клиническим перинатальным центром ДАР.'
          },
          {
            name: 'keywords',
            content: ' центр поддержки, роды, сопровождение родов, родильный дом, партнерские роды, послеродовой уход, перинатальный центр ДАР'
          }
        ]
      };
    },
    data() {
      return {
        nameDepartment: 'Центр поддержки',
        linksDepartment: 'support-center',
        menuItems: [
          { text: "Отделения", link: "/department", active: false },
          { text: "Поликлиника", link: "/department/policlinika", active: false },
          { text: "Акушерский стационар", link: "/department/akusherskij-stacionar", active: false },
          { text: "Неонатологический стационар", link: "/department/neonatologicheskij-stacionar", active: false },
          { text: "Центр гинекологии, восстановительной медицины и реабилитации", link: "/department/centr-ginekologii", active: false },
          { text: "Лабораторные и инструментальные методы обследования", link: "/department/laboratornye-i-instrumentalnye-metody-obsledovaniya", active: false },
          { text: "Отделение катамнестического наблюдения за детьми с перинатальной патологией", link: "/department/kabinet-katamnesticheskogo-nablyudeniya-za-detmi-s-perinatalnoj-patologiej", active: false },
          { text: "Центр медико-социальной поддержки женщин, оказавшихся в трудной жизненной ситуации", link: "/department/support-center", active: true },
          { text: "Вспомогательные службы", link: "/department/vspomogatelnye-sluzhby", active: false },
        ],
        slides: [
          { id: 1, image: require('../../assets/img/departments/support/support.jpg') },
          { id: 2, image: require('../../assets/img/departments/support/support1.jpg') },
          { id: 3, image: require('../../assets/img/departments/support/support2.jpg') },
          { id: 4, image: require('../../assets/img/departments/support/support3.jpg') },
          { id: 5, image: require('../../assets/img/departments/support/support4.jpg') },
          { id: 6, image: require('../../assets/img/departments/support/support5.jpg') },
          { id: 7, image: require('../../assets/img/departments/support/support6.jpg') },


        ]
      }
    },
    computed: {
      ...mapState({
        underDepartments: (state) => state.department.underDepartments,
        selectedDepartmentId: (state) => state.department.selectedDepartmentId,
        doctors: (state) => state.department.doctors
      }),
    },
    methods: {

      fetchArticleDepartment() {
        this.$store.dispatch(actionTypesDepartment.getArticleDepartment, this.nameDepartment)
            .then(() => {
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
      getDoctorsDepartment() {
        this.$store.dispatch(actionTypesDepartment.getDoctorsDepartment, this.nameDepartment)
            .then(() => {
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      }

    },
    mounted() {
      this.fetchArticleDepartment();
      this.getDoctorsDepartment();
    }
  };
</script>

