<template>
  <div class="section-reviews">
    <div class="section-reviews__container">
    <div class="section-reviews__wrap">
    <div class="section-reviews__block-info">
      <div class="section-reviews__title">
        <p class="text-TTDrugs-Bold-40 color-green">Отзывы о нашей работе</p>
      </div>
      <div class="section-reviews__info intro-reviews">
        <div class="intro-reviews__wrap-title">
          <div class="intro-reviews__logo"><img src="../../assets/svg/logo-img.svg" alt="Логотип"></div>
          <div class="intro-reviews__wrap-text">
            <div class="intro-reviews__title">
              <p class="text-TTDrugs-regular-24">400 +</p>
            </div>
            <div class="intro-reviews__description">
              <p class="text-circe-regular-14 color-gray">Довольных клиентов</p>
            </div>
          </div>
        </div>
        <div class="intro-reviews__btn">
          <button class="index-btn-mini" @click="goToReview">ПОСМОТРЕТЬ ВСЕ ОТЗЫВЫ</button>
        </div>
      </div>
    </div>
    <div class="section-reviews__reviews reviews">
      <div class="reviews__list">
        <vueper-slides class="custom-slider no-shadow" :autoplay="true" :autoplay-speed="5000" :bullets="false" arrows-outside transition-speed="500" fixed-height="400px" arrow-next-outside arrow-prev-outside >
          <vueper-slide
              v-for="review in firstOneReview"
              :key="review.id"
          >
            <template #content>
            <SectionItemReview :item_review="review" />
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>
    </div>
    </div>
    <div class="section-reviews__footer">
      <p class="text-circe-24 center">Больше отзывов о работе центра на "ПроДокторов" и "Спасибо, доктор"</p>
    </div>
    </div>
  </div>
</template>

<script>
  // import SectionItemNews from '@/components/News/SectionItemNews.vue'
  import { VueperSlides,VueperSlide } from 'vueperslides'
  import 'vueperslides/dist/vueperslides.css'
  import SectionItemReview from '@/components/Rewiews/SectionItemReviews.vue'
  import { actionTypesReviews } from '@/store/modules/reviews'
  import { mapState } from 'vuex'

  export default {
    name: 'SectionReviews',
    components: { SectionItemReview , VueperSlides,VueperSlide },
    data() {
      return {

      }
    },
    methods: {
      goToReview() {
        this.$router.push('/about/reviews')
      }
    },
    computed: {
      ...mapState({
        isLoading: (state) => state.reviews.isLoading,
        reviews: (state) => state.reviews.reviews,
      }),
      firstOneReview() {
        return this.reviews.slice(0, 3);
      },
    },
    mounted() {
      this.$store.dispatch(actionTypesReviews.getReviews)
          .then(() => {
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
    }
  }
</script>

<style>
.custom-slider {
  position: relative;
  .vueperslides__arrow--prev,
  .vueperslides__arrow--next {
    background: none;
    border: none;
    color: #ffffff; /* Ваш цвет стрелок */
    font-size: 20px;
    cursor: pointer;
  }
  .vueperslides__arrow--next {
    left: auto;
    right: 9.5em;
    bottom: 10px;
  }
  .vueperslides__arrow--prev {
    right: auto;
    bottom: 10px;
    left: 30.5em;
  }
  .vueperslides__arrows--outside {
    position: relative;
  }
  .vueperslides__arrow svg {

    width: 50px;
    height: 50px;
    padding: 15px;
    background: #66CC66;
    border-radius: 50%;
  }
}
.vueperslide {
  display: flex;
  justify-content: center;
  padding: 15px 15px;
}
</style>
