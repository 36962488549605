<template>
  <div class="article-list-departments__containers">
    <div class="article-list-departments__grid">

      <AppArticlePatientsItem v-for="itemArticle in patientsArticle" :key="itemArticle.id"  :article_item="itemArticle"/>

    </div>
  </div>
</template>

<script>

  import AppArticlePatientsItem from '@/components/Article/ArticlePatientsItem.vue'

  export default {
    name: "AppArticlePatientsList",
    data() {
      return {
      };
    },
    props: {
      patientsArticle: {
        type: Array,
        required: true,
      }
    },
    components: {
      AppArticlePatientsItem,

    },


  };
</script>

<style scoped>

</style>
