<template>
  <div class="section-swiper">
    <div class="section-swiper__container">
      <vueper-slides
          class="no-shadow"
          :visible-slides="3"
          slide-multiple
          :gap="3"
          :slide-ratio="1 / 4"
          :dragging-distance="200"
          :breakpoints="{ 800: { visibleSlides: 1, slideMultiple: false } }">

        <vueper-slide v-for="(slide) in slides" :key="slide.id"   :image="slide.image"
                       class="rounded-image"/>

      </vueper-slides>
    </div>
  </div>
</template>

<script>
  import { VueperSlides, VueperSlide } from 'vueperslides'
  import 'vueperslides/dist/vueperslides.css'
  export default {
    name: 'AppSwiperIndexView',
    components: { VueperSlides, VueperSlide },
    data() {
      return {
        slides: [
          { id: 1, image: require('../../assets/img/swiper/1.jpg') },
          { id: 2, image: require('../../assets/img/swiper/2.jpg') },
          { id: 3, image: require('../../assets/img/swiper/3.jpg') },
          { id: 4, image: require('../../assets/img/swiper/4.jpg') },
          { id: 5, image: require('../../assets/img/swiper/5.jpg') },
          { id: 6, image: require('../../assets/img/swiper/6.jpg') },
          { id: 7, image: require('../../assets/img/swiper/7.jpg') },
          { id: 8, image: require('../../assets/img/swiper/8.jpg') },
          { id: 9, image: require('../../assets/img/swiper/9.jpg') },
          { id: 10, image: require('../../assets/img/swiper/10.jpg') },
          { id: 11, image: require('../../assets/img/swiper/11.jpg') },
          { id: 12, image: require('../../assets/img/swiper/12.jpg') },
          { id: 13, image: require('../../assets/img/swiper/13.jpg') },
          { id: 14, image: require('../../assets/img/swiper/14.jpg') },
          { id: 15, image: require('../../assets/img/swiper/15.jpg') },
        ]
      }
    },

    props: {

    },
    methods: {

    }
  }
</script>

<style scoped>
.rounded-image {
  border-radius: 20px; /* Здесь можно настроить степень закругления */
  //width: 100%;
  //height: auto;
}
</style>
