import NewsAPi from "@/api/news.js";
const state = {
  isLoading: false, //ожидание запроса
  validationErrors: null, //ошибки
  news: [],
  itemNews: {},
};

export const mutationTypes = {
  getNewsStart: "[news] getNewsStart",
  getNewsSuccess: "[news] getNewsSuccess",
  getNewsFailure: "[news] getNewsFailure",

  getNewsItemStart: "[news] getNewsItemStart",
  getNewsItemSuccess: "[news] getNewsItemSuccess",
  getNewsItemFailure: "[news] getNewsItemFailure",


};
export const actionTypesNews = {
  getNews: "[news] getNews",
  getNewsItem: "[news] getNewsItem"
};

const mutations = {
  //Получение Списка новостей
  [mutationTypes.getNewsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getNewsSuccess](state, info) {
    state.isLoading = false;
    state.news = info
  },
  [mutationTypes.getNewsFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //Получение новости по id
  [mutationTypes.getNewsItemStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getNewsItemSuccess](state, info) {
    state.isLoading = false;
    state.itemNews = info
  },
  [mutationTypes.getNewsItemFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
};
const actions = {
  [actionTypesNews.getNews](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getNewsStart);
      NewsAPi.getNews()
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.getNewsSuccess,
                  response.data.result
              );
            } else {
              context.commit(mutationTypes.getNewsFailure, response);
            }
            resolve(response.data);
          })
          .catch((response) => {
            context.commit(mutationTypes.getNewsFailure, response);
            console.log("ошибка", response);
          });
    });
  },
  // getNewsItem
  [actionTypesNews.getNewsItem](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getNewsItemStart);
      NewsAPi.getNewsItem(id)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.getNewsItemSuccess,
                  response.data.result
              );
            } else {
              context.commit(mutationTypes.getNewsItemFailure, response);
            }
            resolve(response.data);
          })
          .catch((response) => {
            context.commit(mutationTypes.getNewsItemFailure, response);
            console.log("ошибка", response);
          });
    });
  },
};

export default {
  state,
  mutations,
  actions,
};
