<template>
  <div>
    <div id="map" class="yandex-map"></div>
    <div class="custom-controls">
      <button @click="zoomIn" class="control-button">+</button>
      <button @click="zoomOut" class="control-button">-</button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "YandexMap",
    props: {
      center: {
        type: Array,
        default: () => [55.751574, 37.573856], // Центр карты по умолчанию (Москва)
      },
      zoom: {
        type: Number,
        default: 10, // Уровень масштабирования карты по умолчанию
      },
    },
    mounted() {
      this.initMap();
    },
    methods: {
      initMap() {
        // Проверяем, загружен ли уже API Яндекс.Карт
        if (window.ymaps) {
          this.createMap();
        } else {
          const script = document.createElement("script");
          script.src = `https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=0a85810d-f050-434c-ab6b-0851b6fda2a8`; // Замените ВАШ_API_КЛЮЧ на ваш API ключ
          script.onload = this.createMap;
          document.head.appendChild(script);
        }
      },
      createMap() {
        // Ждем, пока API Яндекс.Карт загрузится и станет доступным
        window.ymaps.ready(() => {
          this.map = new window.ymaps.Map("map", {
            center: this.center,
            zoom: this.zoom,
            type: 'yandex#hybrid', // Устанавливаем тип карты
            controls: [] // Убираем все стандартные элементы управления
          });

          // Отключаем увеличение карты при скролле
          this.map.behaviors.disable('scrollZoom');
// Убираем элементы, такие как "Создать свою карту"
          this.map.controls.remove('searchControl');
          this.map.controls.remove('trafficControl');
          this.map.controls.remove('geolocationControl');
          // Удаляем блоки с копирайтом и промо-контентом
          this.removeMapPromo();
          // Добавление маркера
          const placemark = new window.ymaps.Placemark(this.center, {
            hintContent: 'Мое местоположение',
            balloonContent: 'ДАР!',
          });
          this.map.geoObjects.add(placemark);
        });
      },
      zoomIn() {
        if (this.map) {
          const currentZoom = this.map.getZoom();
          this.map.setZoom(currentZoom + 1, { checkZoomRange: true });
        }
      },
      removeMapPromo() {
        const promoElements = document.querySelectorAll('.ymaps-2-1-79-map-copyrights-promo, .ymaps-2-1-79-copyright__content-cell');
        promoElements.forEach(el => el.style.display = 'none');
      },
      zoomOut() {
        if (this.map) {
          const currentZoom = this.map.getZoom();
          this.map.setZoom(currentZoom - 1, { checkZoomRange: true });
        }
      }
    }
  };
</script>

<style scoped>
.yandex-map {
  width: 100%;
  height: 500px; /* Установите нужную вам высоту */
}

</style>
