<template>
  <div class="news-index__item news-block-index">
    <div class="news-block-index__img">
      <img :src="item_news.imageUrl" :alt="item_news.title" @dragstart.prevent>
    </div>
    <div class="news-block-index__info">
      <div class="news-block-index__title">
        <p class="text-circe-bold-18 color-green">{{item_news.title}}</p>
      </div>
      <div class="news-block-index__description">
        <p class="text-circe-14">{{ item_news.description }}</p>
      </div>
      <div class="news-block-index__btn-wrap">
        <div class="news-block-index__btn" @click="routeNews">
          <span class="text-circe-18 color-green">ПОДРОБНЕЕ</span>
          <div class="news-block-index__btn-img"><img src="../../assets/svg/arrow-right.svg" alt="Подробнее"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SectionIndexItemNews',
    data() {
      return {

      }
    },
    props: {
      item_news: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    methods: {
      routeNews() {
        this.$router.push({ name: 'NewsItem', params: { id: this.item_news.id } });
      }
    }
  }
</script>

<style scoped>

</style>
