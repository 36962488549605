<template>
  <div class="test-container">
    <h2>Тест «Готовы ли вы к родам?»</h2>
    <p>Ответьте на вопросы «да» или «нет»</p>
    <form @submit.prevent="submitTest" class="test-form">
      <ol>
        <li v-for="(question, index) in questions" :key="index">
          <label class="text-circe-bold-18">{{ question }}</label>
          <div class="test-form-answer">
          <input type="radio" :name="'q' + index" value="yes" v-model="answers[index]"  class="text-circe-16"> Да
          <input type="radio" :name="'q' + index" value="no" v-model="answers[index]" class="text-circe-16" > Нет
          </div>
        </li>
      </ol>
      <div class="button__wrap">
      <button type="submit" class="index-btn-dark text-uppercase">Отправить</button>
      </div>
    </form>
    <div v-if="result !== null" class="result">
      <p v-if="result >= 8"  class="text-circe-bold-24 color-green">Вы хорошо подготовлены к родам. Ваш результат: {{result}}</p>
      <p v-else-if="result >= 5 && result < 8" class="text-circe-bold-24 color-green">Вам требуется приобрести знания по отдельным вопросам, чтобы быть полностью готовыми к родам. Ваш результат: {{result}}</p>
      <p v-else class="text-circe-bold-24 color-green">Вы не готовы к родам, и, чтобы избежать сложностей во время родов, рекомендуется пройти подготовку в <router-link to="/school-of-parents" class="menu__link text-circe-bold-18"> Школе будущих родителей</router-link> . Ваш результат: {{result}}</p>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        questions: [
          "Знаете ли вы, до скольки недель может длиться беременность?",
          "Знаете ли вы, когда ехать в роддом?",
          "Знаете ли вы что делать, если воды отошли, а схваток нет?",
          "Знаете ли вы техники дыхания в разных периодах родов?",
          "Знаете ли вы, чем может помочь ваш партнер?",
          "Знаете ли вы, что делать, если роды остановились?",
          "Знаете ли вы, как правильно тужиться, чтобы снизить риск разрывов?",
          "Знаете ли вы о своих потребностях в родах?",
          "Знаете ли вы, какие позы могут облегчить болевые ощущения?",
          "Знаете ли вы, при каких условиях родовая деятельность развивается лучше всего?"
        ],
        answers: Array(10).fill(null),
        result: null
      };
    },
    methods: {
      submitTest() {
        const yesAnswers = this.answers.filter(answer => answer === "yes").length;
        this.result = yesAnswers;
      }
    }
  };
</script>

<style scoped>

.test-form ol {
  padding-left: 20px;
}

.test-form li {
  margin-bottom: 10px;
}

.test-form-answer {
  margin-top: 10px;
}

.test-form input {
  margin-left: 10px;
}

.result {
  margin-top: 10px;
}
.result p {
  font-size: 18px;
  font-weight: bold;
}
.button__wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
