<template>
  <div>
    <img v-if="loaded" :src="src" alt="Loaded Image" class="loaded-image" />
    <div v-else class="image-placeholder">
      <div class="loader-mini"></div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      src: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        loaded: false
      };
    },
    mounted() {
      this.loadImage();
    },
    methods: {
      loadImage() {
        const img = new Image();
        img.src = this.src;
        img.onload = () => {
          this.loaded = true;
        };
      }
    }
  };
</script>

<style scoped>
.image-placeholder {
  width: 100%;
  height: 300px; /* Вы можете настроить размер под ваши нужды */
  background-color: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 738px) {
    height: 150px;
  }
}

.loader-mini {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loaded-image {
  width: 100%;
  height: auto;
  display: block;
}
</style>
