<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title">
            <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">Новости</h1>
          </div>
          <div class="general-info__row">
            <div class="news__list">
              <SectionItemNews v-for="newsItem in firstTwoNews" :key="newsItem.id" :item_news="newsItem" />
            </div>
          </div>
        </div>
      </div>
      <!-- Для страницы со всеми новостями! -->
      <div class="news__list">
        <SectionItemNews v-for="newsItem in remainingNews" :key="newsItem.id" :item_news="newsItem" />
      </div>
    </div>
  </div>
</template>

<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  import SectionItemNews from '@/components/News/SectionItemNews.vue'
  import { actionTypesNews } from '@/store/modules/news'
  import { mapState } from 'vuex'

  export default {
    name: "AppNews",
    components: { SectionItemNews, DynamicMenu },
    metaInfo() {
      return {
        title: 'Новости | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Новости. Подробная информация о Алтайском краевом клиническом перинатальном центре ДАР.'
          },
          {
            name: 'keywords',
            content: 'Алтайский краевой клинический перинатальный центр'
          }
        ]
      };
    },
    data() {
      return {
        menuItems: [
          { text: "О центре", link: "/about", active: false },
          { text: "Блог главного врача", link: "/about/blog", active: false },
          { text: "Руководство", link: "/about/management", active: false },
          { text: "Специалисты", link: "/about/specialists", active: false },
          { text: "Общие сведения", link: "/about/general-info", active: false },
          { text: "Документы", link: "/documents", active: false },
          { text: "Режим работы", link: "/about/schedule", active: false },
          { text: "Контакты", link: "/about/contacts", active: false },
          { text: "Вакансии", link: "/about/vacancies", active: false },
          { text: "Новости", link: "/about/news", active: true },
          { text: "Отзывы", link: "/about/reviews", active: false },
          { text: "Галерея", link: "/about/gallery", active: false }
        ],
      }
    },

    computed: {
      ...mapState({
        news: (state) => state.news.news,
      }),
      firstTwoNews() {
        return this.news.slice(0, 2);
      },
      remainingNews() {
        return this.news.slice(2);
      }
    },
    methods: {

    },
    mounted() {
      this.$store.dispatch(actionTypesNews.getNews)
          .then(() => {
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
    }
  };
</script>


