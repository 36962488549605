<template>
  <div class="general-info">
    <div class="general-info__container">
      <AppBreadCrumbs/>
      <div class="general-info__title-left">
        <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">СТАТЬ ДОНОРОМ</h1>
      </div>
      <div class="general-info-text-block">
        <h2>Донорские программы ЭКО</h2>

        <p>Донорские программы ЭКО помогают зачать долгожданного ребенка при мужском/женском и сочетанном бесплодии и других патологиях, исключающих возможность искусственного оплодотворения с собственными половыми клетками партнеров. В центре ДАР доступны все виды донорских программ. Имеется собственный банк донорских яйцеклеток, спермы, эмбрионов. Супруги могут выбрать донора внешне максимально похожего на одного из будущих родителей.</p>

        <h3>Подбор осуществляется по следующим критериям:</h3>
        <ul class="under-department-content__list">
          <li>Общая характеристика внешности (славянский, азиатский и др. типы внешности);</li>
          <li>Цвет волос, глаз;</li>
          <li>Рост, вес;</li>
          <li>Физиология лица: нос, овал лица и т.д.;</li>
          <li>Группа крови, резус-фактор;</li>
          <li>При необходимости: особенности характера, увлечения, тип фигуры, предрасположенности и т.д.</li>
        </ul>

        <p>Все эти данные фиксируются в анкетах, на основании которых создается каталог. Обычно семейная пара выбирает донора, который имеет максимальное внешнее сходство с мужем/женой. Если пара планирует иметь несколько детей, репродуктолог советует купить несколько порций гамет выбранного донора и оставить на хранение в криобанке. Это необходимо для сходства у будущих детей генотипа и фенотипа.</p>

      </div>
      <div class="general-info-department">
        <section class="statistics-section4" >
          <div class="statistics-section4__wrap-index-block">
            <div class="statistics-section4__index-block-white">
              <div class="statistics-section4__img">
                <img src="../../assets/img/eco/eco5.jpg" alt="ЭКО 5">
              </div>
              <div class="statistics-section4__wrap-info-text" >
                <div class="statistics-section4__title">
                  <p class="text-TTDrugs-Bold-40 color-green">ЭКО С ДОНОРСКИМИ ЯЙЦЕКЛЕТКАМИ
                  </p>
                </div>
                <div class="statistics-section4__flex">
                  <div class="statistics-section4__text">
                    <p class="text-circe-20">При различных заболеваниях репродуктивной системы яйцеклетки перестают созревать, что приводит к диагнозу “бесплодие”. Сегодня данный диагноз не приговор, т.к. есть возможность использовать яйцеклетку донора. Эффективность программ с использованием ооцитов донора достигает 60-70%. Это самые высокие показатели из всей совокупности методик ЭКО.</p>
                  </div>
                </div>
                <div class="statistics-section4__btn" @click="routeDonorsOne">
                  <button class="index-btn">Подробнее</button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="general-info-department">
        <section class="statistics-section3">
          <div class="">
            <div class="statistics-section3__wrap-index-block-white">
              <div class="statistics-section3__index-block">
                <div class="statistics-section3__title">
                  <p class="text-TTDrugs-Bold-40 color-green">ЭКО С ДОНОРСКОЙ СПЕРМОЙ
                  </p>
                </div>
                <div class="statistics-section3__wrap-info-text">
                  <div class="statistics-section3__flex">
                    <div class="statistics-section3__text">
                      <p class="text-circe-20">Предоставляя сперму другим лицам для борьбы с бесплодием, доноры не берут на себя родительские обязанности по отношению к будущему ребенку. Образцы спермы криоконсервируются на 6 месяцев. После их заново проверяют на заболевания и используют для искусственного оплодотворения.</p>
                    </div>
                    <div class="statistics-section3__btn" @click="routeDonorsTwo">
                      <button class="index-btn">Подробнее</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="statistics-section3__img">
                <img src="../../assets/img/eco/eco6.jpg" alt="ЭКО 6">
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="under-department-content">
        <div class="under-department-content__first-block">
          <h2>Информация для доноров</h2>
          <h3>Что получают доноры, при прохождении программы в центре ДАР:</h3>
          <ul class="under-department-content__list">
            <li>Достойное вознаграждение;</li>
            <li>Бесплатное дорогостоящее медицинское обследование;</li>
            <li>Гарантию полной конфиденциальности.</li>
          </ul>
          <p class="under-department-content__paragraph">Все процедуры и исследования контролируются высококвалифицированными врачами, поэтому здоровью доноров ничего не угрожает.</p>

          <h3>Требования к кандидатам:</h3>
          <ul class="under-department-content__list">
            <li>Российское гражданство;</li>
            <li>Отсутствие ярко выраженных внешних признаков/стигм;</li>
            <li>Отсутствие заболеваний, влияющих на репродуктивную функцию, включая генные и хромосомные изменения;</li>
            <li>Согласие на все виды диагностики и процедуры;</li>
            <li>Возраст от 18 до 30 лет;</li>
            <li>Наличие как минимум одного здорового ребенка;</li>
            <li>Отсутствие алко-, нарко- зависимости и вредных привычек.</li>
          </ul>
          <p class="under-department-content__paragraph">Администрация центра самостоятельно выдает разрешение на проведение процедуры. Мы подбираем постоянных доноров. Один донор может проходить процедуру не более 6 раз с перерывом в несколько месяцев.</p>

          <h3>Какие обследования должны пройти доноры:</h3>
          <ul class="under-department-content__list">
            <li>Общий осмотр;</li>
            <li>Анализ крови на всевозможные инфекции;</li>
            <li>УЗИ молочных желез, половых органов;</li>
            <li>Цитологические исследования;</li>
            <li>Общий и биохимический анализ крови, мочи;</li>
            <li>Флюорография;</li>
            <li>ЭКГ;</li>
            <li>Оценка уровня свертываемости крови;</li>
            <li>Генетическое обследование;</li>
            <li>Осмотр у терапевта, невролога и психиатра.</li>
          </ul>
          <p class="under-department-content__paragraph">Дополнительные исследования и консультации у узких специалистов могут потребоваться после изучения индивидуального анамнеза донора и получения результатов анализов. Если все обследования не выявят противопоказаний, донор может подписать договор и пройти процедуру.</p>

          <h3>Как проходит процедура пошагово:</h3>
          <ol class="under-department-content__list">
            <li>1) Первичная врачебная консультация;</li>
            <li>2) Проведение всех необходимых обследований;</li>
            <li>3) Дополнительные врачебные консультации и анализы;</li>
            <li>4) Подписание соглашений, изучение правил о донорстве;</li>
            <li>5) Подготовка, посещение специалиста согласно графику, проведение терапии и информирование врача обо всех изменениях;</li>
            <li>6) Проведение процедуры по получению половой клетки/спермы;</li>
            <li>7) Получение денежного вознаграждения;</li>
            <li>8) Контрольные обследования в течение последующих трех недель.</li>
          </ol>

        </div>
<!--        <div class="under-department-content__second-block">-->
<!--          <div class="under-department-content__second-block-image" >-->
<!--            <img src="../../assets/img/eco/eco1.jpg" alt="Диагностика причин бесплодия" class="under-department-content__image" />-->
<!--          </div>-->
<!--          &lt;!&ndash;          <div class="under-department-content__second-block-text" v-html="contentTwo">&ndash;&gt;-->
<!--          &lt;!&ndash;          </div>&ndash;&gt;-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
  import AppBreadCrumbs from '@/components/BreadCrumbs.vue'

  export default {
    name: "AppDonation",
    components: { AppBreadCrumbs },
    metaInfo() {
      return {
        title: 'Стать донором | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Узнайте все об услугах экстракорпорального оплодотворения (ЭКО) в Алтайском краевом клиническом перинатальном центре ДАР. Мы предлагаем современные методы лечения бесплодия с использованием передовых технологий. Доверьте ваше здоровье нашим опытным специалистам.'
          },
          {
            name: 'keywords',
            content: 'эко, перинатальный центр ДАР'
          }
        ]
      };
    },
    props: {

    },
    computed: {

    },
    methods: {
      routeDonorsOne() {
        this.$router.push({ name: 'ECOGirls', path: '/family-center/article/' + encodeURI('Стать донором') + '/' + encodeURI('ЭКО с донорскими яйцеклетками')});
      },
      routeDonorsTwo() {
        this.$router.push({ name: 'ECOBoys', path: '/family-center/article/' + encodeURI('Стать донором') + '/' + encodeURI('ЭКО с донорской спермой')});
      },

    },
    mounted() {
    }

  };
</script>

<style scoped>

</style>
