<template>
  <div class="block-thank-you">
    <div class="block-thank-you__container">
      <p class="block-thank-you__text text-circe-20 color-gray">Поблагодарить врача или мед.работника можете, заполнив форму ниже</p>
      <a :href="'https://zdravalt.ru/society-and-us/thank-doctor/'" target="_blank"> <button class="index-btn-dark text-uppercase">Сказать "Спасибо"</button></a>

    </div>
  </div>
</template>

<script>
  export default {
    name: "BlockThankYou"
  };
</script>

<style scoped>
.block-thank-you {
  padding: 20px 0;
  text-align: center;
  //border-top: 2px solid #336633;
}

.block-thank-you__container {
  max-width: 800px;
  margin: 0 auto;
}

.block-thank-you__text {
  margin-bottom: 20px;
}
</style>
