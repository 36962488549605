<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row general-info__align-items">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title"><h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">Роды</h1></div>
          <div class="general-info-department__description">
            <p class="text-circe-20 color-gray">
              Почему выбирают нас? Центр имеет всё необходимое для того, чтобы наши пациенты получали качественную и безопасную медицинскую помощь. Каждый специалист центра стремится сделать так, чтобы Вы чувствовали себя как дома.
            </p>
          </div>
        </div>
      </div>
      <div class="general-info-department">
        <section class="statistics-section4" >
          <div class="statistics-section4__wrap-index-block">
            <div class="statistics-section4__index-block-white">
              <div class="statistics-section4__img">
                <img src="../../assets/img/rody/rody1.jpg" alt="РОДЫ 1">
              </div>
              <div class="statistics-section4__wrap-info-text" >
                <div class="statistics-section4__title">
                  <p class="text-TTDrugs-Bold-40 color-green">Персональная акушерка на роды
                  </p>
                </div>
                <div class="statistics-section4__flex">
                  <div class="statistics-section4__text">
                    <p class="text-circe-20">
                      В перинатальном центре «ДАР» теперь доступна услуга по выбору персональной акушерки на роды. Ее стоимость составляет 20 000 рублей.
                      <br>
                      В центре уже определен круг специалистов, которые будут оказывать роженице квалифицированную медицинскую помощь.
                    </p>
                  </div>
                </div>
                <div class="statistics-section4__btn" @click="personalAkusherka">
                  <button class="index-btn">Подробнее</button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="general-info-department">
        <section class="statistics-section3">
          <div class="">
            <div class="statistics-section3__wrap-index-block-white">
              <div class="statistics-section3__index-block">
                <div class="statistics-section3__title">
                  <p class="text-TTDrugs-Bold-40 color-green">Персональный врач на роды
                  </p>
                </div>
                <div class="statistics-section3__wrap-info-text">
                  <div class="statistics-section3__flex">
                    <div class="statistics-section3__text">
                      <p class="text-circe-20">
                        Перинатальный центр «ДАР» всегда стремится соответствовать пожеланиям пациенток, поэтому теперь у нас есть возможность выбора врача, который будет принимать роды. Женщины могут детально обсудить предстоящее самое важное событие в их жизни с понравившимся врачом акушер-гинекологом и чувствовать себя более спокойно и уверенно.
                      </p>
                    </div>
                    <div class="statistics-section3__btn" @click="personalDoctors">
                      <button class="index-btn">Подробнее</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="statistics-section3__img">
                <img src="../../assets/img/rody/rody2.jpg" alt="РОДЫ 2">
              </div>
            </div>
          </div>
        </section>
      </div>
          <app-article-departments-list :name_department="linksDepartment" />
    </div>
  </div>
</template>

<script>

  import AppArticleDepartmentsList from '@/components/Article/ArticleDepartmentsList.vue'
  import { actionTypesDepartment } from '@/store/modules/department'
  import DynamicMenu from '@/components/DynamicMenu.vue'

  export default {
    name: "AppChildbirth",
    components: { DynamicMenu, AppArticleDepartmentsList },
    data() {
      return {
        nameDepartment: 'Роды',
        linksDepartment: 'childbirth',

        menuItems: [
          { text: "ЭКО", link: "/family-center", active: false },
          { text: "Роды", link: "/childbirth", active: true },
          { text: "Плод и новорожденный", link: "/newborn", active: false },
          { text: "Беременность", link: "/pregnancy", active: false },
        ],

      }
    },
    metaInfo() {
      return {
        title: 'Роды | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Подготовьтесь к родам вместе с Алтайским краевым клиническим перинатальным центром ДАР. Узнайте о наших услугах по сопровождению родов, условиях пребывания в родильном доме, партнерских родах и послеродовом уходе. Мы обеспечим вам комфорт и безопасность на всех этапах родов.'
          },
          {
            name: 'keywords',
            content: 'роды, сопровождение родов, родильный дом, партнерские роды, послеродовой уход, перинатальный центр ДАР'
          }
        ]
      };
    },
    props: {

    },
    computed: {

    },
    methods: {
      fetchArticleDepartment() {
        this.$store.dispatch(actionTypesDepartment.getArticleDepartment, this.nameDepartment)
            .then(() => {
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
      personalAkusherka() {
        this.$router.push({ name: 'PersonalAkysherka', path: '/childbirth' + encodeURI('Персональная акушерка на роды')});

      },
      personalDoctors() {
        this.$router.push({ name: 'PersonalDoctors', path: '/childbirth' + encodeURI('Персональный врач на роды')});

      },

    },
    mounted() {
      this.fetchArticleDepartment();
    }

  };
</script>

<style scoped>

</style>
