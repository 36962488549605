<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__title-left">
        <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">Наши услуги</h1>
      </div>
      <section class="statistics-section4" >
        <div class="statistics-section4__container statistics-section4__wrap-index-block">
          <div class="statistics-section4__index-block-white" >
            <div class="statistics-section4__img">
              <img src="../assets/img/section3-index.png" alt="Секция 3">
            </div>
            <div class="statistics-section4__wrap-info-text" >
              <div class="statistics-section4__title">
                <p class="text-TTDrugs-Bold-40 color-green">Ведение беременности</p>
              </div>
              <div class="statistics-section4__flex">
                <div class="statistics-section4__subtitle">
                  <p class="text-circe-bold-22">КОМПЛЕКСНОЕ НАБЛЮДЕНИЕ ЗА ЗДОРОВЬЕМ БУДУЩЕЙ МАМЫ И МАЛЫША, ДЕЛИКАТНЫЙ ПОДХОД, ВЫСОКИЙ УРОВЕНЬ ПРОФЕССИОНАЛИЗМА
                  </p>
                </div>
                <div class="statistics-section4__description">
                  <p class="text-circe-bold-22 color-green">НАБЛЮДЕНИЕ В ТЕЧЕНИЕ ВСЕГО СРОКА БЕРЕМЕННОСТИ</p>
                </div>
                <div class="statistics-section4__text">
                  <p class="text-circe-20">
                    Мы оказываем помощь уже на этапе планирования беременности, предлагаем полный спектр обследований. При необходимости, в ведении беременности принимают участие профильные специалисты: кардиолог, уролог, невролог, эндокринолог, психолог.</p>
                </div>
              </div>
              <div class="statistics-section4__btn" data-aos="fade-up"  data-aos-duration="1000" @click="routeGoPregnancy">
                <button class="index-btn">Подробнее</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="statistics-section3">
        <div class="statistics-section3__container">
          <div class="statistics-section3__wrap-index-block-white">
            <div class="statistics-section3__index-block">
              <div class="statistics-section3__title">
                <p class="text-TTDrugs-Bold-40 color-green">РОДЫ</p>
              </div>
              <div class="statistics-section3__wrap-info-text">
                <div class="statistics-section3__flex">
                  <div class="statistics-section3__info">
                    <div class="statistics-section3__subtitle">
                      <p class="text-circe-bold-22">КОМФОРТНЫЕ И БЕЗОПАСНЫЕ</p>
                    </div>
                  </div>
                </div>
                <div class="statistics-section3__flex">
                  <div class="statistics-section3__description">
                    <p class="text-circe-bold-22 color-green">ПОЧЕМУ ВЫБИРАЮТ НАС? </p>
                  </div>
                  <div class="statistics-section3__text">
                    <p class="text-circe-20">Почему выбирают нас? Центр имеет всё необходимое для того, чтобы наши пациенты получали качественную и безопасную медицинскую помощь. Каждый специалист центра стремится сделать так, чтобы Вы чувствовали себя как дома.</p>
                  </div>
                  <div class="statistics-section3__btn" data-aos="fade-up"  data-aos-duration="1000" @click="routeGoChildbirth">
                    <button class="index-btn">Подробнее</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="statistics-section3__img">
              <img src="../assets/img/section4-index.png" alt="Секция 4">
            </div>
          </div>
        </div>
      </section>
      <section class="statistics-section4">
        <div class="statistics-section4__container statistics-section4__wrap-index-block">
          <div class="statistics-section4__index-block-white">
            <div class="statistics-section4__img statistics-background">
              <img src="../assets/img/section5-index.png" alt="Секция 5">
            </div>
            <div class="statistics-section4__wrap-info-text" >
              <div class="statistics-section4__title">
                <p class="text-TTDrugs-Bold-40 color-green">ЭКО</p>
              </div>
              <div class="statistics-section4__flex">
                <div class="statistics-section4__subtitle">
                  <p class="text-circe-bold-22">ПОЗНАТЬ РОДИТЕЛЬСКОЕ СЧАСТЬЕ
                  </p>
                </div>
                <div class="statistics-section4__description">
                  <p class="text-circe-bold-22 color-green">ЧТО ТАКОЕ ЭКО?</p>
                </div>
                <div class="statistics-section4__text">
                  <p class="text-circe-16">Экстракорпоральное оплодотворение является высокоэффективной процедурой, после которой выносить ребенка удается даже женщинам, недонократно сталкивавшимся с неудачами. В нашем центре будущие родители получат полное сопровождение: от момента планируемой беременности до долгожданной выписки домой. Наши специалисты лаборатории клинической эмбриологии успешно применяют на практике последние достижения в области ЭКО</p>
                </div>
              </div>
              <div class="statistics-section4__btn"  data-aos="fade-up"  data-aos-duration="1000" @click="routeGoECO">
                <button class="index-btn">ПОДРОБНЕЕ</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="statistics-section3">
        <div class="statistics-section3__container">
          <div class="statistics-section3__wrap-index-block-white">
            <div class="statistics-section3__index-block">
              <div class="statistics-section3__title">
                <p class="text-TTDrugs-Bold-40 color-green">ПЛОД И НОВОРОЖДЕННЫЙ</p>
              </div>
              <div class="statistics-section3__wrap-info-text">
                <div class="statistics-section3__flex">
                  <div class="statistics-section3__info">
                    <div class="statistics-section3__subtitle">
                      <p class="text-circe-bold-22">ВЫХАЖИВАНИЕ ПРЕЖДЕВРЕМЕННО РОЖДЕННЫХ ДЕТЕЙ</p>
                    </div>
                  </div>
                </div>
                <div class="statistics-section3__flex">
                  <div class="statistics-section3__description">
                    <p class="text-circe-bold-22 color-green">КАКУЮ ПОМОЩЬ МЫ ОКАЗЫВАЕМ?</p>
                  </div>
                  <div class="statistics-section3__text">
                    <p class="text-circe-20">Неонатологический стационар готов оказать многоплановую медицинскую помощь самым маленьким и тяжелым пациентам. Он оснащен современным медицинским оборудованием, позволяющим оказывать помощь детям с низкой и экстремально низкой массой тела при рождении, проводить восстановительную терапию в соответствии с международными требованиями, а также осуществлять реабилитационные мероприятия.</p>
                  </div>
                  <div class="statistics-section3__btn" data-aos="fade-up"  data-aos-duration="1000" @click="routeGoNewborn">
                    <button class="index-btn">Подробнее</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="statistics-section3__img" >
              <img src="../assets/img/section7-index.png" alt="Секция 7">
            </div>
          </div>
        </div>
      </section>


    </div>
  </div>
</template>

<script>

  import 'aos/dist/aos.css';
  import AOS from 'aos'

  export default {
    name: "AppServices",
    components: {  },
    data() {
      return {

      }
    },
    metaInfo() {
      return {
        title: 'Услуги | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Всё, что вам нужно знать о беременности в Алтайском краевом клиническом перинатальном центре ДАР. Узнайте о медицинском сопровождении беременности, необходимых обследованиях, планировании родов и уходе за здоровьем будущей мамы и ребенка. Наши опытные специалисты помогут вам на каждом этапе беременности.'
          },
          {
            name: 'keywords',
            content: 'беременность, медицинское сопровождение, обследования, планирование родов, перинатальный центр ДАР'
          }
        ]
      };
    },
    props: {

    },
    computed: {

    },
    methods: {
      routeGoPregnancy() {
        this.$router.push('/pregnancy')
      },
      routeGoNewborn() {
        this.$router.push('/newborn')
      },
      routeGoECO() {
        this.$router.push('/department/family-center')
      },
      routeGoChildbirth() {
        this.$router.push('/childbirth')
      },


    },
    mounted() {
      AOS.init();
    }

  };
</script>

<style scoped>

</style>
