<template>
  <div class="article-list-departments__containers">
  <div class="article-list-departments"  v-if="article && article.length">
    <div class="article-list-departments__grid" >
      <AppArticleDepartmentItem v-for="itemArticle in article" :key="itemArticle.id"  :article_item="itemArticle" :name_department="name_department"/>
    </div>
  </div>
  </div>
</template>

<script>

  import { mapState } from 'vuex'
  import AppArticleDepartmentItem from '@/components/Article/ArticleDepartmentItem.vue'

  export default {
    name: "AppArticleDepartmentsList",
    data() {
      return {

      };
    },
    props: {
      name_department: {
        type: String,
        required: true
      },
    },
    components: {
      AppArticleDepartmentItem

    },
    computed: {
      ...mapState({
        article: (state) => state.department.article,
      }),
    },


  };
</script>

<style scoped>

</style>
