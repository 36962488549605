<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column general-info__margin-bottom">
          <div class="general-info__title">
            <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">Режим работы</h1>
          </div>
          <div class="general-info__schedule">
            <AppScheduleTable/>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  import AppScheduleTable from '@/components/ScheduleTable.vue'

  export default {
    name: "AppSchedule",
    components: { AppScheduleTable, DynamicMenu },
    metaInfo() {
      return {
        title: 'Режим работы | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Режим работы. Подробная информация о Алтайском краевом клиническом перинатальном центре ДАР.'
          },
          {
            name: 'keywords',
            content: 'Алтайский краевой клинический перинатальный центр'
          }
        ]
      };
    },
    data() {
      return {
        menuItems: [
          { text: "О центре", link: "/about", active: false },
          { text: "Блог главного врача", link: "/about/blog", active: false },
          { text: "Руководство", link: "/about/management", active: false },
          { text: "Специалисты", link: "/about/specialists", active: false },
          { text: "Общие сведения", link: "/about/general-info", active: false },
          { text: "Документы", link: "/documents", active: false },
          { text: "Режим работы", link: "/about/schedule", active: true },
          { text: "Контакты", link: "/about/contacts", active: false },
          { text: "Вакансии", link: "/about/vacancies", active: false },
          { text: "Новости", link: "/about/news", active: false },
          { text: "Отзывы", link: "/about/reviews", active: false },
          { text: "Галерея", link: "/about/gallery", active: false }
        ],
      }
    }
  };
</script>
