var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"general-info"},[_c('div',{staticClass:"general-info__container"},[_c('div',{staticClass:"general-info__row general-info__align-items"},[_c('DynamicMenu',{attrs:{"menuItems":_vm.menuItems}}),_vm._m(0)],1),_c('div',{staticClass:"general-info-department"},[_c('section',{staticClass:"statistics-section4"},[_c('div',{staticClass:"statistics-section4__wrap-index-block"},[_c('div',{staticClass:"statistics-section4__index-block-white"},[_vm._m(1),_c('div',{staticClass:"statistics-section4__wrap-info-text"},[_vm._m(2),_vm._m(3),_c('div',{staticClass:"statistics-section4__btn",on:{"click":_vm.personalAkusherka}},[_c('button',{staticClass:"index-btn"},[_vm._v("Подробнее")])])])])])])]),_c('div',{staticClass:"general-info-department"},[_c('section',{staticClass:"statistics-section3"},[_c('div',{},[_c('div',{staticClass:"statistics-section3__wrap-index-block-white"},[_c('div',{staticClass:"statistics-section3__index-block"},[_vm._m(4),_c('div',{staticClass:"statistics-section3__wrap-info-text"},[_c('div',{staticClass:"statistics-section3__flex"},[_vm._m(5),_c('div',{staticClass:"statistics-section3__btn",on:{"click":_vm.personalDoctors}},[_c('button',{staticClass:"index-btn"},[_vm._v("Подробнее")])])])])]),_vm._m(6)])])])]),_c('app-article-departments-list',{attrs:{"name_department":_vm.linksDepartment}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"general-info__column"},[_c('div',{staticClass:"general-info__title"},[_c('h1',{staticClass:"text-TTDrugs-Bold-40 color-green center text-uppercase"},[_vm._v("Роды")])]),_c('div',{staticClass:"general-info-department__description"},[_c('p',{staticClass:"text-circe-20 color-gray"},[_vm._v(" Почему выбирают нас? Центр имеет всё необходимое для того, чтобы наши пациенты получали качественную и безопасную медицинскую помощь. Каждый специалист центра стремится сделать так, чтобы Вы чувствовали себя как дома. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"statistics-section4__img"},[_c('img',{attrs:{"src":require("../../assets/img/rody/rody1.jpg"),"alt":"РОДЫ 1"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"statistics-section4__title"},[_c('p',{staticClass:"text-TTDrugs-Bold-40 color-green"},[_vm._v("Персональная акушерка на роды ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"statistics-section4__flex"},[_c('div',{staticClass:"statistics-section4__text"},[_c('p',{staticClass:"text-circe-20"},[_vm._v(" В перинатальном центре «ДАР» теперь доступна услуга по выбору персональной акушерки на роды. Ее стоимость составляет 20 000 рублей. "),_c('br'),_vm._v(" В центре уже определен круг специалистов, которые будут оказывать роженице квалифицированную медицинскую помощь. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"statistics-section3__title"},[_c('p',{staticClass:"text-TTDrugs-Bold-40 color-green"},[_vm._v("Персональный врач на роды ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"statistics-section3__text"},[_c('p',{staticClass:"text-circe-20"},[_vm._v(" Перинатальный центр «ДАР» всегда стремится соответствовать пожеланиям пациенток, поэтому теперь у нас есть возможность выбора врача, который будет принимать роды. Женщины могут детально обсудить предстоящее самое важное событие в их жизни с понравившимся врачом акушер-гинекологом и чувствовать себя более спокойно и уверенно. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"statistics-section3__img"},[_c('img',{attrs:{"src":require("../../assets/img/rody/rody2.jpg"),"alt":"РОДЫ 2"}})])
}]

export { render, staticRenderFns }