<template>
  <div class="table-container">
    <table class="schedule-table">
      <thead>
      <tr>
        <th class="text-circe-20">Должность</th>
        <th class="text-circe-20">Ф.И.О</th>
        <th class="text-circe-20">День недели</th>
        <th class="text-circe-20">Часы приема</th>
        <th class="text-circe-20">Адрес электронной почты, телефон</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in table" :key="index">
        <td class="text-circe-18">{{ item.position }}</td>
        <td class="text-circe-18">{{ item.fio }}</td>
        <td class="text-circe-18">{{ item.day }}</td>
        <td class="text-circe-18">{{ item.hours }}</td>
        <td class="text-circe-semi-bold-16">{{ item.contact }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    name: "AppScheduleTableDoctors",
    data() {
      return {
        table: [
          {
            position: "Главный врач",
            fio: "Молчанова Ирина Владимировна",
            day: "вторник",
            hours: "15.00-16.00",
            contact: "akkpc@zdravalt.ru\n8 (3852) 591-130"
          },
          {
            position: "Заместитель главного врача по акушерско – гинекологической помощи",
            fio: "Буркова Татьяна Викторовна",
            day: "среда",
            hours: "14.00-15.00",
            contact: "akkpc@zdravalt.ru\n8 (3852) 591-130"
          },
          {
            position: "Заместитель главного врача по контролю качества медицинской помощи",
            fio: "Колядо Ольга Викторовна",
            day: "пятница",
            hours: "14.00-15.00",
            contact: "akkpc@zdravalt.ru\n8 (3852) 591-130"
          },
          {
            position: "Заместитель главного врача по экономическим вопросам",
            fio: "Рощипкин Николай Николаевич",
            day: "понедельник",
            hours: "14.00-15.00",
            contact: "akkpc@zdravalt.ru\n8 (3852) 591-130"
          }
        ]
      };
    }
  };
</script>
