<template>
<!--  <ul class="validate">-->
    <p class="error-text">
      {{ validationErrors }}
    </p>
<!--  </ul>-->
</template>

<script>
export default {
  name: "AppValidationErrors",
  props: {
    validationErrors: {
      type: Object,
      required: true, //без него ничего не будет работать
    },
  },
  computed: {},
};
</script>
