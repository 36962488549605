<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row general-info__align-items">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title">
            <h1 class="text-TTDrugs-Bold-40 color-green left text-uppercase">Справка для налогового вычета</h1>
          </div>
          <div class="general-info__block-row">
            <div class="">
              <h2>Получение справки об оплате медицинских услуг</h2>
              <p>Для получения справки об оплате медицинских услуг Вам необходимо на электронную почту <a href="mailto:pc_altay.buh@mail.ru">pc_altay.buh@mail.ru</a> направить номер ИНН, договор о предоставлении платных услуг (если договор отсутствует, необходимо указать Ф.И.О., дату рождения).</p>
              <p>Готовая справка будет направлена Вам на электронную почту или Вы можете забрать оригинал справки в центральном холле перинатального центра через 5 рабочих дней.</p>

              <h3>Социальный налоговый вычет на лечение и (или) приобретение медикаментов</h3>
              <p>Пациенты КГБУЗ «АККПЦ» вправе обратиться за справкой для социального налогового вычета. Сотрудники перинатального центра оказывают консультирование и помощь в формировании необходимого пакета документов.</p>
              <p>К возврату принимается 13% от суммы затраченной на платные медицинские услуги, но не более того, что было уплачено в качестве налога в отчетном году.</p>

              <h4>Перечень документов</h4>
              <ul class="under-department-content__list">
                <li>Налоговая декларация по форме 3-НДФЛ</li>
                <li>Договор на лечение с приложениями и дополнительными соглашениями к нему (в случае заключения) - копия <sup>1</sup></li>
                <li>Справка об оплате медицинских услуг для представления в налоговые органы Российской Федерации <sup>2</sup> - оригинал</li>
                <li>Рецептурный бланк <sup>3</sup> со штампом "Для налоговых органов Российской Федерации, ИНН налогоплательщика" - оригинал</li>
                <li>Документы, подтверждающие оплату медикаментов (например, чек) - копия <sup>1</sup></li>
                <li>Документ, подтверждающий степень родства <sup>4</sup> (например, свидетельство о рождении) - копия <sup>1</sup></li>
                <li>Документ, подтверждающий заключение брака <sup>5</sup> (например, свидетельство о браке) - копия <sup>1</sup></li>
                <li>Заявление на возврат НДФЛ <sup>6</sup> (см. образец)</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="under-department-content">
        <div class="under-department-content__third-block">
          <div class="under-department-content__third-block-image">
            <img src="../../assets/img/nalogovyj_vychet.jpg" alt="nalogovyj_vychet" class="under-department-content__image" >
          </div>
          <div class="under-department-content__third-block-text" >
            <h4>Примечание:</h4>
            <ul class="under-department-content__list">
              <li><sup>1.</sup> Налоговый орган вправе запросить оригиналы.</li>
              <li><sup>2.</sup> Выдается медицинским учреждением; форма Справки утверждена приказом Минздрава России и МНС России от 25.07.2001 N 289/БГ-3-04/256.</li>
              <li><sup>3.</sup> Выдается лечащим врачом; представляется в случае приобретения медикаментов.</li>
              <li><sup>4.</sup> Представляется в случае оплаты лечения и приобретения медикаментов для родителей и детей.</li>
              <li><sup>5.</sup> Представляется в случае оплаты лечения и приобретения медикаментов для супруга.</li>
              <li><sup>6.</sup> Представляется в случае, если в налоговой декларации исчислена сумма налога к возврату.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  export default {
    name: "AppRefund",
    components: {   DynamicMenu },
    metaInfo() {
      return {
        title: 'Справка для налогового вычета | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Подробная информация о Алтайском краевом клиническом перинатальном центре ДАР'
          },
          {
            name: 'keywords',
            content: 'пациентам, алтайский краевой клинический перинатальный центр, перинатальный центр, дар'
          }
        ]
      };
    },
    data() {
      return {
        nameDepartment: 'Справка для налогового вычета',
        linksDepartment: 'tax-refund',
        menuItems: [
          { text: "Услуги ОМС", link: "/oms", active: false },
          { text: "Правила записи на прием", link: "/rules", active: false },
          { text: "Анализы", link: "/tests", active: false },
          { text: "Госпитализация", link: "/hospitalization", active: false },
          { text: "Иностранным гражданам", link: "/foreigners", active: false },
          { text: "Справка для налогового вычета", link: "/tax-refund", active: true },
          { text: "График прима граждан", link: "/schedule", active: false },
          { text: "Список продуктов для передачи", link: "/list-product", active: false },
          { text: "Молитвенная комната", link: "/prayer-room", active: false },
          { text: "Медицинский туризм", link: "/medical-tourism", active: false }
        ],

      }
    },

  };
</script>

