<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row general-info__align-items">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title"><h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">Беременность</h1></div>
          <div class="general-info-department__description">
            <p class="text-circe-20 color-gray">
              Основной задачей наблюдения женщин в этот период является профилактика и раннее выявление возможных осложнений беременности, родов и послеродового периода. В нашем центре ведение беременности проходит под контролем команды грамотных специалистов различного профиля, готовых оказать как плановую, так и экстренную консультативную помощь пациенткам на всей территории края.

            </p>
          </div>
        </div>
      </div>
      <div class="general-info-department">
        <div class="general-info-department__info-block">
          <div class="general-info-department__description">

            <p class="text-circe-20 color-gray">
              Беременность – очень важный и ответственный момент в жизни женщины. Всегда хочется, чтобы эти 9 месяцев протекали нормально и не заставляли будущую маму волноваться.

            </p>
          </div>
        </div>
        <div class="general-info-department__thank">
          <BlockThankYou/>
        </div>
      </div>
      <app-article-departments-list :name_department="linksDepartment" />
    </div>
  </div>
</template>

<script>

  import AppArticleDepartmentsList from '@/components/Article/ArticleDepartmentsList.vue'
  import { actionTypesDepartment } from '@/store/modules/department'
  import BlockThankYou from '@/components/BlockThankYou.vue'
  import DynamicMenu from '@/components/DynamicMenu.vue'

  export default {
    name: "AppPregnancy",
    components: { DynamicMenu, BlockThankYou, AppArticleDepartmentsList },
    metaInfo() {
      return {
        title: 'Беременность | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Всё, что вам нужно знать о беременности в Алтайском краевом клиническом перинатальном центре ДАР. Узнайте о медицинском сопровождении беременности, необходимых обследованиях, планировании родов и уходе за здоровьем будущей мамы и ребенка. Наши опытные специалисты помогут вам на каждом этапе беременности.'
          },
          {
            name: 'keywords',
            content: 'беременность, медицинское сопровождение, обследования, планирование родов, перинатальный центр ДАР'
          }
        ]
      };
    },
    data() {
      return {
        nameDepartment: 'Беременность',
        linksDepartment: 'pregnancy',


        menuItems: [
          { text: "ЭКО", link: "/family-center", active: false },
          { text: "Роды", link: "/childbirth", active: false },
          { text: "Плод и новорожденный", link: "/newborn", active: false },
          { text: "Беременность", link: "/pregnancy", active: true },
        ],
      }
    },
    props: {

    },
    computed: {

    },
    methods: {
      fetchArticleDepartment() {
        this.$store.dispatch(actionTypesDepartment.getArticleDepartment, this.nameDepartment)
            .then(() => {
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },


    },
    mounted() {
      this.fetchArticleDepartment();
    }

  };
</script>

<style scoped>

</style>
