<template>
  <div class="history-block" >
    <div class="history-block__container">
      <vueper-slides
          class="no-shadow"
          :autoplay="true"
          :autoplay-speed="3000"
          transition-speed="500"
          fixed-height="800px"
          :visible-slides="1"
          :slide-multiple="false"
          :gap="1"
          :dragging-distance="200"
          arrows-outside
          :breakpoints="{ 800: { visibleSlides: 1, slideMultiple: false } }"
          arrow-next-outside
          arrow-prev-outside
      >
<!--        @slide="onSlideChange"-->
        <vueper-slide
            v-for="(slide) in history" :key="slide.id"
        >
<!--          :class="{'active-slide': index === activeSlide.index, 'inactive-slide': index !== activeSlide.index}"-->
          <template #content>
            <div class="history-block__wrap">
              <div class="history-block__first">
                <div class="history-block__first-img">
                  <img :src="slide.image" alt="История"/>
                </div>
                <div class="history-block__first-age">
                  <p class="text-TTDrugs-Regular-124 color-gray">{{slide.age}} год</p>
                </div>
              </div>
              <div class="history-block__second">
                <p class="text-circe-regular-24">{{slide.text}}</p>
              </div>
              <div class="history-block__second-text">
                <p class="text-circe-regular-24">{{slide.text2}}</p>
              </div>
            </div>
          </template>
        </vueper-slide>
      </vueper-slides>

      </div>
  </div>
</template>

<script>
  import { VueperSlides,VueperSlide } from 'vueperslides'
  import 'vueperslides/dist/vueperslides.css'
  export default {
    name: "AppSwiperHistory",
    components: { VueperSlide, VueperSlides},
    data() {
      return {
        history: [
          {id: 1,age: '2016', text: 'Идея создания современного учреждения родовспоможения была поддержана губернатором Александром Карлиным в 2010 году. Далее проект получил поддержку на уровне президента России Владимира Путина, благодаря чему наш регион включился в специальную федеральную программу по строительству 32 перинатальных центров. Работы начались в октябре 2014 года, общая стоимость затрат на строительство здания и оснащение его необходимым оборудованием составила порядка 3 млрд руб.\n' +
                'Свое имя новый перинатальный центр получил по результатам всенародного конкурса. Название «ДАР» – «Дети, Алтай, Россия!» предложили студенты и преподаватели АлтГТУ, которые также разработали логотип учреждения.', text2:'', image: require('../../assets/img/history/history-2016.webp')},
          {id: 2,age: '2017', text: 'В центре родился мальчик, который стал 1000-м ребенком для учреждения. Это радостное и запоминающееся событие произошло в семье Трышкиных. Мама приехала из Хабарского района, село Мичуринское. В их семье Назар - пятый ребенок. Вес новорожденного мальчика составил 3100 грамм, рост – 51 сантиметр. \n', text2:'Открыт кабинет катамнеза для оказания высококвалифицированной специализированной медицинской помощи глубоко недоношенным детям, родившимся с массой тела менее 1500 грамм. На учет в кабинет встают малыши, перенесшие критические состояния в раннем неонатальном периоде, такие как асфиксия, ИВЛ различной длительности, реанимационные мероприятия, оперативное вмешательство. ', image: require('../../assets/img/history/history-2017.jpg')},
          {id: 3,age: '2018', text: 'Сотрудники АККПЦ приняли участие в дискуссии «Международный опыт родоразрешения пациенток с тазовым предлежанием плода», которая состоялась в Москве. Профессор Климов В.А. представил врачам перинатальных центров со всей страны методику ведения родов, успешно используемую коллегами из Германии уже на протяжении длительного времени. Для российских же специалистов такой подход оказался новацией. Тем не менее, по возвращении в Барнаул врачам «ДАРа» выпала возможность применить его на практике. В Алтайском крае это произошло впервые! Результат оказался более чем успешным.', text2:'', image: require('../../assets/img/history/history-2018.jpg')},
          {id: 4,age: '2019', text: 'Наши врачи освоили новую для региона процедуру, которая способна не просто вылечить, но и в некоторых случаях спасти жизнь самым маленьким пациентам – внутриутробное переливание крови. Данная процедура считается наиболее эффективным методом лечения гемолитической болезни плода, возникающей из-за несовместимости крови матери и ребенка по резус-фактору. ', text2:'', image: require('../../assets/img/history/history-2019.webp')},
          {id:5, age: '2020', text:'В перинатальном центре «ДАР» стартовала первая школа мам, чьи малыши появились на свет раньше срока. Занятия проходят в рамках сотрудничества с Всероссийским благотворительным фондом «Право на чудо». ', image: require('../../assets/img/history/history-2020.jpg')}
        ]
      }
    },
  };
</script>

<style scoped>

</style>
