<template>
  <div class="loader-overlay">
    <div class="loader"></div>
  </div>
</template>

<script>
  export default {
    name: 'AppLoader',
    props: {

    }
  };
</script>
