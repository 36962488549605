<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row general-info__align-items">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title"><h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">Плод и новожденный</h1></div>
          <div class="general-info-department__description">
            <p class="text-circe-20 color-gray">Неонатологический стационар готов оказать многоплановую медицинскую помощь самым маленьким и тяжелым пациентам. Он оснащен современным медицинским оборудованием, позволяющим оказывать помощь детям с низкой и экстремально низкой массой тела при рождении, проводить восстановительную терапию в соответствии с международными требованиями, а также осуществлять реабилитационные мероприятия.
            </p>
          </div>
        </div>
      </div>
      <div class="general-info-department">
        <div class="general-info-department__info-block">
          <div class="general-info-department__description">
            <p class="text-circe-20 color-gray">
              Заботимся о вашем малыше с первого дня зачатия до 1 года жизни.
            </p>
            <br>
            <p class="text-circe-20 color-gray">
              В Перинатальном центре “ДАР” есть все условия для должного выхаживания, диагностики и лечения плода, недоношенных, доношенных детей. Центр оснащен инновационным оборудованием мирового уровня. Здесь работают чуткие, любящие, неравнодушные специалисты, которые ежедневно делают все возможное для того, чтобы ваши дети росли здоровыми.
            </p>
          </div>
        </div>
        <div class="general-info-department__thank">
          <BlockThankYou/>
        </div>
      </div>
      <app-article-departments-list :name_department="linksDepartment"/>
    </div>
  </div>
</template>

<script>

  import AppArticleDepartmentsList from '@/components/Article/ArticleDepartmentsList.vue'
  import { actionTypesDepartment } from '@/store/modules/department'
  import BlockThankYou from '@/components/BlockThankYou.vue'
  import DynamicMenu from '@/components/DynamicMenu.vue'

  export default {
    name: "AppPregnancy",

    components: { DynamicMenu, BlockThankYou, AppArticleDepartmentsList },
    metaInfo() {
      return {
        title: 'Плод и новорожденный | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Неонатологический стационар готов оказать многоплановую медицинскую помощь самым маленьким и тяжелым пациентам.'
          },
          {
            name: 'keywords',
            content: 'пациентам, алтайский краевой клинический перинатальный центр, перинатальный центр, дар'
          }
        ]
      };
    },
    data() {
      return {
        nameDepartment: 'Неонатологический стационар',
        linksDepartment: 'newborn',
        menuItems: [
          { text: "ЭКО", link: "/family-center", active: false },
          { text: "Роды", link: "/childbirth", active: false },
          { text: "Плод и новорожденный", link: "/newborn", active: true },
          { text: "Беременность", link: "/pregnancy", active: false },
        ],
      }
    },
    props: {

    },
    computed: {

    },
    methods: {
      fetchArticleDepartment() {
        this.$store.dispatch(actionTypesDepartment.getArticleDepartment, this.nameDepartment)
            .then(() => {
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },


    },
    mounted() {
      this.fetchArticleDepartment();
    }

  };
</script>

<style scoped>

</style>
