import Vue from 'vue'
import Vuex from 'vuex'
import department from "@/store/modules/department";
import vacancies from "@/store/modules/vacancies";
import documents from "@/store/modules/documents";
import paidServices from "@/store/modules/paidServices";
import reviews from "@/store/modules/reviews";
import modal from "@/store/modules/modal";
import news from "@/store/modules/news";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    department,
    vacancies,
    documents,
    paidServices,
    reviews,
    modal,
    news
  }
})
