import axios from "@/api/axios";

const getDocuments = () => {
  return axios.get(`/files`);
};

const getNextFolderDocuments = (data) => {
  return axios.get(`/next-folder?folderName=${data.name}&currentPath=${data.currentPath}`);
}
const downloadFile = (data) => {
  return axios.get(`/download?fileName=${data.name}&currentPath=${data.currentPath}`, {
    responseType: "blob",
  })
}
export default {
  getDocuments,
  getNextFolderDocuments,
  downloadFile

};
