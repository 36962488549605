<template>
  <div class="dynamic-section7">
    <div class="dynamic-section7__container">
      <div class="dynamic-section7__title">
         <p class="text-TTDrugs-Bold-40 color-green">НОВОСТИ</p>
      </div>
      <div class="dynamic-section7__news news-index">
        <div class="news-index__list" v-if="firstTwoNews.length > 0">
          <vueper-slides
              ref="slider"
              :autoplay="true"
              :autoplay-speed="3000"
              class="no-shadow"
              transition-speed="500"
              fixed-height="600px"
              :visible-slides="3"
              :slide-multiple="false"
              :gap="3"
              :dragging-distance="200"
              arrows-outside
              :breakpoints="{
                1382: { visibleSlides: 2, slideMultiple: false },
                1087: { visibleSlides: 1, slideMultiple: false },

              }"
              arrow-next-outside
              arrow-prev-outside
              @slide="onSlideChange"

          >
            <vueper-slide
                v-for="(news, index) in firstTwoNews"
                :key="news.id"
                :class="{'active-slide': index === activeSlide.index, 'inactive-slide': index !== activeSlide.index}"
            >
              <template #content>
                <SectionIndexItemNews :item_news="news"/>
              </template>
            </vueper-slide>
          </vueper-slides>
        </div>
      </div>
      <div class="dynamic-section7__btn" >
        <button class="index-btn" @click="goToNews">ПЕРЕЙТИ К ОСТАЛЬНЫМ ПОВОСТЯМ</button>
      </div>
    </div>
  </div>
</template>

<script>

  import SectionIndexItemNews from '@/components/News/SectionIndexItenNews.vue'
  import { VueperSlides,VueperSlide } from 'vueperslides'
  import 'vueperslides/dist/vueperslides.css'
  import { actionTypesNews } from '@/store/modules/news'
  import { mapState } from 'vuex'

  export default {
    name: 'SectionNews',
    components: { VueperSlide, VueperSlides, SectionIndexItemNews },
    data() {
      return {  activeSlide: {index: 0}, // Состояние для отслеживания активного слайда
      }
    },
    computed: {
      ...mapState({
        news: (state) => state.news.news,
      }),
      firstTwoNews() {
        return this.news.slice(0, 5);
      },
    },
    methods: {
      onSlideChange(event) {
        this.activeSlide = event.currentSlide; // Обновляем активный слайд
      },
      goToNews() {
        this.$router.push('/about/news')
      },
    },
    mounted() {
      // this.initDrag();
      this.$store.dispatch(actionTypesNews.getNews)
          .then(() => {
            // После загрузки новостей принудительно инициализируем слайдер
            this.$nextTick(() => {
              const slider = this.$refs.slider;
              if (slider && slider.initialize) {
                slider.initialize();
              }
            });
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
    }
  }
</script>
<style scoped>
/* Добавляем стили для скрытия стрелок */
.vueperslides__arrow--prev,
.vueperslides__arrow--next {
  background: none;
  border: none;
  color: #ffffff; /* Ваш цвет стрелок */
  font-size: 20px;
  cursor: pointer;
}
/* Стили для активного и неактивных слайдов */
.active-slide {
  transform: scale(1.0);
  transition: transform 0.5s ease;
  z-index: 1;
  border-radius: 20px;
}

.inactive-slide {
  transform: scale(0.9);
  transition: transform 0.5s ease;
  z-index: 0;
}
</style>

