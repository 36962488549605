import DocumentsAPi from "@/api/documents.js";
import { setItem, getItem } from "@/helpers/persistanceStorage";
const state = {
  isLoading: false, //ожидание запроса
  validationErrors: null, //ошибки
  documents: [],
  folderDocuments: [],
  currentFile: getItem("currentFile") || "",
};

export const mutationTypes = {
  getDocumentsStart: "[documents] getDocumentsStart",
  getDocumentsSuccess: "[documents] getDocumentsSuccess",
  getDocumentsFailure: "[documents] getDocumentsFailure",

  getNextFolderDocumentsStart: "[documents] getNextFolderDocumentsStart",
  getNextFolderDocumentsSuccess: "[documents] getDocumentsSuccess",
  getNextFolderDocumentsFailure: "[documents] getNextFolderDocumentsFailure",

  downloadFileStart: "[documents] downloadFileStart",
  downloadFileSuccess: "[documents] downloadFileSuccess",
  downloadFileFailure: "[documents] downloadFileFailure",

  safeCurrentFileSuccess:  "[documents] safeCurrentFileSuccess",
};
export const actionTypesDocuments = {
  getDocuments: "[documents] getDocuments",
  getNextFolderDocuments: "[documents] getNextFolderDocuments",
  downloadFile: "[documents] downloadFile",
  safeCurrentFile: "[documents] safeCurrentFile"
};

const mutations = {
  [mutationTypes.safeCurrentFileSuccess](state, info) {
    state.currentFile = info;
  },
  //Получение Папок документов
  [mutationTypes.getDocumentsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getDocumentsSuccess](state, info) {
    state.isLoading = false;
    state.documents = info
  },
  [mutationTypes.getDocumentsFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },

  //Получение Папок документов
  [mutationTypes.getNextFolderDocumentsStart](state) {
    state.isLoading = true;
    state.folderDocuments = []
  },
  [mutationTypes.getNextFolderDocumentsSuccess](state, info) {
    state.isLoading = false;
    state.folderDocuments = info
  },
  [mutationTypes.getNextFolderDocumentsFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //downloadFile
  [mutationTypes.downloadFileStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.downloadFileSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.downloadFileFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
};
const actions = {
  [actionTypesDocuments.getDocuments](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getDocumentsStart);
      DocumentsAPi.getDocuments()
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.getDocumentsSuccess,
                  response.data.result
              );
            } else {
              context.commit(mutationTypes.getDocumentsFailure, response);
            }
            resolve(response.data);
          })
          .catch((response) => {
            context.commit(mutationTypes.getDocumentsFailure, response);
            console.log("ошибка", response);
          });
    });
  },
  [actionTypesDocuments.getNextFolderDocuments](context, name) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getNextFolderDocumentsStart);
      DocumentsAPi.getNextFolderDocuments(name)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.getNextFolderDocumentsSuccess,
                  response.data.result
              );
            } else {
              context.commit(mutationTypes.getNextFolderDocumentsFailure, response);
            }
            resolve(response.data);
          })
          .catch((response) => {
            context.commit(mutationTypes.getNextFolderDocumentsFailure, response);
            console.log("ошибка", response);
          });
    });
  },
  [actionTypesDocuments.downloadFile](context, data) {
  return new Promise((resolve) => {
    context.commit(mutationTypes.downloadFileStart);
    DocumentsAPi.downloadFile(data)
        .then((response) => {
            const contentType = response.headers['content-type'];
            const url = URL.createObjectURL(
                new Blob([response.data], { type: contentType })
            );
            const fileName = data.name; // предполагается, что data.name содержит правильное имя файла с расширением
            console.log(fileName,'fileName')
            const reportData = {
              url,
              fileName,
            };
            context.commit(mutationTypes.downloadFileSuccess, reportData);
            resolve(reportData);
        })
        .catch((error) => {
          context.commit(mutationTypes.downloadFileFailure, error.response);
          console.log("ошибка", error);
        });
  });
},
  [actionTypesDocuments.safeCurrentFile](context, data) {
      context.commit(mutationTypes.safeCurrentFileSuccess, data);
      setItem("currentFile", data);
  },
  // downloadFile
};

export default {
  state,
  mutations,
  actions,
};
