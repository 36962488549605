<template>
  <div class="appeal">
    <div class="appeal__container">
      <div class="appeal__text-wrap">
      <h2 class="text-TTDrugs-bold-24 center">Уважаемые пациенты!</h2>
      </div>
      <p class="appeal__text">
        Работа с обращениями граждан в КГБУЗ «Алтайский краевой клинический перинатальный центр» организована в соответствии с Федеральным законом № 59-ФЗ от 02.05.2006 «О порядке рассмотрений обращений граждан Российской Федерации» и законом Алтайского края № 152-ЗС от 29.12.2006 «О рассмотрении обращений граждан Российской Федерации на территории Алтайского края».
      </p>
      <div class="appeal__methods">
        <div class="appeal__text-wrap">
        <h3 class="text-TTDrugs-bold-24">Обращения принимаются:</h3>
        </div>
        <ul class="under-department-content__list">
          <li class="">
            <strong>В устной форме</strong> по телефонам горячей линии: 8 (3852) 591-130 и 8 (963) 536-54-08.
          </li>
          <li class="">
            <strong>При личном обращении</strong> в центр. В ежедневном режиме ведется прием граждан специалистом отдела по работе с обращениями граждан в кабинете № 2007 (второй этаж, кабинет в холле слева от лифта) в рабочие дни с 8-00 до 16-00 без предварительной записи.
          </li>
          <li class="">
            <strong>В ходе личного приема руководством</strong> перинатального центра. График личного приема главным врачом и заместителями главного врача размещен в разделе <router-link to="/patients">"Пациентам" </router-link> вкладка <router-link to="/schedule"> "График приема граждан"</router-link>.
          </li>
          <li class="">
            <strong>В электронной форме через сайт</strong> с помощью кнопки «Написать обращение».
          </li>
          <li class="">
            <strong>В письменном виде</strong> гражданин может подать обращение по почтовому адресу: 656045, Алтайский край, г. Барнаул, ул. Фомина, д. 154 или по электронной почте <a href="mailto:akkpc@zdravalt.ru">akkpc@zdravalt.ru</a>.
          </li>
        </ul>
      </div>
      <div class="appeal__contacts">
        <h3 class="appeal__contacts-title">Контакты ответственных лиц:</h3>
        <p class="appeal__contact">
          Мазурова Наталья Владимировна, начальник отдела по связям с общественностью и работе с обращениями граждан, тел. 8 (3852) 591-130.
        </p>
        <p class="appeal__contact">
          Натарова Валентина Владимировна, специалист отдела по связям с общественностью и работе с обращениями граждан, тел. 8 (3852) 591-130.
        </p>
      </div>
      <div class="appeal__footer">
         <button class="index-btn" @click="openModal">Написать обращение</button>
      </div>
    </div>
    <AppModalAskQuestion v-if="isModalVisible" @close="closeModal"/>
  </div>
</template>

<script>
  import AppModalAskQuestion from '@/components/Modal/ModalAppeal.vue'

  export default {
    name: "AppAppeal",
    metaInfo() {
      return {
        title: 'Обращение | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Подробная информация о Алтайском краевом клиническом перинатальном центре ДАР'
          },
          {
            name: 'keywords',
            content: 'пациентам, алтайский краевой клинический перинатальный центр, перинатальный центр, дар'
          }
        ]
      };
    },
    components: { AppModalAskQuestion },
    data() {
      return {
        isModalVisible: false
      };
    },
    methods: {
      openModal() {
        this.isModalVisible = true;
      },
      closeModal() {
        this.isModalVisible = false;
      }
    }
  };
</script>

<style scoped>

</style>
