<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__title-left">
        <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">Образование</h1>
      </div>
      <div class="general-info-department">
        <section class="statistics-section3">
          <div class="">
            <div class="statistics-section3__wrap-index-block-white">
              <div class="statistics-section3__index-block">
                <div class="statistics-section3__title">
                  <p class="text-TTDrugs-Bold-40 color-green">Школа будущих родителей</p>
                </div>
                <div class="statistics-section3__wrap-info-text">
                  <div class="statistics-section3__flex">
                    <div class="statistics-section3__text">
                      <h2>В перинатальном центре «ДАР» создана «Школа будущих родителей» для семейных пар, которые готовятся стать родителями.</h2>
                      <p><span class="bold">Школа дает уверенность и спокойствие</span>, что родители справятся с новыми обязанностями внутри семьи. Программа составлена для помощи молодым родителям, подверженным тревогам и переживаниям, которые связаны с появлением первенца. Парам, которые ждут второго или третьего ребенка, также будет интересен курс в нашей школе: мы предусмотрели блок информации и для опытных родителей.</p>

                    </div>
                    <div class="statistics-section3__btn" @click="routeGoSchool">
                      <button class="index-btn">Подробнее</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="statistics-section3__img">
                <img src="../../assets/img/education.jpg" alt="Школа будущих родителей">
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="general-info-department">
        <section class="statistics-section4" >
          <div class="statistics-section4__wrap-index-block">
            <div class="statistics-section4__index-block-white">
              <div class="statistics-section4__img">
                <img src="../../assets/img/volont.jpg" alt="Волонтерство">
              </div>
              <div class="statistics-section4__wrap-info-text" >
                <div class="statistics-section4__title">
                  <p class="text-TTDrugs-Bold-40 color-green">Волонтерство</p>
                </div>
                <div class="statistics-section4__flex">
                  <div class="statistics-section4__text">
                    <p>Особое внимание в краевом перинатальном центре уделяют работе с будущим поколением медицинских работников. В частности, здесь активно развивают волонтерское движение. В команде добровольцев - студенты Алтайского государственного университета (направление Клиническая психология), Барнаульского базового медицинского колледжа и Института клинической медицины Алтайского государственного медицинского университета.</p>
                    <p>Вначале волонтеры осваивают специальную программу подготовки, разработанную экспертами центра. Далее, на практике развивают профессиональные навыки по оказанию помощи пациенткам</p>
                  </div>
                </div>
                <div class="statistics-section4__btn" @click="routeVolonteering">
                  <button class="index-btn">Узнать подробнее</button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="general-info-department">
        <section class="statistics-section3">
          <div class="">
            <div class="statistics-section3__wrap-index-block-white">
              <div class="statistics-section3__index-block">
                <div class="statistics-section3__title">
                  <p class="text-TTDrugs-Bold-40 color-green">Документы</p>
                </div>
                <div class="statistics-section3__wrap-info-text">
                  <div class="statistics-section3__flex">
                    <div class="statistics-section3__text">
                      <!--                        href="/documents/education"-->
                      <p>Перечень доступных образовательных докуметов вы можете найти по ссылке <router-link to="/documents/education" >Образование</router-link></p>
                      <ul class="under-department-content__list">
                        <li>Актуальные планы и программы для медицинских работников</li>
                        <li>Акушерство и гинекология</li>
                        <li>Аттестация</li>
                        <li>Документы для зачисления на курсы</li>
                        <li>Информационные материалы</li>
                        <li>Неонатология</li>
                        <li>Новые приказы по аттестации</li>
                        <li>Нормативные документы</li>
                      </ul>
                    </div>
                    <div class="statistics-section3__btn" @click="routeDocumentsEducation">
                      <button class="index-btn">Перейти ко все документам</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="statistics-section3__img">
                <img src="../../assets/img/documents.jpg" alt="Документы">
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "AppEducation",
    data() {
      return {

      };
    },
    metaInfo() {
      return {
        title: 'Образование | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Узнайте все об услугах образования в Алтайском краевом клиническом перинатальном центре ДАР.'
          },
          {
            name: 'keywords',
            content: 'образование, алтайский краевой клинический перинатальный центр, перинатальный центр, дар'
          }
        ]
      };
    },
    computed: {

    },
    methods: {
      routeDocumentsEducation() {
        this.$router.push('/documents/education')
      },
      routeGoSchool() {
        this.$router.push('/education/school-of-parents')
      },
      routeVolonteering() {
        this.$router.push('/education/volunteer-activities')
      }
    },
    mounted() {
    }
  };
</script>
