<template>
  <div class="management">
    <div class="management__container">
      <div class="management__row">
        <DynamicMenu :menuItems="menuItems" />
        <div class="management__content">
          <div class="management__title">
          <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase left">Руководство</h1>
          </div>
          <template v-if="CHIEF_MEDICAL_OFFICER && CHIEF_MEDICAL_OFFICER.length ">
          <div class="people-block" v-for="main in CHIEF_MEDICAL_OFFICER" :key="main.id" >
            <div class="people-block__img">
              <img src="../../assets/img/molchanova.png" alt="Молчанова">
            </div>
            <div class="people-block__information">
              <div class="people-block__information-header">
                <p class="text-TTDrugs-bold-20 text-uppercase color-gray">{{main.name}}
                </p>
              </div>
              <div class="people-block__information-position">
                <p class="color-black text-TTDrugs-regular-20 text-uppercase">{{ main.position }}</p>
<!--                <div class="people-block__description">-->
<!--                  <p class="color-gray text-circe-18">Кандидат медицинских наук, отличник здравоохранения, врач акушер-гинеколог высшей категории</p>-->
<!--                </div>-->
              </div>

              <div class="people-block__information-opening-hours">
                <p class="text-circe-20"><span class="text-circe-bold-20">Часы приёма:</span> {{main.openingHours}}</p>
              </div>
            </div>
          </div>
          </template>
        </div>
      </div>
      <template v-if="MANAGEMENTS && MANAGEMENTS.length">
      <app-people-list :managements="MANAGEMENTS" />
      </template>
    </div>

  </div>
</template>

<script>
  import DynamicMenu from "@/components/DynamicMenu.vue";
  import AppPeopleList from '@/components/People/PeopleList.vue'
  import { mapState } from 'vuex'
  import { actionTypesDepartment } from '@/store/modules/department'


  export default {
    name: "AppManagement",
    components: {
      AppPeopleList,
      DynamicMenu
    },
    metaInfo() {
      return {
        title: 'Руководство | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Руководство. Подробная информация о Алтайском краевом клиническом перинатальном центре ДАР.'
          },
          {
            name: 'keywords',
            content: 'Алтайский краевой клинический перинатальный центр'
          }
        ]
      };
    },
    computed: {
      ...mapState({
        isLoading: (state) => state.department.isLoading,
        MANAGEMENTS: (state) => state.department.MANAGEMENTS,
        CHIEF_MEDICAL_OFFICER: (state) => state.department.CHIEF_MEDICAL_OFFICER,
      }),

    },
    data() {
      return {
        menuItems: [
          { text: "О центре", link: "/about", active: false },
          { text: "Блог главного врача", link: "/about/blog", active: false },
          { text: "Руководство", link: "/about/management", active: true },
          { text: "Специалисты", link: "/about/specialists", active: false },
          { text: "Общие сведения", link: "/about/general-info", active: false },
          { text: "Документы", link: "/documents", active: false },
          { text: "Режим работы", link: "/about/schedule", active: false },
          { text: "Контакты", link: "/about/contacts", active: false },
          { text: "Вакансии", link: "/about/vacancies", active: false },
          { text: "Новости", link: "/about/news", active: false },
          { text: "Отзывы", link: "/about/reviews", active: false },
          { text: "Галерея", link: "/about/gallery", active: false }
        ],

      };
    },
    methods: {
      fetchGetAllDoctors() {
        this.$store.dispatch(actionTypesDepartment.getAllDoctors)
      },
    },
    mounted() {
      this.fetchGetAllDoctors()
    }
  };
</script>

<style scoped>

</style>
