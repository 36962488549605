<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__title-left">
        <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">Пациентам</h1>
      </div>
        <div class="">
          <app-article-patients-list :patients-article="articlePatients"/>
        </div>

    </div>
  </div>
</template>

<script>

  import AppArticlePatientsList from '@/components/Article/ArticlePatientsList.vue'

  export default {
    name: "AppPatients",
    components: { AppArticlePatientsList, },
    computed: {

    },
    metaInfo() {
      return {
        title: 'Пациентам | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Подробная информация о Алтайском краевом клиническом перинатальном центре ДАР'
          },
          {
            name: 'keywords',
            content: 'пациентам, алтайский краевой клинический перинатальный центр, перинатальный центр, дар'
          }
        ]
      };
    },
    data() {
      return {
        articlePatients: [
          { id:"1", image: require('../../assets/img/patients/patients1.jpg'),
          title:"Услуги ОМС"
          },
          { id:"2", image: require('../../assets/img/patients/patients2.jpg'),
            title:"Правила записи на прием"
          },
          { id:"3",image: require('../../assets/img/patients/patients3.jpg'),
            title:"Анализы"
          },
          { id:"4", image: require('../../assets/img/patients/patients4.jpg'),
            title:"Госпитализация"
          },
          { id:"5", image: require('../../assets/img/patients/patients5.png'),
            title:"Иностранным гражданам"
          },
          { id:"6", image: require('../../assets/img/patients/patients6.jpg'),
            title:"Справка для налогового вычета"
          },
          { id:"7", image: require('../../assets/img/patients/patients7.jpg'),
            title:"График приема граждан"
          },
          { id:"8", image: require('../../assets/img/patients/patients8.jpg'),
            title:"Список продуктов для передачи"
          },
          { id:"9", image: require('../../assets/img/patients/patients9.jpg'),
            title:"Молитвенная комната"
          },
          { id:"10",image: require('../../assets/img/patients/patients10.jpg'),
            title:"Медицинский туризм"
          },

    ]
      };
    },
    methods: {

    },
    mounted() {

    }
  };
</script>

<style scoped>

</style>
