<template>
  <div class="general-info">
    <div class="general-info__container">
      <AppBreadCrumbs/>
      <div class="general-info__title-left">
        <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">Лечение бесплодия</h1>
      </div>
      <div class="under-department-content">
        <div class="under-department-content__first-block">
          <h2 class="bold">Программы ЭКО</h2>

          <h3>В центре осуществляются все виды вспомогательных репродуктивных технологий:</h3>

          <h3>Стандартная программа ЭКО</h3>
          <p>Включает: стимуляцию овуляции под ультразвуковым контролем, консультации репродуктолога, пункцию фолликулов яичников под внутривенной анестезией, оплодотворение полученных ооцитов и их культивирование в питательной среде.</p>

          <h3>ЭКО в естественном цикле</h3>
          <p>Подходит женщинам с низким овариальным резервом, а также тем, кому стимуляция суперовуляции противопоказана. Программа дает возможность получить 1, иногда 2 яйцеклетки без использования гормональных препаратов. Применяется чаще всего при наличии онкологических заболеваний, отсутствии реакции яичников на стимуляцию, получении некачественных ооцитов или крайне малом запасе яйцеклеток в яичниках.</p>

          <h3>ЭКО с использованием донорских ооцитов</h3>
          <p>При низком овариальном резерве супругам предлагается использовать донорские ооциты. Это один из наиболее безопасных методов с минимальной медикаментозной нагрузкой и сравнительно низким уровнем денежных затрат (если сравнивать со стоимостью препаратов, применяемых для стимуляции суперовуляции при стандартном ЭКО).</p>

          <h3>ЭКО с ИКСИ/ПИКСИ</h3>
          <p>С целью отбора лучших сперматозоидов были разработаны методы селекции ПИКСИ и ИКСИ.</p>

          <p>В случае с ИКСИ эмбриолог отбирает самый здоровый, жизнеспособный сперматозоид и внедряет его с помощью инъекции внутрь яйцеклетки. Этот метод применяется в самых тяжелых случаях мужского бесплодия.</p>

          <p>Метод ПИКСИ построен на молекулярном анализе, который позволяет дать оценку не только морфологии сперматозоида, но и его способности пройти оболочку яйцеклетки. Применяется при неудачных попытках ЭКО/ИКСИ, пониженных показателях спермограммы, патологии ДНК фрагментации сперматозоидов.</p>

          <h3>Положительные стороны ЭКО в естественном цикле:</h3>
          <ul class="under-department-content__list">
            <li>Низкая вероятность многоплодной беременности при переносе только одного эмбриона</li>
            <li>Минимальные шансы развития синдрома гиперстимуляции яичников</li>
            <li>Возможность проведения процедуры в нескольких менструальных циклах</li>
          </ul>

          <p>Наличие или отсутствие противопоказаний для ЭКО и выбор индивидуальной тактики лечения для супружеской пары определяется репродуктологом. Иногда может потребоваться консультация смежных специалистов - эндокринолога, онколога, маммолога, невролога, гематолога и др.</p>

          <p>Пациенты могут пройти лечение методом ЭКО как платно, так и по программе обязательного медицинского страхования. Индивидуальный подход и высококачественные услуги гарантированно получит каждый пациент центра.</p>

        </div>
<!--        <div class="under-department-content__second-block">-->
<!--          <div class="under-department-content__second-block-image" >-->
<!--            <img src="../../assets/img/eco/eco3.jpg" alt="Диагностика причин бесплодия" class="under-department-content__image" />-->
<!--          </div>-->
<!--          &lt;!&ndash;          <div class="under-department-content__second-block-text" v-html="contentTwo">&ndash;&gt;-->
<!--          &lt;!&ndash;          </div>&ndash;&gt;-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
  import AppBreadCrumbs from '@/components/BreadCrumbs.vue'

  export default {
    name: "AppInfertilityTreatment",
    components: { AppBreadCrumbs },
    props: {

    },
    metaInfo() {
      return {
        title: 'Лечение бесплодия | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Узнайте все об услугах экстракорпорального оплодотворения (ЭКО) в Алтайском краевом клиническом перинатальном центре ДАР. Мы предлагаем современные методы лечения бесплодия с использованием передовых технологий. Доверьте ваше здоровье нашим опытным специалистам.'
          },
          {
            name: 'keywords',
            content: 'эко, экстракорпоральное оплодотворение, методы лечения,  Алтайский краевой клинический перинатальный центр'
          }
        ]
      };
    },
    computed: {

    },
    methods: {

    },
    mounted() {
    }

  };
</script>

<style scoped>

</style>
