<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__title-left">
        <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">Отделения</h1>
      </div>
      <AppLoader v-if="isLoading"/>
      <template v-else>
      <AppDepartmentItem  v-for="department in departments" :key="department.id" :item_department="department"/>
      </template>
    </div>
  </div>
</template>

<script>
  import AppDepartmentItem from '@/components/Departments/DepartmentItem.vue'
  import { actionTypesDepartment } from "@/store/modules/department";
  import { mapState } from 'vuex'
  import AppLoader from '@/components/Loader.vue'
  export default {
    name: "AppDepartment",
    components: { AppLoader, AppDepartmentItem },
    metaInfo() {
      return {
        title: 'Отделения | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Отделения Алтайского краевого клинического перинатального центра ДАР.Подготовьтесь к родам вместе с Алтайским краевым клиническим перинатальным центром ДАР.'
          },
          {
            name: 'keywords',
            content: 'роды, сопровождение родов, родильный дом, партнерские роды, послеродовой уход, перинатальный центр ДАР'
          }
        ]
      };
    },
    computed: {
      ...mapState({
        departments: (state) => state.department.departments,
        isLoading: (state) => state.department.isLoading,
      }),
    },
    data() {
      return {
      };
    },
    methods: {

    },
    mounted() {
      this.$store.dispatch(actionTypesDepartment.getDepartment)
          .then(() => {
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
    }
  };
</script>

<style scoped>

</style>
