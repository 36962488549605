<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row general-info__align-items">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title">
            <h1 class="text-TTDrugs-Bold-40 color-green left text-uppercase">Мedical Tourism</h1>
          </div>
          <div class="general-info__block-row">
            <div class="">
              <h3>BASIC INFORMATION ABOUT MEDICAL ORGANIZATION</h3>
              <p>The perinatal center "DAR" is a third-level medical organization.</p>

              <p><span class="bold">Our mission:</span> within our professional competence, to provide each family with the desired number of healthy children, preserving the life and health of the mother.</p>

              <p>The center’s staff is a team of highly qualified doctors with extensive experience, our team has 3 doctors of medical sciences, 12 candidates of medical sciences, 59 doctors have the highest qualification category.</p>

              <div class="general-info__index-mg-mini">
                <img src="../../assets/img/poster-index.png" alt="poster">
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="">

        <p>On a total area of 34 thousand square meters, 5900 units of medical equipment are located. Such equipment allows you to solve the most serious problems in obstetrics, gynecology, and neonatology.</p>

        <p>The center operates in the system of compulsory medical insurance, along with the provision of high-tech medical care and assistance within the framework of the Territorial Program of State Guarantees, the Altai Regional Clinical Perinatal Center KGBUZ provides paid medical services, including work under contracts with insurance companies, enterprises, organizations, and institutions within the framework of voluntary medical insurance.</p>


      </div>
      <section class="statistics-section4" >
        <div class="statistics-section4__container statistics-section4__wrap-index-block">
          <div class="statistics-section4__index-block-white" >
            <div class="statistics-section4__img">
              <img src="../../assets/img/departments/akusherstij-stacioner/akusherstij-stacioner1.jpg" alt="Акушерство">
            </div>
            <div class="statistics-section4__wrap-info-text" >
              <div class="statistics-section4__title">
                <p class="text-TTDrugs-Bold-40 color-green">OBSTETRICS</p>
              </div>
              <div class="statistics-section4__flex">
                <div class="statistics-section4__description">
                  <p class="text-circe-bold-22 color-green">MONITORING THROUGHOUT THE ENTIRE PREGNANCY TERM</p>
                </div>
                <div class="statistics-section4__text">
                  <ul class="under-department-content__list">
                    <li>Diagnostics: ultrasound expert class;</li>
                    <li>Consultation reception of leading experts on women's health, pregnancy, and childbirth;</li>
                    <li>Admission of narrow specialists - geneticist, endocrinologist, ophthalmologist, etc.;</li>
                    <li>The only department of pregnancy pathology in Siberia specializing in the treatment of women with diabetes.</li>
                  </ul>

                  <p class="under-department-content__paragraph">Accompanying childbirth with a personal doctor and midwife. Family ward of the postpartum department with the possibility of a round-the-clock stay of the father (or other relative).</p>

                </div>
              </div>
              <div class="statistics-section4__btn" @click="routeGoAkusherskiyStasionar">
                <button class="index-btn">More details</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="statistics-section3">
        <div class="statistics-section3__container">
          <div class="statistics-section3__wrap-index-block-white">
            <div class="statistics-section3__index-block">
              <div class="statistics-section3__title">
                <p class="text-TTDrugs-Bold-40 color-green"> GYNECOLOGY</p>
              </div>
              <div class="statistics-section3__wrap-info-text">
                <div class="statistics-section3__flex">
                  <div class="statistics-section3__text">
                    <ul class="under-department-content__list">
                      <li>Organ-saving operations on the uterus and appendages using modern endoscopic equipment;</li>
                      <li>Reconstructive plastic surgery for genital prolapse;</li>
                      <li>Intimate plastic;</li>
                      <li>A comprehensive program for the rehabilitation of the endometrium in patients with infertility and habitual miscarriage;</li>
                    </ul>

                    <p class="under-department-content__paragraph">In treatment are used:</p>
                    <ul class="under-department-content__list">
                      <li>modern physiotherapeutic techniques;</li>
                      <li>hirudotherapy;</li>
                      <li>mud therapy.</li>
                    </ul>

                  </div>
                  <div class="statistics-section3__btn" @click="routeGoGinecologita">
                    <button class="index-btn">More details</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="statistics-section3__img">
              <img src="../../assets/img/departments/gynecology/gynecology1.jpg" alt="Гинекология">
            </div>
          </div>
        </div>
      </section>
      <section class="statistics-section4" >
        <div class="statistics-section4__container statistics-section4__wrap-index-block">
          <div class="statistics-section4__index-block-white" >
            <div class="statistics-section4__img">
              <img src="../../assets/img/departments/eco/eco2.jpg" alt="Акушерство">
            </div>
            <div class="statistics-section4__wrap-info-text" >
              <div class="statistics-section4__title">
                <p class="text-TTDrugs-Bold-40 color-green">REPRODUCTION CENTER</p>
              </div>
              <div class="statistics-section4__flex">
                <div class="statistics-section4__text">
                  <p>Highly qualified reproductive specialists at the perinatal center are ready to help. We strive to achieve the best possible results while maintaining physical and psychological comfort for the patient. Specialists will select an individual IVF protocol scheme that will surely lead to success.</p>
                  <ul class="under-department-content__list">
                    <li>Diagnosis and treatment of all types of infertility;</li>
                    <li>IVF;</li>
                    <li>PIXY;</li>
                    <li>ICSI;</li>
                    <li>Testicular biopsy;</li>
                    <li>Immunohistochemical study of endometrium;</li>
                    <li>Intrauterine insemination;</li>
                    <li>Auxiliary hatching;</li>
                    <li>Cryopreservation of germ cells and embryos;</li>
                    <li>Bank of donor oocytes;</li>
                    <li>Surrogacy program.</li>
                  </ul>
                  <p class="under-department-content__paragraph">The list of available medical services can be clarified on the page <a href="page link">Paid services</a>.</p>
                </div>
              </div>
              <div class="statistics-section4__btn" @click="routeGoECO">
                <button class="index-btn">More details</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="under-department-content">
        <div class="under-department-content__first-block-info">
          <div class="under-department-content__first-block">
            <h2>ALGORITHM FOR APPEALING TO A MEDICAL ORGANIZATION OF A FOREIGN PATIENT</h2>

            <p>A foreign citizen applies to the center registry:</p>
            <p>1.1. The medical registrar sends a foreign citizen to a specialist doctor in the chosen profile.</p>
            <p>2. Specialist in the selected profile:</p>
            <p>2.1. Determines the type of medical care (ambulance, emergency, planned);</p>
            <p>2.2. Establishes the status of a foreign citizen (residence permit, temporary residence or residence permit, citizenship, existence of an insurance policy);</p>
            <p>2.3. Determines the amount of medical care;</p>
            <p>2.4. Provides medical care under applicable law.</p>
            <p>3. Having determined the status of a foreign citizen and the scope of services provided, a specialist doctor (manager):</p>
            <p>3.1. The source of payment is agreed:</p>
            <ul class="under-department-content__list">
              <li>personal funds;</li>
              <li>funds of the insurance company;</li>
              <li>others.</li>
            </ul>
            <p>3.2. In the case of the provision of planned types of medical care at the personal expense of the patient or other sources, with the exception of insurance companies, accompanied by a nurse is sent to the administrator of paid services to conclude an agreement and pay for the services rendered;</p>
            <p>3.3. If it is necessary to conduct additional examinations in other healthcare organizations, the patient is referred for further treatment and examination.</p>

            <h3>CONTACT INFORMATION</h3>
            <p>
              Address: Barnaul, Fomina St., 154<br>
              Phone of the department of paid services: 8 (3852) 5691-130<br>
              Head of Corporate Communications and Extrabudgetary Activities Sechenova Lilya Ivanovna<br>
              Phone: 8 (3852) 591-130<br>
              Email: <a href="mailto:darmedturizm@mail.ru">darmedturizm@mail.ru</a>
            </p>

          </div>
        </div>
        <div class="under-department-content__icon">
          <div class="under-department-content__icon-mini">
            <router-link to="/medical-tourism">
              <img src="../../assets/svg/russia.svg" alt="russia">
            </router-link>
          </div>
          <div class="under-department-content__icon-mini">
            <a href="https://russiamedtravel.ru/" target="_blank"> <img src="../../assets/img/russiamedtravel.jpg" alt="russiamedtravel"></a>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  export default {
    name: "AppMedicalTourismEnglish",
    components: {   DynamicMenu },
    data() {
      return {
        nameDepartment: 'Медицинский туризм',
        linksDepartment: 'medical-tourism"',
        menuItems: [
          { text: "Услуги ОМС", link: "/oms", active: false },
          { text: "Правила записи на прием", link: "/rules", active: false },
          { text: "Анализы", link: "/tests", active: false },
          { text: "Госпитализация", link: "/hospitalization", active: false },
          { text: "Иностранным гражданам", link: "/foreigners", active: false },
          { text: "Справка для налогового вычета", link: "/tax-refund", active: false },
          { text: "График прима граждан", link: "/schedule", active: false },
          { text: "Список продуктов для передачи", link: "/list-product", active: false },
          { text: "Молитвенная комната", link: "/prayer-room", active: false },
          { text: "Медицинский туризм", link: "/medical-tourism", active: true }
        ],

      }
    },
    metaInfo() {
      return {
        title: 'Medical tourism | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Подробная информация о Алтайском краевом клиническом перинатальном центре ДАР'
          },
          {
            name: 'keywords',
            content: 'пациентам, алтайский краевой клинический перинатальный центр, перинатальный центр, дар'
          }
        ]
      };
    },
    methods: {
      routeGoAkusherskiyStasionar() {
        this.$router.push('/department/akusherskij-stacionar')
      },
      routeGoGinecologita() {
        this.$router.push('/department/centr-ginekologii')
      },
      routeGoECO() {
        this.$router.push('/family-center')
      }

    }


  };
</script>

