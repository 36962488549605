import axios from "@/api/axios";

const getNews = () => {
  return axios.get(`/news`);
};
const getNewsItem = (id) => {
  return axios.get(`/news/${id}`);
}
export default {
  getNews,
  getNewsItem,

};
