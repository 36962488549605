<template>
  <div class="view-doctors">
    <div class="view-doctors__container">
      <div class="view-doctors__row">
        <DynamicMenu :menuItems="menuItems" />
        <div class="view-doctors__column">
          <div class="view-doctors__title">
            <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">Наши специалисты</h1>
          </div>
          <AppLoader v-if="isLoading"/>
          <div class="view-doctors__list-specialist">
            <nav class="filter__body">
              <ul class="filter__list">
                <li class="filter__item"   @click="filterSpecialists('Все специалисты')" >
                  <p class="text-TTDrugs-regular-14 text-uppercase"  :class="{ active: selectedFilter === 'Все специалисты' }" >
                    Все специалисты</p>
                </li>
                <li class="filter__item" v-for="department in departments" :key="department.id"   @click="filterSpecialists(department.links)" >
                  <p class="text-TTDrugs-regular-14 text-uppercase" :class="{ active: selectedFilter === department.links }">
                    {{ department.name }}</p>
                </li>
              </ul>
            </nav>
            <div class="view-doctors__search">
              <div class="view-doctors__search-title">
                <p class="text-TTDrugs-regular-16 color-gray">Врачи КГБУЗ Алтайского краевого клинического перинатального центра "ДАР"</p>
              </div>
              <input
                  type="text"
                  v-model="searchQuery"
                  placeholder="Введите фамилию или имя врача..."
                  class="search-input-people text-circe-16"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="" v-if="filteredDoctors && filteredDoctors.length">
        <AppDoctorsPeopleList :people_list="filteredDoctors"/>
      </div>
      <div class="" v-else>
        <p class="text-TTDrugs-bold-24 color-gray center" style="margin-bottom: 20px">Специалистов не найдено*</p>
      </div>
    </div>
  </div>
</template>

<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  import AppDoctorsPeopleList from '@/components/People/DoctorsPeopleList.vue'
  import { actionTypesDepartment } from '@/store/modules/department'
  import { mapState } from 'vuex'
  import AppLoader from '@/components/Loader.vue'

  export default {
    name: "AppDoctors",
    components: { AppLoader, AppDoctorsPeopleList, DynamicMenu },
    metaInfo() {
      return {
        title: 'Специалисты | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Специалисты. Подробная информация о Алтайском краевом клиническом перинатальном центре ДАР.'
          },
          {
            name: 'keywords',
            content: 'Алтайский краевой клинический перинатальный центр'
          }
        ]
      };
    },
    data() {
      return {
        menuItems: [
          { text: "О центре", link: "/about", active: false },
          { text: "Блог главного врача", link: "/about/blog", active: false },
          { text: "Руководство", link: "/about/management", active: false },
          { text: "Специалисты", link: "/about/specialists", active: true },
          { text: "Общие сведения", link: "/about/general-info", active: false },
          { text: "Документы", link: "/documents", active: false },
          { text: "Режим работы", link: "/about/schedule", active: false },
          { text: "Контакты", link: "/about/contacts", active: false },
          { text: "Вакансии", link: "/about/vacancies", active: false },
          { text: "Новости", link: "/about/news", active: false },
          { text: "Отзывы", link: "/about/reviews", active: false },
          { text: "Галерея", link: "/about/gallery", active: false }
        ],
        searchQuery: '',
        selectedFilter: 'Все специалисты',
      }
    },
    watch: {
      searchQuery(val) {
        this.performFioSearch(val);
      },
    },
    computed: {
      ...mapState({
        departments: (state) => state.department.departments,
        isLoading: (state) => state.department.isLoading,
        EMPLOYEES: (state) => state.department.EMPLOYEES,
        doctors: (state) => state.department.doctors,
      }),
      filteredDoctors() {
        if(this.selectedFilter === 'Все специалисты') {
          return this.EMPLOYEES
        } else {
          return this.doctors
        }
      }
    },
    methods: {
      filterSpecialists(mainDepartment) {
        this.selectedFilter = mainDepartment;
        this.getDoctorsDepartment(mainDepartment)
      },
      getDoctorsDepartment(item) {
        this.$store.dispatch(actionTypesDepartment.getDoctorsDepartment, item)
            .then(() => {
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
      fetchGetAllDoctors() {
        this.$store.dispatch(actionTypesDepartment.getAllDoctors)
      },
      performFioSearch(fio) {
        if (fio !== "") {
          this.$store.dispatch(actionTypesDepartment.getSearchFio, fio);
        } else {
          this.$store.dispatch(actionTypesDepartment.getAllDoctors);
        }
      },
    },
    mounted() {
      this.$store.dispatch(actionTypesDepartment.getDepartment)
          .then(() => {
           this.fetchGetAllDoctors()
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
    }
  };
</script>

<style>
.view-doctors__search {
  display: flex;
  flex-direction: column;
  margin: 20px 0px 0px 0px;
}

.view-doctors__search-title {
  margin: 0px 0px 15px 0px;
}

.search-input-people {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 10px;
  /* Сброс стилей */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  box-shadow: none;
  outline: none;

}


.view-doctors__results {
  margin: 20px 0;
}

.doctor-item {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
</style>
