<template>
  <div class="not-found">
    <h1 class="not-found__title text-TTDrugs-regular-24">404 - Страница не найдена</h1>
    <p class="not-found__message text-TTDrugs-bold-18">Извините, но страница, которую вы ищете, не существует.</p>
    <router-link to="/" class="not-found__link color-green text-circe-bold-20">Вернуться на главную страницу</router-link>
  </div>
</template>

<script>
  export default {
    name: 'NotFound',
    metaInfo() {
      return {
        title: 'Не найдено | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'перенатальный центр ДАР'
          },
          {
            name: 'keywords',
            content: 'пациентам, алтайский краевой клинический перинатальный центр, перинатальный центр, дар'
          }
        ]
      };
    },
  }
</script>
