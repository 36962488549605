<template>
  <div class="general-info">
    <div class="general-info__container">
      <AppBreadCrumbs/>
      <div class="general-info__title-left">
        <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">Персональная акушерка на роды</h1>
      </div>

      <div class="under-department-content">
        <div class="under-department-content__first-block">
          <h2>«Не бойся, я с тобой!»</h2>

          <p>В перинатальном центре «ДАР» теперь доступна услуга по выбору персональной акушерки на роды. Ее стоимость составляет 20 000 рублей.</p>

          <p>В центре уже определен круг специалистов, которые будут оказывать роженице квалифицированную медицинскую помощь. Это акушерки:</p>

          <ul class="under-department-content__list-max">
            <li><b>Кутейкина Юлия Владимировна</b>, исполняющий обязанности главной акушерки, высшая квалификационная категория. Клинический психолог. Общий стаж 19 лет.</li>
            <li><b>Моисеева Надежда Михайловна</b> – акушерка, высшая квалификационная категория. Общий стаж 27 лет.</li>
            <li><b>Кириллова Елена Александровна</b> - акушерка, высшая квалификационная категория. Общий стаж 26 лет.</li>
            <li><b>Курасова Анастасия Алексеевна</b> – акушерка, высшая квалификационная категория. Общий стаж 8 лет.</li>
            <li><b>Андреева Елена Игоревна</b> - акушерка, высшая квалификационная категория. Общий стаж 15 лет.</li>
            <li><b>Хабарова Любовь Алексеевна</b> - акушерка, высшая квалификационная категория. Общий стаж 17 лет.</li>
            <li><b>Орлова Ирина Владимировна</b> – старшая акушерка акушерского обсервационного отделения. Общий стаж 15 лет.</li>
            <li><b>Бояркина Наталья Анатольевна</b> – акушерка, высшая квалификационная категория. Общий стаж 29 лет.</li>
            <li><b>Чернова Евгения Ивановна</b> – акушерка, вторая квалификационная категория. Общий стаж 12 лет.</li>
            <li><b>Кривцова Светлана Викторовна</b> – акушерка. Общий стаж 2 года.</li>
            <li><b>Насирова Сабина Бахадир Кызы</b> - акушерка. Общий стаж 2 года.</li>
            <li><b>Сопова Наталья Николаевна</b>, исполняющая обязанности старшей акушерки родового отделения, высшая квалификационная категория. Общий стаж 27 лет.</li>
          </ul>

        </div>
        <div class="under-department-content__second-block">
          <div class="under-department-content__second-block-image">
            <img src="../../assets/img/rody/rody2.jpg" alt="Роды 2" class="under-department-content__image" />
          </div>
          <div class="under-department-content__second-block-text">
            <h2>Акушерка – это специалист, который наиболее полно понимает потребности рожающей женщины</h2>

            <p>Акушерка – это специалист, который наиболее полно понимает потребности рожающей женщины. В родах они индивидуальны: кому-то необходимо постоянное общение, кто-то хочет «незаметного» присутствия помощника. При этом и те, и другие справедливо ожидают качественного родовспоможения.</p>

            <p>Личная акушерка будет сопровождать физиологические роды, без осложненного анамнеза.</p>

            <p>Отметим, что акушерка не заменяет врача. В обязанности последнего, согласно приказу, входит изучение анамнеза поступившей в родовое отделение женщины и составление индивидуального плана родов. Именно врач проводит влагалищный осмотр, оценивает клиническую ситуацию и прогрессию родов.</p>

            <p>Акушерка же выполняет по назначению врача необходимые диагностические и лечебно-профилактические манипуляции. Она же измеряет пульс и артериальное давление, сердцебиение малыша; применяет методы немедикаментозного обезболивания, в том числе с использованием дыхательных техник, массажа, фитбола, физических упражнений, акупунктуры. Личная акушерка постоянно находится в родзале и при необходимости оказывает женщине в том числе психологическую поддержку. Она поддерживает и благоприятно относится к ведению партнерских родов.</p>

          </div>
        </div>
      </div>
      <div class="general-info-department">
        <div class="general-info-department__info-block">
          <div class="general-info-department__title-left">
            <p class="text-TTDrugs-Bold-40 color-green center text-uppercase">Специалисты</p>
          </div>
          <div class="general-info-department__description">
            <p class="text-circe-20 color-gray">Наши специалисты </p>
          </div>
        </div>
        <div class="general-info-department__thank">
          <BlockThankYou/>
        </div>
      </div>
      <AppDoctorsPeopleList :people_list="doctors"/>
    </div>
  </div>
</template>

<script>
  import AppBreadCrumbs from '@/components/BreadCrumbs.vue'
  import { actionTypesDepartment } from '@/store/modules/department'
  import AppDoctorsPeopleList from '@/components/People/DoctorsPeopleList.vue'
  import BlockThankYou from '@/components/BlockThankYou.vue'
  import { mapState } from 'vuex'

  export default {
    name: "AppPersonalDoctors",
    components: { BlockThankYou, AppDoctorsPeopleList,  AppBreadCrumbs },
    data() {
      return {
        nameDepartment: 'Акушерский стационар'
      }
    },
    metaInfo() {
      return {
        title: 'Персональная акушерка на роды | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Подготовьтесь к родам вместе с Алтайским краевым клиническим перинатальным центром ДАР. Узнайте о наших услугах по сопровождению родов, условиях пребывания в родильном доме, партнерских родах и послеродовом уходе. Мы обеспечим вам комфорт и безопасность на всех этапах родов.'
          },
          {
            name: 'keywords',
            content: 'роды, сопровождение родов, родильный дом, партнерские роды, послеродовой уход, перинатальный центр ДАР'
          }
        ]
      };
    },
    props: {

    },
    computed: {
      ...mapState({
        doctors: (state) => state.department.doctors,
        isLoading: (state) => state.department.isLoading,
      }),
    },
    methods: {
      getDoctorsUnderDepartment() {
        this.$store.dispatch(actionTypesDepartment.getDoctorsDepartment,this.nameDepartment)
            .then(() => {
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      }
    },
    mounted() {
      this.getDoctorsUnderDepartment()
    }

  };
</script>

<style scoped>

</style>
