import { render, staticRenderFns } from "./SectionNewsDetails.vue?vue&type=template&id=4b19a8dc"
import script from "./SectionNewsDetails.vue?vue&type=script&lang=js"
export * from "./SectionNewsDetails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports