<template>
  <div class="vacancy__item">
    <div class="vacancy__body">
    <p class="text-TTDrugs-regular-24">{{ item_vacancy.title }}</p>
    <p class="vacancy__salary text-circe-bold-18 color-gray">{{ item_vacancy.salary }}</p>
    <div class="vacancy__section" v-if="item_vacancy.duties && item_vacancy.duties.length">
      <p class="vacancy__section-title text-TTDrugs-bold-20 color-gray">Обязанности</p>
      <ul>
        <li v-for="duty in item_vacancy.duties" :key="duty"  class="text-circe-16">{{ duty }}</li>
      </ul>
    </div>
    <div class="vacancy__section" v-if="item_vacancy.requirements && item_vacancy.requirements.length">
      <p class="vacancy__section-title  text-TTDrugs-bold-20 color-gray">Требования<p/>
      <ul>
        <li v-for="requirement in item_vacancy.requirements" :key="requirement" class="text-circe-16">{{ requirement }}</li>
      </ul>
    </div>
    <div class="vacancy__section" v-if="item_vacancy.conditions && item_vacancy.conditions.length">
      <p class="vacancy__section-title  text-TTDrugs-bold-20 color-gray">Условия</p>
      <ul>
        <li v-for="condition in item_vacancy.conditions" :key="condition"  class="text-circe-16">{{ condition }}</li>
      </ul>
    </div>
    </div>
    <div class="vacancy__footer">
    <p class="vacancy__contact text-TTDrugs-bold-18 color-white">Анкету направлять по электронной почте: <a :href="'mailto:' + 'ok_dar@bk.ru'">ok_dar@bk.ru</a></p>
    <p class="vacancy__link text-TTDrugs-bold-18 color-white"><a :href="'/documents/anketa.doc'" download>Заполнить анкету</a></p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AppVacancyItem',
    data() {
      return {

      }
    },
    props: {
      item_vacancy: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    methods: {

    }
  }
</script>



