<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title">
            <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">Контакты</h1>
          </div>
          <div class="general-info__row">
          <div class="general-info__information-contact">
            <div class="general-info__information-text-column">
              <div class="general-info__information-text-title">
                <p class="text-TTDrugs-bold-16">Справочная:</p>
              </div>
              <p class="text-circe-16">8 (3852) 591-130</p>
              <p class="text-circe-16">8 (963) 536-54-08</p>
            </div>
            <div class="general-info__information-text-column">
              <div class="general-info__information-text-title">
                <p class="text-TTDrugs-bold-16">Email:</p>
              </div>
              <p class="text-circe-16">akkpc@zdravalt.ru</p>
            </div>
            <div class="general-info__information-text-column">
              <div class="general-info__information-text-title">
                <p class="text-TTDrugs-bold-16">Адрес фактический:</p>
              </div>
              <p class="text-circe-16">656045, Алтайский край, г. Барнаул, ул. Фомина, д. 154</p>
            </div>
            <div class="general-info__information-text-column">
              <div class="general-info__information-text-title">
                <p class="text-TTDrugs-bold-16">Обособленные структурные подразделения:</p>
              </div>
              <p class="text-circe-16">659323, Алтайский край, г. Бийск, ул. Садовая, д. 210. тел.: 8 (3854) 37-57-02 <br/> 658213 Алтайский край, г. Рубцовск, Ленина проспект, д. 13 тел.: 8 (3855) 79-62-07 <br/> 658823, Алтайский край, г. Славгород, с. Славгородское, ул. Ленина, д. 288<br/>
              </p>
            </div>
            <div class="general-info__information-text-column">
              <div class="statistics-section3__btn" @click="goToSchedule">
                <button class="index-btn">Узнать режим работы отделений</button>
              </div>
            </div>
          </div>
          <div class="general-info__img">
            <img src="../../assets/img/dar-image.jpg" alt="">
          </div>
          </div>
        </div>
      </div>
      <div class="">
        <section class="section-map">
          <div class="div-blur"></div>
          <YandexMap :center="[53.305432, 83.759842]" :zoom="18" />
        </section>
      </div>
    </div>
  </div>

</template>
<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  import YandexMap from '@/components/Map/MapYandex.vue'

  export default {
    name: "AppContact",
    components: { YandexMap,  DynamicMenu },
    metaInfo() {
      return {
        title: 'Контакты | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Телефоны: (3852) 56-93-30, +7 (963) 536 54 08; Адрес: Алтайский край, г. Барнаул, ул. Фомина, д. 154. Контактная информация о Алтайском краевом клиническом перинатальном центре ДАР.'
          },
          {
            name: 'keywords',
            content: 'контакты, телефоны, почта, обращение'
          }
        ]
      };
    },
    data() {
      return {
        menuItems: [
          { text: "О центре", link: "/about", active: false },
          { text: "Блог главного врача", link: "/about/blog", active: false },
          { text: "Руководство", link: "/about/management", active: false },
          { text: "Специалисты", link: "/about/specialists", active: false },
          { text: "Общие сведения", link: "/about/general-info", active: false },
          { text: "Документы", link: "/documents", active: false },
          { text: "Режим работы", link: "/about/schedule", active: false },
          { text: "Контакты", link: "/about/contacts", active: true },
          { text: "Вакансии", link: "/about/vacancies", active: false },
          { text: "Новости", link: "/about/news", active: false },
          { text: "Отзывы", link: "/about/reviews", active: false },
          { text: "Галерея", link: "/about/gallery", active: false }
        ],
      }
    },
    methods: {
      goToSchedule() {
        this.$router.push('/schedule')
      }
    }
  };
</script>
