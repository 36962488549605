<template>
  <transition name="modal" @click="$emit('close')">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container max-width">
          <div class="modal-header">
            <p class="text-circe-bold-20 color-green">Задать вопрос</p>
            <div class="modal-header__cross" @click="$emit('close')">
              <img src="../../assets/svg/CrossModal.svg" alt="cross" />
            </div>
          </div>
          <div class="error">
            <AppValidationErrors
                v-if="validationErrorsModal"
                :validation-errors="validationErrorsModal"
            />
          </div>
          <div class="error" v-if="isSubmitted && this.$v.$invalid">
            <p class="error-text">*Заполните все пустые поля</p>
          </div>
          <div class="modal-body">
            <!-- Поля формы -->
            <div class="modal-body__wrap-input">
              <label for="fullName" class="label-for-input">ФИО</label>
              <input
                  type="text"
                  name="fullName"
                  v-model="fullName"
                  placeholder="Введите ФИО"
                  class="input-text"
                  :class="{
                  'error-border-input': isSubmitted && $v.fullName.$error,
                }"
              />
            </div>
            <div class="modal-body__wrap-input">
              <label for="phone" class="label-for-input">Телефон</label>
              <input
                  type="text"
                  name="phone"
                  placeholder="Введите телефон"
                  v-model="phone"
                  class="input-text"
                  :class="{
                  'error-border-input': isSubmitted && $v.phone.$error,
                }"
              />
            </div>

            <div class="modal-body__wrap-input">
              <label for="postalCode" class="label-for-input">Почтовый индекс</label>
              <input
                  type="text"
                  name="postalCode"
                  v-model="postalCode"
                  placeholder="Введите почтовый индекс"
                  class="input-text"
                  :class="{
                  'error-border-input': isSubmitted && $v.postalCode.$error,
                }"
              />
            </div>
            <div class="modal-body__wrap-input">
              <label for="address" class="label-for-input">Улица, номер дома, квартиры</label>
              <input
                  type="text"
                  name="address"
                  v-model="address"
                  placeholder="Введите адрес"
                  class="input-text"
                  :class="{
                  'error-border-input': isSubmitted && $v.address.$error,
                }"
              />
            </div>
            <div class="modal-body__wrap-input">
              <label for="city" class="label-for-input">Населенный пункт</label>
              <input
                  type="text"
                  name="city"
                  v-model="city"
                  placeholder="Введите населенный пункт"
                  class="input-text"
                  :class="{
                  'error-border-input': isSubmitted && $v.city.$error,
                }"
              />
            </div>
            <div class="modal-body__wrap-input">
              <label for="email" class="label-for-input">E-mail</label>
              <input
                  type="email"
                  name="email"
                  v-model="email"
                  placeholder="Введите e-mail"
                  class="input-text"
                  :class="{
                  'error-border-input': isSubmitted && $v.email.$error,
                }"
              />
            </div>
            <div class="modal-body__wrap-input">
              <label for="subject" class="label-for-input">Тема обращения</label>
              <input
                  type="text"
                  name="subject"
                  v-model="subject"
                  placeholder="Введите тему обращения"
                  class="input-text"
                  :class="{
                  'error-border-input': isSubmitted && $v.subject.$error,
                }"
              />
            </div>
            <div class="modal-body__wrap-input">
              <label for="message" class="label-for-input">Текст обращения</label>
              <textarea
                  name="message"
                  v-model="message"
                  placeholder="Введите текст обращения"
                  class="textarea-text"
                  :class="{
                  'error-border-input': isSubmitted && $v.message.$error,
                }"
              ></textarea>
            </div>
            <div class="modal-body__wrap-input-column">
              <label for="file-upload" class="label-for-input">Загрузка файлов <span class="color-gray text-circe-12"  v-if="fileLimit">ТОЛЬКО ИЗОБРАЖЕНИЯ(JPG,PNG), НЕ БОЛЕЕ: 2</span></label>
              <div class="custom-file-input">
                <label for="file-upload" class="custom-file-label">
                  {{ fileNames.length ? fileNames.join(", ") : "Выберите файлы" }}
                </label>
                <input
                    type="file"
                    id="file-upload"
                    name="file"
                    @change="onFileChange"
                    accept="image/*"
                    multiple
                    class="input-file-hidden"
                />

              </div>

              <!-- Отображение миниатюр загруженных изображений -->
              <div v-if="filePreviews.length" class="file-previews">
                <div v-for="(preview, index) in filePreviews" :key="index" class="file-preview">
                  <img :src="preview" alt="preview" class="file-preview-image" />
                </div>
              </div>
            </div>
            <!-- Согласие на обработку персональных данных -->
            <div class="modal-body__wrap-input-column">
              <div class="" v-if=" isSubmitted && $v.consent.$error">
                <p class="error-text">*Нет согласия на обработку персональных данных</p>
              </div>
              <label class="label-for-input">
                <input
                    type="checkbox"
                    v-model="consent"
                    :class="{
                    'error-border-input': isSubmitted && $v.consent.$error,
                  }"
                />
                Я даю свое согласие на обработку персональных данных
              </label>
            </div>
          <div class="">
            <p class="color-gray text-circe-14">С информацией о персональных данных можно ознакомиться по ссылке <router-link to="/appeal/consent" class="color-green text-circe-14" style=" text-decoration: underline;">СОГЛАСИЕ</router-link> </p>
          </div>
            <!-- Поле загрузки файла -->

          </div>

          <div class="modal-footer">
            <div class="modal-footer__btn">
              <button
                  type="button"
                  class="index-btn lite-color"
                  @click="$emit('close')"
              >
                Отменить
              </button>
            </div>
            <div class="modal-footer__btn">
              <button
                  type="button"
                  class="index-btn index-color"
                  @click="onSubmitForm"
              >
                Отправить
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { required, email, sameAs } from "vuelidate/lib/validators";
  import { actionTypesAppeal } from '@/store/modules/modal'
  import { mapState } from 'vuex'
  import AppValidationErrors from '@/components/ValidationErrors.vue'

  export default {
    name: "AppModalAskQuestion",
    components: { AppValidationErrors },
    data() {
      return {
        fullName: "",
        phone: "",
        postalCode: "",
        address: "",
        city: "",
        email: "",
        subject: "",
        message: "",
        consent: false,
        file: null,
        fileNames: [], // Массив имен файлов
        filePreviews: [], // Массив для хранения предварительных изображений
        fileLimit: 2,
        isSubmitted: false,
      };
    },
    validations: {
      fullName: { required },
      phone: { required },
      postalCode: {  required},
      address: { required },
      city: { required },
      email: { required, email },
      subject: { required },
      message: { required },
      consent: { required, sameAsConsent: sameAs(() => true) }
    },
    computed: {
      ...mapState({
        validationErrorsModal: (state) => state.modal.validationErrorsModal,
      }),
    },
    methods: {
      // onFileChange(event) {
      //   const file = event.target.files[0];
      //   if (file) {
      //     this.file = file;
      //     this.fileName = file.name;
      //   }
      // },
      onFileChange(event) {
        const files = Array.from(event.target.files);
        if (files.length > this.fileLimit) {
          alert(`Можно загрузить не более ${this.fileLimit} файлов.`);
          return;
        }

        this.fileNames = files.map(file => file.name); // Получаем имена файлов
        this.filePreviews = [];

        files.forEach((file) => {
          if (file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onload = (e) => {
              this.filePreviews.push(e.target.result); // Добавляем Base64 превью в массив
            };
            reader.readAsDataURL(file);
          }
        });
      },
      onSubmitForm() {
        this.isSubmitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        const cleanedFilePreviews = this.filePreviews.map(preview =>
            preview.replace(/^data:image\/[a-zA-Z]+;base64,/, "")
        );
        const item = {
          fullName: this.fullName,
          phone: this.phone,
          postalCode: this.postalCode,
          address: this.address,
          city:this.city,
          email: this.email,
          subject: this.subject,
          message: this.message,
          consent: this.consent,
          files: cleanedFilePreviews
        }
        this.$store.dispatch(actionTypesAppeal.postAppeal, item)
            .then(() => {
              if(this.validationErrorsModal === null) {
                this.$emit("close");
              }
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });

      },
    },
  };
</script>
