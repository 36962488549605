<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row general-info__align-items">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title"><h1 class="text-TTDrugs-Bold-40 color-green  text-uppercase">Вспомогательные службы</h1></div>
          <div class="general-info__index-img">
            <img src="../../assets/img/departments/auxiliary/auxiliary.jpg" alt="" class="rounded-image" />
<!--            <AppSwiperDepartments :list_image="slides"/>-->
          </div>
        </div>
      </div>
      <div class="general-info-department">
        <div class="general-info-department__info-block">
          <div class="general-info-department__title-left">
            <p class="text-TTDrugs-Bold-40 color-green center text-uppercase">Специалисты</p>
          </div>
          <div class="general-info-department__description">
            <p class="text-circe-20 color-gray"> <span class="bold"> Вспомогательные службы </span> центра представлены отделами,  которые обеспечивают бесперебойную работу всего медицинского и технического оборудования, коммуникаций. Труд каждого сотрудника этих подразделений вносит огромный вклад в развитие и процветание центра на благо наших пациентов.</p>
          </div>
        </div>
        <div class="general-info-department__thank">
          <BlockThankYou/>
        </div>
      </div>
      <AppDoctorsPeopleList :people_list="doctors"/>
    </div>
  </div>

</template>
<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  import BlockThankYou from '@/components/BlockThankYou.vue'
  import { actionTypesDepartment } from '@/store/modules/department'
  import { mapState } from 'vuex'
  import AppDoctorsPeopleList from '@/components/People/DoctorsPeopleList.vue'

  export default {
    name: "AppAuxiliaryServices",
    components: { AppDoctorsPeopleList, BlockThankYou,   DynamicMenu },
    metaInfo() {
      return {
        title: 'Вспомогательные службы | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Отделения Алтайского краевого клинического перинатального центра ДАР.Подготовьтесь к родам вместе с Алтайским краевым клиническим перинатальным центром ДАР.'
          },
          {
            name: 'keywords',
            content: 'роды, сопровождение родов, родильный дом, партнерские роды, послеродовой уход, перинатальный центр ДАР'
          }
        ]
      };
    },
    data() {
      return {
        nameDepartment: 'Вспомогательные службы',
        linksDepartment: 'vspomogatelnye-sluzhby',
        menuItems: [
          { text: "Отделения", link: "/department", active: false },
          { text: "Поликлиника", link: "/department/policlinika", active: false },
          { text: "Акушерский стационар", link: "/department/akusherskij-stacionar", active: false },
          { text: "Неонатологический стационар", link: "/department/neonatologicheskij-stacionar", active: false },
          { text: "Центр гинекологии, восстановительной медицины и реабилитации", link: "/department/centr-ginekologii", active: false },
          { text: "Лабораторные и инструментальные методы обследования", link: "/department/laboratornye-i-instrumentalnye-metody-obsledovaniya", active: false },
          { text: "Отделение катамнестического наблюдения за детьми с перинатальной патологией", link: "/department/kabinet-katamnesticheskogo-nablyudeniya-za-detmi-s-perinatalnoj-patologiej", active: false },
          { text: "Центр медико-социальной поддержки женщин, оказавшихся в трудной жизненной ситуации", link: "/department/support-center", active: false },
          { text: "Вспомогательные службы", link: "/department/vspomogatelnye-sluzhby", active: true },
        ],
        slides: [
          { id: 1, image: require('../../assets/img/departments/auxiliary/auxiliary.jpg') },
        ],
        specialists: [
          { id: 1, name: 'Логиновская Инна Михайловна', mainDepartment: 'Бугалтерия', department: "Бугалтерия", position: 'Главный бухгалтер', image:  require('../../assets/img/auxiliary/loginovskaya.png')},
          { id: 2, name: 'Трубникова Анастасия Владимировна',  mainDepartment: 'Отдел по работе с персоналом',  department: "Отдел по работе с персоналом", position: 'Начальник отдела', image:  require('../../assets/img/auxiliary/trubnikova.jpg') },
          { id: 3, name: 'Беляева Галина Федоровна',  mainDepartment: 'Отдел обеспечения медицинскими ресурсами',  department: "Отдел обеспечения медицинскими ресурсами ", position: 'Начальник отдела - старший провизор', image:  require('../../assets/img/auxiliary/belyaeva.jpg') },
          { id: 4, name: 'Безгинова Екатерина Юрьевна',  mainDepartment: 'Регистратурно-диспетчерский отдел',  department: "Регистратурно-диспетчерский отдел", position: 'Начальник отдела',  image:  require('../../assets/img/auxiliary/bezginova.jpg') },
          { id: 5, name: 'Старикова Олеся Викторовна',  mainDepartment: 'Регистратурно-диспетчерский отдел',  department: "Регистратурно-диспетчерский отдел", position: 'Делопроизводитель',  image:  require('../../assets/img/auxiliary/starikova.jpg') },
          { id: 6, name: 'Железков Сергей Петрович',  mainDepartment: 'Отдел информационных технологий',  department: "Отдел информационных технологий", position: 'Начальник отдела',  image:  require('../../assets/img/auxiliary/zhelezkov.jpg') },
          { id: 7, name: 'Булькотин Михаил Дмитриевич',  mainDepartment: 'Отдел закупок-контрактная служба',  department: "Отдел закупок-контрактная служба", position: 'Начальник отдела',  image:  require('../../assets/img/auxiliary/bulkotin.jpg') },
          { id: 8, name: 'Молибогов Михаил Юрьевич',  mainDepartment: 'Технический отдел',  department: "Технический отдел", position: 'Начальник отдела',  image:  require('../../assets/img/auxiliary/molibogov.jpg') },
          { id: 9, name: 'Шеляков Борис Игоревич',  mainDepartment: 'Хозяйственный отдел',  department: "Хозяйственный отдел", position: 'Начальник отдела',  image:  require('../../assets/img/auxiliary/shelyakov.jpg') },
          { id: 10, name: 'Дунаев Сергей Владимирович',  mainDepartment: 'Отдел по поддержке санитарного благополучия',  department: "Отдел по поддержке санитарного благополучия", position: 'Начальник отдела',  image:  require('../../assets/img/auxiliary/dunaev.jpg') },
          { id: 11, name: 'Белицкий Олег Васильевич',  mainDepartment: 'Специалист гражданской обороны',  department: "Специалист гражданской обороны", position: 'Начальник отдела',  image:  require('../../assets/img/auxiliary/belickij_ov.jpg') },
        ]
      }
    },
    computed: {
      ...mapState({
        selectedDepartmentId: (state) => state.department.selectedDepartmentId,
        doctors: (state) => state.department.doctors
      }),
    },
    methods: {
      getDoctorsDepartment() {
        this.$store.dispatch(actionTypesDepartment.getDoctorsDepartment, this.nameDepartment)
            .then(() => {
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      }
    },
    mounted() {

      this.getDoctorsDepartment();
    }
  };
</script>

