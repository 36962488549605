<template>
  <div class="information-dynamic-block__item image-block-active"  @click="goToArticle" style="cursor: pointer">
    <div class="" >
    <img :src="article_item.image" :alt="article_item.title" class="image-block-active__img">
    </div>
    <div class="image-overlay-active">
      <div class="overlay-content-active">
        <div class="overlay-content-start-active">
          <p class="text-TTDrugs-bold-24 color-white ">{{article_item.title}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "AppArticleDepartmentItem",
    props: {
      article_item: {
        type: Object,
        required: true
      },
      name_department: {
        type: String,
        required: true
      },
    },
    methods: {
      goToArticle() {
        if (this.name_department === 'newborn' || this.name_department === 'pregnancy' ||  this.name_department === 'childbirth' ) {
          this.$router.push({ name: 'ArticleAll', params: { nameDepartment: this.name_department, id: this.article_item.id } });
        } else {
          this.$router.push({ name: 'Article', params: { nameDepartment: this.name_department, id: this.article_item.id } });

        }
      }
    }
  };
</script>

<style scoped>

</style>
