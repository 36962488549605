<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row general-info__align-items">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title">
            <h1 class="text-TTDrugs-Bold-40 color-green left text-uppercase">Госпитализация/Список вещей</h1>
          </div>
          <div class="general-info__block-row">
            <div class="">
              <p>Госпитализация пациентов на стационарное лечение в перинатальный центр «ДАР» осуществляется в плановом и экстренном порядке.</p>
              <p><span class="bold">Планово</span> - пн - пт с 8.00 до 13.00; <span class="bold">Экстренно</span> - круглосуточно</p>
              <p>Госпитализация пациентов производится через приемное отделение. При обращении необходимо иметь с собой:</p>
              <ul class="under-department-content__list">
                <li>Направление от специалиста</li>
                <li>Обменная карта беременной</li>
                <li>Результаты анализов, обследований</li>
                <li>Паспорт</li>
                <li>Полис ОМС</li>
                <li>Страховое свидетельство государственного пенсионного страхования (СНИЛС)</li>
                <li>Свидетельство о браке/о расторжении брака (для получения Свидетельства о рождении ребенка в перинатальном центре «ДАР»)</li>
              </ul>
              <p>При госпитализации родителя для ухода за ребенком в отделение патологии новорожденных и недоношенных детей необходимо предоставить результаты исследований:</p>
              <ul class="under-department-content__list">
                <li>флюорографии (сроком не более года)</li>
                <li>крови на RW (сроком не более года)</li>
                <li>крови на ВИЧ (сроком не более года)</li>
              </ul>
              <p>Врачами выявляются сведения об эпидемическом окружении. Оформляется медицинская карта, история родов. Медперсонал знакомит больного с распорядком центра. Если обратившийся отказывается от госпитализации, специалисты оказывают необходимую медицинскую помощь, фиксируют состояние больного и причину отказа в журнале учета.</p>
              <p>По всем вопросам можете обращаться по телефону: 8 (3852) 591-130</p>
<!--              <div class="general-info__index-mg-mini">-->
<!--                <img src="../../assets/img/departments/laboratory/laboratory3.jpg" alt="tests">-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
      <div class="under-department-content">
        <div class="under-department-content__first-block-info">
          <div class="under-department-content__first-block">
            <h3>Список необходимых вещей на госпитализацию в перинатальный центр «ДАР»</h3>
            <h4>Документы:</h4>
            <ul class="under-department-content__list">
              <li>Паспорт</li>
              <li>Полис ОМС</li>
              <li>Обменная карта беременной</li>
              <li>Страховое свидетельство государственного пенсионного страхования (СНИЛС)</li>
              <li>Свидетельство о браке/о расторжении брака (для получения Свидетельства о рождении ребенка в перинатальном центре «ДАР»)</li>
            </ul>
            <p><span class="bold">ВАЖНО!</span> Фамилия в полисе ОМС и СНИЛСе должны совпадать с паспортными данными. При смене фамилии необходимо обратиться в страховую компанию и пенсионный фонд для актуализации данных!</p>

            <h4>Общий список необходимых вещей для госпитализации независимо от отделения:</h4>
            <ul class="under-department-content__list">
              <li>Предметы личной гигиены</li>
              <li>Полотенце</li>
              <li>Халат и сорочка</li>
              <li>Резиновые сланцы/тапочки</li>
            </ul>
            <p><span class="italic">*Все вещи должны быть упакованы в полиэтиленовую сумку/пакет</span></p>

            <h4>При госпитализации в отделения патологии беременности:</h4>
            <ul class="under-department-content__list">
              <li>Компрессионное белье (чулки/бинты)</li>
            </ul>
            <p><span class="italic">*Все вещи должны быть упакованы в полиэтиленовую сумку/пакет</span></p>

            <h4>При госпитализации в родовое отделение:</h4>
            <ul class="under-department-content__list">
              <li>Компрессионное белье (чулки/бинты)</li>
              <li>Прокладки послеродовые – 2 упаковки</li>
              <li>Бюстгальтер для кормления</li>
              <li>Впитывающие пеленки одноразовые – 10 штук (60*60 см)</li>
              <li>Вода – 1-1,5 л.</li>
              <li>Печенье без наполнителей, орехи</li>
            </ul>
            <p><span class="italic">*Все вещи должны быть упакованы в полиэтиленовую сумку/пакет</span></p>

            <h4>Список необходимых вещей для ребенка:</h4>
            <ul class="under-department-content__list">
              <li>Подгузники для новорожденных</li>
              <li>Влажные салфетки</li>
              <li>Детское мыло (жидкое)</li>
            </ul>
            <p><span class="italic">*Все вещи должны быть упакованы в полиэтиленовую сумку/пакет</span></p>

            <h4>Список необходимых вещей для участника партнерских родов:</h4>
            <ul class="under-department-content__list">
              <li>Чистая сменная одежда и обувь (трико, футболка или халат, медицинские маска и шапочка, резиновые сланцы/тапочки)</li>
              <li>Вода, перекус для себя (в полиэтиленовом пакете)</li>
            </ul>
            <p><span class="italic">* Условия участия в партнерских родах</span></p>

            <h4>Список вещей, которые Вы можете взять дополнительно:</h4>
            <ul class="under-department-content__list">
              <li>Прокладки - вкладыши мягкие для кормящих мам</li>
              <li>Бандаж послеродовой</li>
              <li>Трусы одноразовые (сетка) – 2-3 шт.</li>
              <li>Вещи для новорожденного: распашонки/боди с заправленным рукавом, шапочки, носочки, антицарапки. Одежда для малыша должна быть без бирок, постиранная и проглаженная</li>
              <li>Постельное белье и столовые приборы (по желанию, так как белье и приборы выдаются каждой пациентке)</li>
            </ul>
            <p><span class="italic">*Все вещи должны быть упакованы в полиэтиленовую сумку/пакет</span></p>

            <h4>Список рекомендуемых вещей на выписку:</h4>
            <ul class="under-department-content__list">
              <li>Одежда и обувь для мамы, одежда для новорожденного. Детские и взрослые вещи упаковать в две разных полиэтиленовых сумки/пакета. Сумки/пакеты можно взять с собой в центр сразу или в день выписки их могут передать ваши родные.</li>
              <li>Автомобильное детское кресло 0+ , позаботьтесь о безопасной перевозке малыша домой!</li>
            </ul>
            <p>В здании центра работают буфет для пациентов, аптека, кабинет ЗАГС, банкомат, молитвенная комната.</p>
            <p><span class="bold">Важно!</span> При госпитализации в отделения запрещено брать электроприборы, кроме зарядки для мобильного телефона. Также не рекомендуется брать с собой ценные вещи и украшения.</p>
          </div>
          <!--          <div class="under-department-content__second-block-50">-->
          <!--            <img src="../../assets/img/oms.jpg" alt="oms">-->
          <!--          </div>-->
        </div>
      </div>

    </div>
  </div>

</template>
<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  export default {
    name: "AppHospitalization",
    components: {  DynamicMenu },
    metaInfo() {
      return {
        title: 'Госпитализация | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Узнайте все о госпитализации в Алтайский краевой клинический перинатальный центр ДАР. Мы предоставляем полную информацию о порядке госпитализации, необходимой документации и списке вещей, которые нужно взять с собой. Обратитесь к нам за подробной консультацией и медицинской помощью.'
          },
          {
            name: 'keywords',
            content: 'госпитализация, список вещей, стационарное лечение, перинатальный центр ДАР, документы для госпитализации'
          }
        ]
      };
    },
    data() {
      return {
        nameDepartment: 'Правила записи',
        linksDepartment: 'rules',

        menuItems: [
          { text: "Услуги ОМС", link: "/oms", active: false },
          { text: "Правила записи на прием", link: "/rules", active: false },
          { text: "Анализы", link: "/tests", active: false },
          { text: "Госпитализация", link: "/hospitalization", active: true },
          { text: "Иностранным гражданам", link: "/foreigners", active: false },
          { text: "Справка для налогового вычета", link: "/tax-refund", active: false },
          { text: "График прима граждан", link: "/schedule", active: false },
          { text: "Список продуктов для передачи", link: "/list-product", active: false },
          { text: "Молитвенная комната", link: "/prayer-room", active: false },
          { text: "Медицинский туризм", link: "/medical-tourism", active: false }
        ],

      }
    },

  };
</script>

