<template>
  <div :class="dynamicClass" @click="goToDocumentsFolder">
    <div class="document-item-row__svg" v-if="file_item.docType === 'FOLDER'">
      <img src="../../assets/svg/folder.svg" alt="folder">
    </div>
    <div class="document-item-row__svg" v-if="file_item.docType === 'FILE'">
      <img v-if="fileType === 'pdf'" src="../../assets/svg/pdf-file.svg" alt="pdf icon">
      <img v-else-if="fileType === 'doc'" src="../../assets/svg/doc-file.svg" alt="word icon">
      <img v-else-if="fileType === 'pptx'" src="../../assets/svg/pptx-file.svg" alt="pptx icon">
      <img v-else-if="fileType === 'rtf'" src="../../assets/svg/rtf-file.svg" alt="rtf icon">
      <!-- <span v-else>Loading...</span> -->
    </div>
    <div class="document-item-row__svg" v-if="file_item.docType === 'LINK'">
      <a :href="file_item.link" target="_blank" class="text-circe-bold-18 color-green">{{file_item.link}}</a>
    </div>
    <div :class="dynamicClassText">
      <p class="text-circe-14 color-gray left">{{ file_item.name }}</p>
    </div>
  </div>
</template>

<script>
  import { actionTypesDocuments } from '@/store/modules/documents';

  export default {
    name: "AppItemFiles",

    data() {
      return {
        fileType: null,
      };
    },
    props: {
      file_item: {
        type: Object,
        required: true
      },
      name_folder: {
        type: String,
        required: false
      },
      currentPath: {
        type: String,
        required: true
      }
    },
    computed: {
      dynamicClass() {
        return this.file_item.docType === 'LINK' ? 'document-item' : 'document-item-row';
      },
      dynamicClassText() {
        return this.file_item.docType === 'LINK' ? 'document-item-row__text-down-0' : 'document-item-row__text-down';
      }
    },
    methods: {
      async goToDocumentsFolder() {
        if (this.file_item.docType === 'FOLDER' ) {
          if (this.$route.params.name && this.$route.params.nameFolder) {
            this.$store.dispatch(actionTypesDocuments.safeCurrentFile, this.currentPath);
            this.$router.push({ name: 'NextFolderDetails', params: { nameFolder: this.$route.params.nameFolder,name:this.$route.params.name, nextName: this.file_item.name } });
          } else {
            this.$store.dispatch(actionTypesDocuments.safeCurrentFile, this.currentPath);
            this.$router.push({ name: 'FolderDetails', params: { nameFolder: this.name_folder ,name:this.file_item.name } });
          }


        } else if(this.file_item.docType === 'FILE') {
          console.log('Request download', this.file_item.name);
          try {
            const reportData = await this.$store.dispatch(actionTypesDocuments.downloadFile, { name: this.file_item.name, currentPath: this.currentPath });
            const { url, fileName } = reportData;
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url); // освобождение памяти
          } catch (error) {
            console.log("Ошибка", error);
          }
        }
      },
      determineFileType() {
        this.fileType = this.getFileType(this.file_item.name);
      },
      getFileType(fileName) {
        const extension = fileName.split('.').pop().toLowerCase();
        if (extension === 'pdf') return 'pdf';
        if (['doc', 'docx'].includes(extension)) return 'doc';
        if (['pptx', 'ppt'].includes(extension)) return 'pptx';
        if (extension === 'rtf') return 'rtf';
        return 'unknown';
      }
    },
    created() {
      this.determineFileType();
    }
  };
</script>
