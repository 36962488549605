<template>
 <div class="reviews__item review-block">
   <div class="review-block__title">
     <div class="review-block__img"></div>
     <div class="review-block__info-user">
       <div class="review-block__info-user-name">
         <p class="text-circe-16">{{item_review.name}}</p>
       </div>
       <p class="text-circe-12 color-gray">{{item_review.date}}</p>
     </div>
   </div>
   <div class="review-block__text">
    <p class="text-circe-16-28"> {{ item_review.review | truncate(190) }} </p>
   </div>
   <div class="review-block__btn" @click="goToReview">
     <p class="text-circe-18 color-green">ПОДРОБНЕЕ</p>
     <div class="review-block__btn-img"><img src="../../assets/svg/arrow-right.svg" alt="Подробнее"></div>
   </div>
 </div>
</template>

<script>
  export default {
    name: 'SectionItemReview',
    data() {
      return {

      }
    },
    props: {
      item_review: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    methods: {
      goToReview() {
        this.$router.push('/about/reviews')
      }
    }
  }
</script>

<style scoped>

</style>
