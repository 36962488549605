<template>
  <div class="article-item">
    <div class="article-item__header">
      <p :class="headerClass">{{ under_department_item.title }}</p>
    </div>
    <div class="article-item__content">
      <div class="article-item__image-department">
        <AppImageLoader :src="under_department_item.image" :alt="under_department_item.title"   />
      </div>
      <p class="text-circe-16 left">{{under_department_item.description}}</p>
    </div>
    <div class="article-item__footer">
      <div class="button-text" @click="goToUnderDepartment">
        <span class="text-circe-18 color-green text-uppercase">Узнать подробнее</span>
        <div class="button-text__img"><img src="../../assets/svg/arrow-right.svg" alt="Подробнее"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import AppImageLoader from '@/components/ImageLoader.vue'
  import { transliterate as tr } from 'transliterate'
  export default {
    name: "AppUnderDepartmentItem",
    components: { AppImageLoader },
    props: {
      under_department_item: {
        type: Object,
        required: true
      },
      linksDepartment: {
        type: String,
        required: true
      }
    },
    computed: {
      headerClassWord() {
        const wordCount = this.under_department_item.title.split(' ').length;
        return wordCount > 6 ? 'text-TTDrugs-bold-16 color-white' : 'text-TTDrugs-bold-20 color-white';
      },
      headerClass() {
        const letterCount = this.under_department_item.title.length;
        return letterCount > 65 ? 'text-TTDrugs-bold-16 color-white' : 'text-TTDrugs-bold-20 color-white';
      }
    },
    methods: {
      transliterate(text) {
        return tr(text).replace(/ /g, '-').toLowerCase();
      },
      goToUnderDepartment() {
        this.$router.push({ name: 'UnderDepartment', params: { nameDepartment: this.linksDepartment, name: this.under_department_item.title} });
      }
    }
  };
</script>

<style scoped>

</style>
