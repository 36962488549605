<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row general-info__align-items">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title"><h1 class="text-TTDrugs-Bold-40 color-green  text-uppercase">Лабораторные и инструментальные методы обследования</h1></div>
          <div class="general-info__index-mg">
            <AppSwiperDepartments :list_image="slides"/>
          </div>
        </div>
      </div>
      <div class="general-info-department">
        <div class="general-info-department__info-block">
          <div class="general-info-department__title-left">
            <p class="text-TTDrugs-Bold-40 color-green center text-uppercase">Отделения и кабинеты</p>
          </div>
          <div class="general-info-department__description">
            <p class="text-circe-20 color-gray">
              Перинатальный центр обладает широким спектром диагностических возможностей: лабораторная, ультразвуковая, лучевая (рентгенологические исследования), функциональная диагностика.
            </p>
          </div>
        </div>
        <div class="general-info-department__thank">
          <BlockThankYou/>
        </div>
      </div>
      <div class="general-info-under-department" v-if="underDepartments && underDepartments.length">
        <app-under-department-list :linksDepartment="linksDepartment"/>
        <!--        :nameDepartment="nameDepartment"-->
      </div>
    </div>
  </div>

</template>
<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  import BlockThankYou from '@/components/BlockThankYou.vue'
  import AppUnderDepartmentList from '@/components/UnderDepartment/UnderDepartmentList.vue'
  import AppSwiperDepartments from '@/components/Gallery/SwiperDepartments.vue'
  import { actionTypesDepartment } from '@/store/modules/department'
  import { mapState } from 'vuex'

  export default {
    name: "AppLaboratory",
    components: { AppSwiperDepartments, AppUnderDepartmentList, BlockThankYou,   DynamicMenu },
    metaInfo() {
      return {
        title: 'Лабораторные обследования | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Отделения Алтайского краевого клинического перинатального центра ДАР. Лабораторные обследования. Подготовьтесь к родам вместе с Алтайским краевым клиническим перинатальным центром ДАР.'
          },
          {
            name: 'keywords',
            content: 'лабораторные обследования, роды, сопровождение родов, родильный дом, партнерские роды, послеродовой уход, перинатальный центр ДАР'
          }
        ]
      };
    },
    data() {
      return {
        nameDepartment: 'Лабораторные и инструментальные методы обследования',
        linksDepartment: 'laboratornye-i-instrumentalnye-metody-obsledovaniya',
        menuItems: [
          { text: "Отделения", link: "/department", active: false },
          { text: "Поликлиника", link: "/department/policlinika", active: false },
          { text: "Акушерский стационар", link: "/department/akusherskij-stacionar", active: false },
          { text: "Неонатологический стационар", link: "/department/neonatologicheskij-stacionar", active: false },
          { text: "Центр гинекологии, восстановительной медицины и реабилитации", link: "/department/centr-ginekologii", active: false },
          { text: "Лабораторные и инструментальные методы обследования", link: "/department/laboratornye-i-instrumentalnye-metody-obsledovaniya", active: true },
          { text: "Отделение катамнестического наблюдения за детьми с перинатальной патологией", link: "/department/kabinet-katamnesticheskogo-nablyudeniya-za-detmi-s-perinatalnoj-patologiej", active: false },
          { text: "Центр медико-социальной поддержки женщин, оказавшихся в трудной жизненной ситуации", link: "/department/support-center", active: false },
          { text: "Вспомогательные службы", link: "/department/vspomogatelnye-sluzhby", active: false },
        ],
        slides: [
          { id: 1, image: require('../../assets/img/departments/laboratory/laboratory.jpg') },
          { id: 2, image: require('../../assets/img/departments/laboratory/laboratory1.jpg') },
          { id: 3, image: require('../../assets/img/departments/laboratory/laboratory2.jpg') },
          { id: 4, image: require('../../assets/img/departments/laboratory/laboratory3.jpg') },
          { id: 5, image: require('../../assets/img/departments/laboratory/laboratory4.jpg') },
          { id: 6, image: require('../../assets/img/departments/laboratory/laboratory5.jpg') },
          { id: 7, image: require('../../assets/img/departments/laboratory/laboratory6.jpg') },
        ]
      }
    },
    computed: {
      ...mapState({
        underDepartments: (state) => state.department.underDepartments,
        selectedDepartmentId: (state) => state.department.selectedDepartmentId,
      }),
    },
    methods: {
      fetchUnderDepartmentData() {

          this.$store.dispatch(actionTypesDepartment.getUnderDepartment, this.nameDepartment)
              .then(() => {
              })
              .catch((er) => {
                console.log("Ошибка", er);
              });
        }

    },
    mounted() {

      this.fetchUnderDepartmentData();
    }
  };
</script>

