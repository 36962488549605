<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title">
            <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">Экскурсия по нашему центру</h1>
          </div>
          <div class="general-info__index-mg">
            <AppSwiperCenter :list_image="slides"/>
          </div>
        </div>

      </div>
      <div class="">
        <AppSwiperGalleryInside/>
      </div>
      <div class="">
        <AppSwiperGallery/>
      </div>
    </div>
  </div>

</template>
<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  import AppSwiperGallery from '@/components/Gallery/SwiperGallery.vue'
  import AppSwiperGalleryInside from '@/components/Gallery/SwiperGalleryInside.vue'
  import AppSwiperCenter from '@/components/Gallery/SwiperCenter.vue'

  export default {
    name: "AppDocuments",
    components: { AppSwiperCenter, AppSwiperGalleryInside, AppSwiperGallery,   DynamicMenu },
    metaInfo() {
      return {
        title: 'Галерея | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Галерея. Подробная информация о Алтайском краевом клиническом перинатальном центре ДАР.'
          },
          {
            name: 'keywords',
            content: 'Алтайский краевой клинический перинатальный центр'
          }
        ]
      };
    },
    data() {
      return {
        menuItems: [
          { text: "О центре", link: "/about", active: false },
          { text: "Блог главного врача", link: "/about/blog", active: false },
          { text: "Руководство", link: "/about/management", active: false },
          { text: "Специалисты", link: "/about/specialists", active: false },
          { text: "Общие сведения", link: "/about/general-info", active: false },
          { text: "Документы", link: "/documents", active: false },
          { text: "Режим работы", link: "/about/schedule", active: false },
          { text: "Контакты", link: "/about/contacts", active: false },
          { text: "Вакансии", link: "/about/vacancies", active: false },
          { text: "Новости", link: "/about/news", active: false },
          { text: "Отзывы", link: "/about/reviews", active: false },
          { text: "Галерея", link: "/about/gallery", active: true }
        ],
        slides: [
          { id: 1, image: require('../../assets/img/gallety-center-outside/1.jpg') },
          { id: 2, image: require('../../assets/img/gallety-center-outside/2.jpg') },
          { id: 3, image: require('../../assets/img/gallety-center-outside/3.jpg') },
          { id: 4, image: require('../../assets/img/gallety-center-outside/4.jpg') },
          { id: 5, image: require('../../assets/img/gallety-center-outside/5.jpg') },
          { id: 6, image: require('../../assets/img/gallety-center-outside/6.jpg') },
        ]
      }
    },
    methods: {
    }
  };
</script>
