<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__title-left">
        <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">Платные услуги</h1>
      </div>
      <div class="general-info-department__description">
        <p class="text-circe-20 color-gray ">Наш центр предоставляет ряд платных услуг, направленных на диагностику, лечение и профилактику различных заболеваний. Услуги включают консультации врачей различных специальностей, лабораторные исследования, ультразвуковую диагностику, физиотерапевтические процедуры, хирургические вмешательства и программы вспомогательных репродуктивных технологий. </p>
      </div>
      <div class="under-department-content">
        <div class="under-department-content__first-block">
          <div v-for="(itemDepartment, index) in paid" :key="itemDepartment.title" class="paid-services" :class="{'no-margin-bottom': index === paid.length - 1}">
            <button @click="toggleAccordion(index)" class="accordion color-white text-TTDrugs-regular-24">
              {{itemDepartment.title}}
              <span :class="{'icon-rotate': isAccordionOpen(index)}" class="accordion-icon-wrap">
                <img src="../../assets/svg/down-arrow.svg" alt="Toggle Icon" class="accordion-icon">
              </span>
            </button>
            <div v-if="isAccordionOpen(index)" class="panel">
              <table class="modern-table">
                <thead>
                <tr>
                  <th style="width: 10%;" class="text-circe-bold-18">КОД УСЛУГИ</th>
                  <th style="width: 60%;" class="text-circe-bold-18">НАИМЕНОВАНИЕ УСЛУГИ</th>
                  <th style="width: 15%;" class="text-circe-bold-18">ЕДИНИЦА ИЗМЕРЕНИЯ</th>
                  <th style="width: 15%;" class="text-circe-bold-18">СТОИМОСТЬ, РУБ.</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(service, serviceIndex) in itemDepartment.services" :key="serviceIndex">
                  <td class="text-circe-16">{{ service.code }}</td>
                  <td class="text-circe-16">{{ service.name }}</td>
                  <td class="text-circe-16">{{ service.unit }}</td>
                  <td class="text-circe-16">{{ service.price }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="paid-services no-margin-bottom">
            <button @click="toggleAccordion('info')" class="accordion color-white text-TTDrugs-regular-24">
              Информация о платных услугах
              <span :class="{'icon-rotate': isAccordionOpen('info')}" class="accordion-icon-wrap">
                <img src="../../assets/svg/down-arrow.svg" alt="Toggle Icon" class="accordion-icon">
              </span>
            </button>
            <div v-if="isAccordionOpen('info')" class="panel">
              <div class="panel-info-text">
                <p class="text-circe-18 color-gray"> Всю дополнительную информацию можете найти в разделе документы по ссылке ниже:</p>
              </div>
              <div class="panel-info-text">
                <p class="text-circe-bold-18 color-green link" @click="routePaidServices">ДОКУМЕНТЫ ПЛАТНЫЕ УСЛУГИ</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { actionTypesPaid } from '@/store/modules/paidServices'
  import { mapState } from 'vuex'

  export default {
    name: "AppPaidServices",
    metaInfo() {
      return {
        title: 'Платные услуги | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Ознакомьтесь с полным списком платных медицинских услуг, доступных в Алтайском краевом клиническом перинатальном центре ДАР. Мы предлагаем широкий спектр современных медицинских услуг, включая диагностику, лечение и реабилитацию, на высочайшем уровне.'
          },
          {
            name: 'keywords',
            content: 'платные услуги, медицинские услуги, диагностика, лечение, реабилитация, Алтайский краевой клинический перинатальный центр'
          }
        ]
      };
    },

    data() {
      return {
        activeIndexes: []
      };
    },
    computed: {
      ...mapState({
        paid: (state) => state.paidServices.paid,
        isLoading: (state) => state.paidServices.isLoading,
      }),
    },
    methods: {
      toggleAccordion(index) {
        const idx = this.activeIndexes.indexOf(index);
        if (idx > -1) {
          this.activeIndexes.splice(idx, 1);
        } else {
          this.activeIndexes.push(index);
        }
      },
      routePaidServices() {
        this.$router.push('/documents/paid-services')
      },
      isAccordionOpen(index) {
        return this.activeIndexes.includes(index);
      }
    },
    mounted() {
      this.$store.dispatch(actionTypesPaid.getPaid)
          .then(() => {
            // Дополнительная логика после успешного запроса
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
    }
  };
</script>
