<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row general-info__align-items">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title"><h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">НЕОНАТОЛОГИЧЕСКИЙ СТАЦИОНАР</h1></div>
          <div class="general-info__index-mg">
            <AppSwiperDepartments :list_image="slides"/>
          </div>
        </div>
      </div>
      <div class="general-info-department">
        <div class="general-info-department__info-block">
          <div class="general-info-department__title-left">
            <p class="text-TTDrugs-Bold-40 color-green center text-uppercase">Отделения</p>
          </div>
<!--          <div class="general-info-department__description">-->
<!--            <p class="text-circe-20 color-gray">Акушерский стационар перинатального центра представляет собой завершенный цикл медицинских технологий, начиная с планирования беременности, ее вынашивания, родоразрешения и заканчивая этапом выхаживания новорожденного . Все звенья в этой цепочки нацелены на комплексное и эффективное оказание медицинской помощи каждой пациентке на каждой стадии этого сложного и значимого процесса.</p>-->
<!--          </div>-->
        </div>
        <div class="general-info-department__thank">
          <BlockThankYou/>
        </div>
      </div>
      <div class="general-info-under-department">
        <app-under-department-list :nameDepartment="nameDepartment" :linksDepartment="linksDepartment"/>
      </div>
    </div>
    <div class="general-info-article-under-department">
      <app-article-departments-list :name_department="linksDepartment" />
    </div>
  </div>

</template>
<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  import BlockThankYou from '@/components/BlockThankYou.vue'
  import AppUnderDepartmentList from '@/components/UnderDepartment/UnderDepartmentList.vue'
  import AppSwiperDepartments from '@/components/Gallery/SwiperDepartments.vue'
  import { actionTypesDepartment } from '@/store/modules/department'
  import AppArticleDepartmentsList from '@/components/Article/ArticleDepartmentsList.vue'

  export default {
    name: "AppNeonatology",
    components: { AppArticleDepartmentsList, AppSwiperDepartments, AppUnderDepartmentList, BlockThankYou,   DynamicMenu },
    metaInfo() {
      return {
        title: 'Неонатологический стационар | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Отделения Алтайского краевого клинического перинатального центра ДАР. Неонатологический стационар. Подготовьтесь к родам вместе с Алтайским краевым клиническим перинатальным центром ДАР.'
          },
          {
            name: 'keywords',
            content: 'неонатологический стационар, роды, сопровождение родов, родильный дом, партнерские роды, послеродовой уход, перинатальный центр ДАР'
          }
        ]
      };
    },
    data() {
      return {
        nameDepartment: 'Неонатологический стационар',
        linksDepartment: 'neonatologicheskij-stacionar',
        menuItems: [
          { text: "Отделения", link: "/department", active: false },
          { text: "Поликлиника", link: "/department/policlinika", active: false },
          { text: "Акушерский стационар", link: "/department/akusherskij-stacionar", active: false },
          { text: "Неонатологический стационар", link: "/department/neonatologicheskij-stacionar", active: true },
          { text: "Центр гинекологии, восстановительной медицины и реабилитации", link: "/department/centr-ginekologii", active: false },
          { text: "Лабораторные и инструментальные методы обследования", link: "/department/laboratornye-i-instrumentalnye-metody-obsledovaniya", active: false },
          { text: "Отделение катамнестического наблюдения за детьми с перинатальной патологией", link: "/department/kabinet-katamnesticheskogo-nablyudeniya-za-detmi-s-perinatalnoj-patologiej", active: false },
          { text: "Центр медико-социальной поддержки женщин, оказавшихся в трудной жизненной ситуации", link: "/department/support-center", active: false },
          { text: "Вспомогательные службы", link: "/department/vspomogatelnye-sluzhby", active: false },
        ],
        slides: [
          { id: 1, image: require('../../assets/img/departments/neonatology/neonatology.jpg') },
          { id: 2, image: require('../../assets/img/departments/neonatology/neonatology1.jpg') },
          { id: 3, image: require('../../assets/img/departments/neonatology/neonatology2.jpg') },
          { id: 4, image: require('../../assets/img/departments/neonatology/neonatology3.jpg') },
          { id: 5, image: require('../../assets/img/departments/neonatology/neonatology4.jpg') },
          { id: 6, image: require('../../assets/img/departments/neonatology/neonatology5.jpg') },
          { id: 7, image: require('../../assets/img/departments/neonatology/neonatology6.jpg') },
          { id: 8, image: require('../../assets/img/departments/neonatology/neonatology7.jpg') },
        ]
      }
    },
    methods: {
      fetchUnderDepartmentData() {
        this.$store.dispatch(actionTypesDepartment.getUnderDepartment, this.nameDepartment)
            .then(() => {
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
      fetchArticleDepartment() {
        this.$store.dispatch(actionTypesDepartment.getArticleDepartment, this.nameDepartment)
            .then(() => {
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      }
    },
    mounted() {
      this.fetchUnderDepartmentData();
      this.fetchArticleDepartment();
    }
  };
</script>
<style>

</style>
