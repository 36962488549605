<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row general-info__align-items">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title">
            <h1 class="text-TTDrugs-Bold-40 color-green left text-uppercase">АНАЛИЗ, ПРИЕМ И ВЫДАЧА РЕЗУЛЬТАТОВ</h1>
          </div>
          <div class="general-info__block-row">
            <div class="">
              <h2>Анализы и лабораторные исследования в перинатальном центре «ДАР»</h2>

              <p>В перинатальном центре «ДАР» можно сдать все виды анализов. При центре работают узкоспециализированные лаборатории, которые проводят исследования, не имеющие аналогов в крае.</p>

              <div class="general-info__index-mg-mini">
                <img src="../../assets/img/departments/laboratory/laboratory3.jpg" alt="tests">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="under-department-content">
        <div class="under-department-content__first-block-info">
          <div class="under-department-content__first-block">
            <h3>Патологоанатомическая лаборатория с отделением иммуногистохимии</h3>
            <p>Проводит гистологические и цитологические исследования по профилю акушерства и гинекологии:</p>
            <ul class="under-department-content__list">
              <li>Комплексное исследование эндометрия;</li>
              <li>Комплексное исследование шейки матки с оценкой степени дисплазии и диагностика ВПЧ инфекции;</li>
              <li>Оценка лечебного патоморфоза тканей эндометрия и шейки матки на фоне терапии;</li>
              <li>Диагностика хронического эндометрита;</li>
              <li>Исследование на плазмоциты;</li>
              <li>Патологоанатомическое исследование абортивного материала при неразвивающихся беременностях;</li>
              <li>Исследование на гистосовместимость тканей эндометрия с определением HLA-DR II (α цепь), при планировании беременности;</li>
              <li>Диагностика гиперпластических процессов эндометрия;</li>
              <li>Исследование доброкачественных новообразований матки и придатков;</li>
              <li>Диагностика возбудителей инфекции, приводящих к хроническим эндометритам, сальпингитам, спаечному процессу в полости матки, выкидышам на различных сроках, преждевременным родам;</li>
              <li>Комплексное морфологическое (гистологическое, цитологическое, иммуногистохимическое и гистохимическое) исследование последов (плацент).</li>
            </ul>

            <h3>Цитологические исследования:</h3>
            <ul class="under-department-content__list">
              <li>Шейки матки по системе Бетеста, также с применением окраски по Папаниколау;</li>
              <li>Цервикального канала;</li>
              <li>Аспиратов из полости матки;</li>
              <li>Пунктатов и отпечатков различных органов и тканей;</li>
            </ul>

            <h3>Другие направления исследований:</h3>
            <p>В патологоанатомической лаборатории проводятся гистологические и цитологические исследования различных органов и систем, полученные от пациентов с:</p>
            <ul class="under-department-content__list">
              <li>Осложненными и неосложненными формами неспецифического острого или хронического воспаления;</li>
              <li>Дистрофическими и инфекционными процессами, в том числе сопровождающимися гранулематозным воспалением;</li>
              <li>Пороками развития;</li>
              <li>Болезнями, связанными с нарушением обмена веществ;</li>
              <li>Доброкачественными опухолями;</li>
              <li>Опухолеподобными процессами;</li>
              <li>Заболеваниями глаз.</li>
            </ul>

            <h3>Гистохимические методы исследования:</h3>
            <ul class="under-department-content__list">
              <li>Helicobacter pylori;</li>
              <li>Микобактерии туберкулеза;</li>
              <li>Фибрин с установлением срока давности;</li>
              <li>Соединительную ткань (фибропластические изменения).</li>
            </ul>

            <h3>Биохимические исследования:</h3>
            <ul class="under-department-content__list">
              <li>Исследование уровня альбумина в сыворотке крови;</li>
              <li>Исследование уровня гликированного гемоглобина в крови;</li>
              <li>Исследование уровня глюкозы в цельной крови;</li>
              <li>Исследование уровня лактата в цельной крови;</li>
              <li>Исследование уровня глюкозы в сыворотке крови;</li>
              <li>Проведение глюкозотолерантного теста;</li>
              <li>Исследование уровня железа сыворотки крови;</li>
              <li>Исследование уровня калия в сыворотке крови;</li>
              <li>Исследование уровня креатинина в сыворотке крови;</li>
              <li>Исследование уровня мочевины в сыворотке крови;</li>
              <li>Исследование уровня мочевой кислоты в сыворотке крови;</li>
              <li>Исследование уровня натрия в сыворотке крови;</li>
              <li>Исследование уровня неорганического фосфора в сыворотке крови;</li>
              <li>Исследование уровня общего белка в сыворотке крови;</li>
              <li>Исследование уровня общего билирубина в сыворотке крови;</li>
              <li>Исследование уровня общего кальция в сыворотке крови;</li>
              <li>Исследование уровня общего магния в сыворотке крови;</li>
              <li>Исследование уровня свободного и связанного билирубина в сыворотке крови;</li>
              <li>Исследование уровня триглицеридов в сыворотке крови;</li>
              <li>Исследование уровня хлоридов в сыворотке крови;</li>
              <li>Исследование уровня холестерина в сыворотке крови;</li>
              <li>Исследование уровня ЛПВП в сыворотке крови;</li>
              <li>Исследование уровня ЛПНП в сыворотке крови;</li>
              <li>Исследование газов, электролитов, метаболитов в крови;</li>
              <li>Определение активности аланинаминотрансферазы в крови;</li>
              <li>Определение активности амилазы в крови;</li>
              <li>Определение активности аспартатаминотрансферазы в сыворотке крови;</li>
              <li>Определение активности гамма-глутамилтрансферазы в сыворотке крови;</li>
              <li>Определение активности креатинкиназы в сыворотке крови;</li>
              <li>Определение активности щелочной фосфатазы в сыворотке крови;</li>
              <li>Определение активности лактатдегидрогеназы в сыворотке крови.</li>
            </ul>

            <h4>Подготовка:</h4>
            <p>Кровь из вены сдается утром натощак, после 12-ти часового голодания. Можно пить воду. Накануне и в день сдачи следует исключить физическую нагрузку, курение, прием алкоголя.</p>

            <ul class="under-department-content__list">
              <li>Исследование уровня глюкозы в моче;</li>
              <li>Исследование уровня креатинина в моче;</li>
              <li>Определение активности альфа-амилазы в моче;</li>
              <li>Определение белка в моче.</li>
            </ul>

            <h4>Подготовка:</h4>
            <p>Всю порцию утренней мочи собрать в чистую сухую емкость после тщательного туалета мочеполовых органов. После сбора сразу доставить в лабораторию. Во время менструации сдача анализа не рекомендуется.</p>

            <ul class="under-department-content__list">
              <li>Исследование уровня глюкозы в суточной моче;</li>
              <li>Определение белка в суточной моче;</li>
              <li>Определение альбумина в моче.</li>
            </ul>

            <h4>Подготовка:</h4>
            <p>Моча собирается в течение 24 часов. Утром необходимо освободить мочевой пузырь, а затем в течение дня собирать в стеклянный сосуд. После сбора в контейнер отлить 50 мл мочи и доставить в лабораторию. Нельзя сдавать анализ мочи во время менструации.</p>

            <h3>Коагулологические исследования:</h3>
            <ul class="under-department-content__list">
              <li>Активированное частичное тромбопластиновое время в плазме крови;</li>
              <li>Исследование уровня растворимых фибринмономерных комплексов в плазме крови;</li>
              <li>Исследование уровня фибриногена в плазме крови;</li>
              <li>Определение тромбинового времени в плазме крови;</li>
              <li>Определение протромбинового времени в плазме крови;</li>
              <li>Определение активности антитромбина III в плазме крови;</li>
              <li>Определение уровня Д-димера в плазме крови;</li>
              <li>Исследование времени кровотечения;</li>
              <li>Исследование времени свертывания крови.</li>
            </ul>

            <h4>Подготовка:</h4>
            <p>Кровь из вены сдается утром натощак, 12 часов голодания. Можно пить воду. Накануне и в день сдачи крови необходимо исключить физнагрузку, курение, прием алкоголя.</p>

            <h3>Гематологические исследования:</h3>
            <ul class="under-department-content__list">
              <li>Общий (клинический) анализ крови;</li>
              <li>Исследование скорости оседания эритроцитов (по Вестергрену);</li>
              <li>Подсчет лейкоцитарной формулы с анализом морфологии лейкоцитов;</li>
              <li>Исследование уровня тромбоцитов в крови;</li>
              <li>Исследование уровня ретикулоцитов в крови.</li>
            </ul>

            <h4>Подготовка:</h4>
            <p>Кровь из вены сдают утром натощак, допускается пить воду. Не рекомендуется сдавать кровь после физической нагрузки, применения лекарственных препаратов и физиотерапевтических процедур. За 2 дня до сдачи крови следует исключить курение, прием алкоголя.</p>

            <h3>ПЦР</h3>
            <ul class="under-department-content__list">
              <li>Исследование на COVID-19</li>
            </ul>

            <h3>Клинические исследования:</h3>
            <ul class="under-department-content__list">
              <li>Исследование мочи методом Зимницкого</li>
            </ul>

            <h4>Подготовка:</h4>
            <p>Сбор мочи производится в течение 24 часов. Нужно: подготовить 8 чистых баночек, промаркировать их - 900, 1200, 1500, 1800, 2100, 2400, 300, 600. Провести тщательный гигиенический туалет половых органов и с утра опорожнить мочевой пузырь в унитаз. Далее в течение суток каждые 3 часа опорожнять мочевой пузырь в баночки в соответствии с надписями и записывать объем выпитой жидкости. Утром следующего дня баночки с бланком-направлением нужно отнести в лабораторию, предварительно указав на бланке объем выпитой жидкости.</p>

            <ul class="under-department-content__list">
              <li>Исследование мочи методом Нечипоренко</li>
            </ul>

            <h4>Подготовка:</h4>
            <p>Перед сбором провести тщательный туалет половых органов. При первом утреннем мочеиспускании выпустить в унитаз небольшой объем мочи. Затем, не прерывая мочеиспускания, собрать приблизительно 30-50 мл мочи в специальный контейнер (средняя порция). Остаточную мочу выпустить в унитаз. Контейнер сразу после сбора доставить в лабораторию.</p>

            <ul class="under-department-content__list">
              <li>Скорость клубочковой фильтрации по методу Реберга-Тареева.</li>
            </ul>

            <h4>Подготовка:</h4>
            <p>Проба предполагает проведение в комплексе 2-х анализов - оценки уровня креатинина в суточной моче и в сыворотке крови. Кровь сдается в утреннее время натощак. Мочу необходимо собирать поэтапно. Первое утреннее мочеиспускание (в 6 утра) необходимо произвести в унитаз. Затем провести тщательный туалет мочеполовых органов. Все последующие мочеиспускания выполняются в 2-3 литровую чистую емкость. Мочу необходимо хранить при 4-8°С, иначе ее физические свойства изменятся и результат теста будет не показательным. Последняя порция собирается ровно через 24 ч. после начала сбора мочи (на следующие сутки в 6:00 ч. утра). Содержимое емкости перемешивается, около 50 мл мочи отливается в специальный контейнер с крышкой. Обязательно указать дату сбора биоматериала, суточный диурез (весь объем собранной за сутки мочи), рост и вес. В этот же день контейнер с мочой необходимо отправить в лабораторию.</p>

            <ul class="under-department-content__list">
              <li>Микроскопическое исследование осадка мочи</li>
              <li>Обнаружение кетоновых тел в моче экспресс-методом</li>
              <li>Общий (клинический) анализ мочи (на автоматическом анализаторе методом сухой химии)</li>
            </ul>

            <h4>Подготовка:</h4>
            <p>Собирается вся порция утренней мочи в чистую сухую посуду после тщательного туалета мочеполовых органов. После сбора контейнер доставить в лабораторию. Не рекомендуется сдавать анализ мочи во время менструации.</p>

            <ul class="under-department-content__list">
              <li>Микроскопическое исследование влагалищных мазков</li>
            </ul>

            <h4>Подготовка:</h4>
            <p>Нельзя проводить исследование в течение 24 ч. после полового акта и введения медицинских препаратов. У женщин репродуктивного возраста мазки следует брать не ранее, чем на 5-й день менструального цикла и не позднее, чем за 5 дней до предполагаемого начала менструации. Процедура проводится врачом-гинекологом.</p>

            <ul class="under-department-content__list">
              <li>Экспресс-исследование кала на скрытую кровь иммунохроматографическим методом</li>
            </ul>

            <h4>Подготовка:</h4>
            <p>Кал собирается после самопроизвольной дефекации в специальный одноразовый контейнер. Следует избегать примеси к калу мочи и отделяемого половых органов. Контейнер необходимо доставить в лабораторию в день сбора материала.</p>

            <h3>Иммунологические исследования:</h3>
            <ul class="under-department-content__list">
              <li>Определение основных групп по системе AB0</li>
              <li>Определение антигена D системы Резус (резус-фактор)</li>
              <li>Определение содержания антител к антигенам эритроцитов в сыворотке крови</li>
              <li>Определение фенотипа крови</li>
              <li>Определение пресепсина в сыворотке крови</li>
              <li>Определение прокальцитонина в сыворотке крови</li>
              <li>Исследования плазмы (сыворотки) крови на сифилис реакцией микропреципитации (экспресс-тест)</li>
              <li>Обнаружение антител к Treponema pallidum в сыворотке/плазме крови с помощью метода иммунохроматографического анализа</li>
              <li>Обнаружение антител к вирусу иммунодефицита человека типа 1 и/или типа 2 в сыворотке/плазме или цельной крови с помощью метода иммунохроматографического анализа</li>
              <li>Исследование уровня С-реактивного белка в сыворотке крови</li>
              <li>Исследование уровня хорионического гонадотропина в крови</li>
            </ul>

            <h4>Подготовка:</h4>
            <p>Кровь сдается утром натощак, после 8-12 часового голодания. Допускается питье воды. Накануне и в день сдачи крови следует исключить физическую нагрузку, курение, прием алкоголя.</p>

            <h3>Цитогенетические исследования:</h3>
            <ul class="under-department-content__list">
              <li>Исследование хромосомного аппарата клеток периферической крови (кариотип)</li>
            </ul>

            <h4>Подготовка:</h4>
            <p>Кровь из вены сдается утром после завтрака, не натощак. Накануне и в день сдачи следует исключить физическую нагрузку, прием лекарственных препаратов, курение, прием алкоголя. За неделю до сдачи анализа исключить прием антибиотиков и цитостатических препаратов.</p>

            <ul class="under-department-content__list">
              <li>Исследование хромосомного аппарата клеток ворсин хориона (кариотип)</li>
            </ul>

            <h4>Подготовка:</h4>
            <p>Процедура проводится в условиях операционной. Производится забор ворсин хориона или плаценты. Накануне и в день проведения процедуры следует исключить физическую нагрузку, прием лекарственных препаратов, курение, прием алкоголя. За неделю до процедуры исключить прием антибиотиков и цитостатических препаратов.</p>

            <ul class="under-department-content__list">
              <li>Исследование хромосомного аппарата клеток методом флуоресцентной гибридизации in SITU (FISH)</li>
            </ul>

            <h4>Подготовка:</h4>
            <p>Для анализа используются хромосомные препараты клеток периферической крови или клеток ворсин хориона и плаценты.</p>

          </div>
          <!--          <div class="under-department-content__second-block-50">-->
          <!--            <img src="../../assets/img/oms.jpg" alt="oms">-->
          <!--          </div>-->
        </div>
      </div>

    </div>
  </div>

</template>
<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  export default {
    name: "AppTests",
    components: {  DynamicMenu },
    metaInfo() {
      return {
        title: 'АНАЛИЗ, ПРИЕМ И ВЫДАЧА РЕЗУЛЬТАТОВ | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Подробная информация о Алтайском краевом клиническом перинатальном центре ДАР'
          },
          {
            name: 'keywords',
            content: 'пациентам, алтайский краевой клинический перинатальный центр, перинатальный центр, дар'
          }
        ]
      };
    },
    data() {
      return {
        nameDepartment: 'АНАЛИЗ, ПРИЕМ И ВЫДАЧА РЕЗУЛЬТАТОВ',
        linksDepartment: 'rules',
        menuItems: [
          { text: "Услуги ОМС", link: "/oms", active: false },
          { text: "Правила записи на прием", link: "/rules", active: false },
          { text: "Анализы", link: "/tests", active: true },
          { text: "Госпитализация", link: "/hospitalization", active: false },
          { text: "Иностранным гражданам", link: "/foreigners", active: false },
          { text: "Справка для налогового вычета", link: "/tax-refund", active: false },
          { text: "График прима граждан", link: "/schedule", active: false },
          { text: "Часто задаваемые вопросы", link: "/faq", active: false },
          { text: "Молитвенная комната", link: "/prayer-room", active: false },
          { text: "Медицинский туризм", link: "/medical-tourism", active: false }
        ],

      }
    },

  };
</script>

