<template>
  <div id="app" :class="{'accessibility-mode': isAccessibilityMode}">
    <div class="wrapper">
      <Header @toggleAccessibility="toggleAccessibilityMode" />
      <div class="main">
        <router-view></router-view>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
  import Header from "./components/Header.vue";
  import Footer from "@/components/Footer.vue";

  export default {
    name: "App",
    data() {
      return {
        isAccessibilityMode: false,
        observer: null,
      };
    },
    components: {
      Footer,
      Header,
    },
    methods: {
      toggleAccessibilityMode() {
        if (!this.isAccessibilityMode) {
          this.enableAccessibilityMode();
        } else {
          this.disableAccessibilityMode();
        }
      },
      enableAccessibilityMode() {
        const initializeAccessibility = () => {
          if (typeof window.initAccessibility === "function") {
            window.initAccessibility();
            this.setAccessibilityMode(true);
          } else {
            // Если функция еще не загружена, проверяем снова через 100 мс
            setTimeout(initializeAccessibility, 100);
          }
        };
        initializeAccessibility();
      },
      disableAccessibilityMode() {
        this.setAccessibilityMode(false);
        // Здесь можно добавить логику отключения режима, если это поддерживается библиотекой
      },
      setAccessibilityMode(value) {
        if (this.isAccessibilityMode !== value) {
          if (this.observer) {
            this.observer.disconnect(); // Отключаем наблюдателя при обновлении состояния
          }
          this.isAccessibilityMode = value;
          if (this.observer) {
            this.observeAccessibilityMode(); // Повторное включение наблюдателя
          }
        }
      },
      loadScript(src, callback) {
        const existingScript = document.querySelector(`script[src="${src}"]`);
        if (existingScript) {
          existingScript.remove(); // Удаляем предыдущий скрипт, если он существует
        }
        const script = document.createElement("script");
        script.src = src;
        script.onload = callback;
        document.head.appendChild(script);
      },
      handleAccessibilityChange() {
        const specialElement = document.getElementById("special");
        if (specialElement && !this.isAccessibilityMode) {
          this.setAccessibilityMode(true);
        } else if (!specialElement && this.isAccessibilityMode) {
          this.setAccessibilityMode(false);
        }
      },
      observeAccessibilityMode() {
        this.observer = new MutationObserver(this.handleAccessibilityChange);
        this.observer.observe(document.body, { childList: true, subtree: true });
        this.handleAccessibilityChange(); // Первоначальная проверка
      },
    },
    mounted() {
      document.addEventListener("DOMContentLoaded", () => {
        this.loadScript("https://lidrekon.ru/slep/js/jquery.js", () => {
          this.loadScript("https://lidrekon.ru/slep/js/uhpv-full.min.js", () => {
            this.observeAccessibilityMode(); // Наблюдаем изменения
            document.querySelector("html").style.display = "block"; // Показать содержимое
          });
        });
      });
    },
    beforeDestroy() {
      if (this.observer) {
        this.observer.disconnect();
      }
    },
  };
</script>

<style lang="scss">
/* Стили для режима доступности */
.accessibility-mode .header {
  margin-top: 50px; /* Задайте нужное значение */
}
.accessibility-mode .hide-in-accessibility {
  display: none;
}
.file-preview-image {
  max-width: 100px;
  max-height: 100px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
</style>
