<template>
  <div class="section-swiper">
    <vueper-slides
        class="no-shadow"
        :visible-slides="1"
        slide-multiple
        :gap="1"
        :slide-ratio="1 / 2"
        :dragging-distance="200"
        fixed-height="500px"
        autoplay
        :breakpoints="{ 800: { visibleSlides: 2, slideMultiple: 1 } }">
      <vueper-slide v-for="(slide) in list_image" :key="slide.id"
                    :image="slide.image" class="rounded-image">
        <img :src="slide.image" alt="" class="rounded-image" />
      </vueper-slide>
    </vueper-slides>
  </div>
</template>

<script>
  import { VueperSlides, VueperSlide } from 'vueperslides'
  import 'vueperslides/dist/vueperslides.css'

  export default {
    name: 'AppSwiperCenter',
    components: { VueperSlides, VueperSlide },
    props: {
      list_image: {
        type: Array,
        required: true
      }
    },
    mounted() {
      this.setAutoplay();
    },
    methods: {
      setAutoplay() {
        setInterval(() => {
          const slides = this.$refs.slides;
          if (slides) {
            slides.next();
          }
        }, 2000);
      }
    }
  }
</script>
