import { render, staticRenderFns } from "./SwiperHistory.vue?vue&type=template&id=06959eee&scoped=true"
import script from "./SwiperHistory.vue?vue&type=script&lang=js"
export * from "./SwiperHistory.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06959eee",
  null
  
)

export default component.exports