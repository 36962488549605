import axios from "@/api/axios";

const getPaid = () => {
  return axios.get(`/sections`);
};
const getStatistics = () => {
  return axios.get(`/statistics`);
};
export default {
  getPaid,
  getStatistics
};
