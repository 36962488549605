<template>
<div class="general-info">
  <div class="general-info__container">
    <div class="general-info__row">
      <DynamicMenu :menuItems="menuItems" />
      <div class="general-info__column">
        <div class="general-info__title">
          <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">Общие сведения</h1>
        </div>
        <div class="general-info__information">
          <div class="general-info__information-text-column">
            <div class="general-info__information-text-title">
              <p class="text-TTDrugs-bold-16">Полное наименование учреждения:</p>
            </div>
            <p class="text-circe-16">Краевое государственное бюджетное учреждение здравоохранения «Алтайский краевой клинический перинатальный центр»</p>
          </div>
          <div class="general-info__information-text-column">
            <div class="general-info__information-text-title">
              <p class="text-TTDrugs-bold-16">Сокращенное наименование учреждения:</p>
            </div>
            <p class="text-circe-16">КГБУЗ «АККПЦ»</p>
          </div>
          <div class="general-info__information-text-row">
            <div class="general-info__information-text-title">
              <p class="text-TTDrugs-bold-16">Дата государственной регистрации:</p>
            </div>
            <p class="text-circe-16"> 17.06.2016</p>
          </div>
          <div class="general-info__information-text-row">
            <div class="general-info__information-text-title">
              <p class="text-TTDrugs-bold-16">ОГРН:</p>
            </div>
            <p class="text-circe-16">1162225078113</p>
          </div>
          <div class="general-info__information-text-column">
            <div class="general-info__information-text-title">
              <p class="text-TTDrugs-bold-16">Учредитель:</p>
            </div>
            <p class="text-circe-16">Министерство здравоохранения Алтайского края.Учреждение находится в ведомственном подчинении Министерства здравоохранения Алтайского края и действует в соответствии с законодательством Российской Федерации, законодательством Алтайского края.</p>
          </div>
          <div class="general-info__information-text-column">
            <div class="general-info__information-text-title">
              <p class="text-TTDrugs-bold-16">Министерство здравоохранения Алтайского края:</p>
            </div>
            <p class="text-circe-16">Министр: Попов Дмитрий Владимирович <br/> Приемная: Щербинина Людмила Николаевна <br/> Адрес: 656031, Алтайский край, г. Барнаул, пр. Красноармейский, д. 95а <br/>
              Телефон приемной: 8 (3852) 62-77-66 <br/>  Факс приемной: 8 (3852) 62-80-98 <br/>  E-mail: krayzdrav@zdravalt.ru</p>
          </div>

        </div>
      </div>
      </div>
    <div class="general-info__information-second-block">
      <div class="general-info__information-text-row">
        <div class="general-info__information-text-title">
          <p class="text-TTDrugs-regular-16"> Краевое государственное бюджетное учреждение здравоохранения «Алтайский краевой клинический перинатальный центр» - <span class="text-TTDrugs-bold-16">некоммерческая организация.</span> </p>
        </div>
      </div>
      <div class="general-info__information-text-row">
        <div class="general-info__information-text-title">
          <p class="text-TTDrugs-bold-16">Главный врач:</p>
        </div>
        <p class="text-circe-16">Молчанова Ирина Владимировна</p>
      </div>
      <div class="general-info__information-text-column">
        <div class="general-info__information-text-title">
          <p class="text-TTDrugs-bold-16">Адрес юридический:</p>
        </div>
        <p class="text-circe-16">656045, Алтайский край, г. Барнаул, ул. Фомина, д. 154</p>
      </div>
      <div class="general-info__information-text-column">
        <div class="general-info__information-text-title">
          <p class="text-TTDrugs-bold-16">Адрес фактический:</p>
        </div>
        <p class="text-circe-16">656045, Алтайский край, г. Барнаул, ул. Фомина, д. 154</p>
      </div>
      <div class="general-info__information-text-column">
        <div class="general-info__information-text-title">
          <p class="text-TTDrugs-bold-16">Адрес почтовый:</p>
        </div>
        <p class="text-circe-16">656045, Алтайский край, г. Барнаул, ул. Фомина, д. 154</p>
      </div>
      <div class="general-info__information-text-column">
        <div class="general-info__information-text-title">
          <p class="text-TTDrugs-bold-16">Email:</p>
        </div>
        <p class="text-circe-16">akkpc@zdravalt.ru</p>
      </div>
      <div class="general-info__information-text-column">
        <div class="general-info__information-text-title">
          <p class="text-TTDrugs-bold-16">Справочная:</p>
        </div>
        <p class="text-circe-16">8 (3852) 591-130</p>
      </div>
      <div class="general-info__information-text-column">
        <div class="general-info__information-text-title">
          <p class="text-TTDrugs-bold-16">Сайт:</p>
        </div>
        <p class="text-circe-16">дар22.рф</p>
      </div>
      <div class="general-info__information-text-column">
        <div class="general-info__information-text-title">
          <p class="text-TTDrugs-bold-16">Обособленные структурные подразделения:</p>
        </div>
        <p class="text-circe-16">659323, Алтайский край, г. Бийск, ул. Садовая, д. 210. тел.: 8 (3854) 37-57-02 <br/> 658213 Алтайский край, г. Рубцовск, Ленина проспект, д. 13 тел.: 8 (3855) 79-62-07 <br/> 658823, Алтайский край, г. Славгород, с. Славгородское, ул. Ленина, д. 288<br/>
        </p>
      </div>
    </div>
    <div class="general-info__schedule">

      <AppScheduleTable/>
    </div>
  </div>
</div>

</template>
<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  import AppScheduleTable from '@/components/ScheduleTable.vue'

  export default {
    name: "AppGeneralInfo",
    components: { AppScheduleTable, DynamicMenu },
    metaInfo() {
      return {
        title: 'Общие сведения | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Общие сведения. Подробная информация о Алтайском краевом клиническом перинатальном центре ДАР.'
          },
          {
            name: 'keywords',
            content: 'Алтайский краевой клинический перинатальный центр'
          }
        ]
      };
    },
    data() {
      return {
        menuItems: [
          { text: "О центре", link: "/about", active: false },
          { text: "Блог главного врача", link: "/about/blog", active: false },
          { text: "Руководство", link: "/about/management", active: false },
          { text: "Специалисты", link: "/about/specialists", active: false },
          { text: "Общие сведения", link: "/about/general-info", active: true },
          { text: "Документы", link: "/documents", active: false },
          { text: "Режим работы", link: "/about/schedule", active: false },
          { text: "Контакты", link: "/about/contacts", active: false },
          { text: "Вакансии", link: "/about/vacancies", active: false },
          { text: "Новости", link: "/about/news", active: false },
          { text: "Отзывы", link: "/about/reviews", active: false },
          { text: "Галерея", link: "/about/gallery", active: false }
        ],
      }
    }
  };
</script>
