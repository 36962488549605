import axios from "@/api/axios";

const getUnderDepartment = (id) => {
  return axios.get(`/sub-department/${id}`);
};
const getDepartment =() => {
  return axios.get(`/department`);
}
const getItemUnderDepartment = (title) => {
  return axios.get(`/sub-department/info/${title}`);
}

const getArticleDepartment = (name) => {
  return axios.get(`/articles/${name}`);
}
const getArticleItemDepartment = (id) => {
  return axios.get(`/article-by-id/${id}`);
}
const getDoctorsDepartment = (links) => {
  return axios.get(`employees/department/${links}`);
}
const getDoctorsUnderDepartment = (subDepartmentName) => {
  return axios.get(`/employees/sub-department/${subDepartmentName}`);
}
const getAllDoctors = () => {
  return axios.get(`/employees`);
}
const getSearchFio = (fio) => {
  return axios.get(`/employees/search?searchTerm=${fio}`);
}
export default {
  getUnderDepartment,
  getDepartment,
  getItemUnderDepartment,
  getArticleDepartment,
  getArticleItemDepartment,
  getDoctorsDepartment,
  getDoctorsUnderDepartment,
  getAllDoctors,
  getSearchFio
};
