<template>
  <div class="general-info">
    <div class="general-info__container">
      <AppBreadCrumbs/>
      <div class="general-info__row general-info__align-items">
        <div class="general-info__column">
          <div class="general-info__title">
            <h1 class="text-TTDrugs-Bold-40 color-green left text-uppercase">Школа будущих родителей</h1>
          </div>
          <div class="general-info__block-row">
            <div class="">
              <h2>В перинатальном центре «ДАР» создана «Школа будущих родителей» для семейных пар, которые готовятся стать родителями.</h2>
              <p><span class="bold">Школа дает уверенность и спокойствие</span>, что родители справятся с новыми обязанностями внутри семьи. Программа составлена для помощи молодым родителям, подверженным тревогам и переживаниям, которые связаны с появлением первенца. Парам, которые ждут второго или третьего ребенка, также будет интересен курс в нашей школе: мы предусмотрели блок информации и для опытных родителей.</p>
              <p>После окончания занятий вы будете знать все о процессе родов, о поведении во время родов, сохранении ровного эмоционального состояния, помощи себе при родах, первом прикладывании малыша и налаживании грудного вскармливания. Также мы научим вас уходу за ребенком, включая практические и психологические советы для пап.</p>
              <p>Вы научитесь справляться с трудностями первого месяца, чтобы получать только приятные эмоции в этот важный для вашей семьи период.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="under-department-content">
        <div class="under-department-content__first-block-info">
          <div class="under-department-content__first-block">
            <p>Можете выбрать <span class="bold">удобный для вас формат обучения</span>: групповые и индивидуальные занятия.</p>

            <p><span class="bold">Все занятия ведут опытные квалифицированные специалисты</span>: врач акушер-гинеколог, педиатр, консультант по грудному вскармливанию, психолог.</p>
            <p>Семейные пары в рамках нашей школы <span class="bold">узнают все</span>, что необходимо о течении беременности, родах и послеродовом периоде от врача акушера-гинеколога.</p>
            <p><span class="bold">Наши педиатры</span> расскажут о правильном уходе за новорожденным.</p>
            <p><span class="bold">Консультанты по грудному вскармливанию</span> научат правильному прикладыванию к груди и формированию длительной лактации.</p>
            <p><span class="bold">Психолог</span> поможет парам осознать новые для себя социальные роли и переориентировать интересы семьи на воспитание ребенка без кризиса семейных отношений и без ущерба личным интересам.</p>
            <h3>Обучаем будущих родителей следующим навыкам:</h3>
            <ul class="under-department-content__list">
              <li>правильному дыханию в родах</li>
              <li>приемам немедикаментозного обезболивания и облегчения потуг</li>
              <li>способам эмоциональной саморегуляции, которые улучшат самочувствие во время беременности и родов</li>
              <li>подготовке к грудному вскармливанию</li>
              <li>навыкам ухода за ребенком (купание, обработка пупочной ранки, прогулки и др.)</li>
              <li>обсудите список необходимых покупок для малыша – одежда, гаджеты, аксессуары для кормления и ухода за ребенком</li>
            </ul>
            <p><span class="bold">Профессиональный опыт наших специалистов и индивидуальный подход к супружеской паре помогут каждой семье осознать собственные возможности и ресурсы, и стать компетентными счастливыми родителям.</span></p>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>
<script>

  import AppBreadCrumbs from '@/components/BreadCrumbs.vue'

  export default {
    name: "AppSchoolOfParents",
    components: { AppBreadCrumbs },
    metaInfo() {
      return {
        title: 'Школа будущих родителей | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Узнайте все об услугах образования в Алтайском краевом клиническом перинатальном центре ДАР.'
          },
          {
            name: 'keywords',
            content: 'образование, алтайский краевой клинический перинатальный центр, перинатальный центр, дар'
          }
        ]
      };
    },
    data() {
      return {
        nameDepartment: 'Правила записи',
        linksDepartment: 'rules',
        menuItems: [
          { text: "Документы", link: "/", active: false },
          { text: "Документы", link: "/", active: false },
          { text: "Документы", link: "/", active: false },
          { text: "Школа будущих родителей", link: "/school-of-parents", active: true },
          { text: "Волонтерская деятельность", link: "/volunteer-activities", active: false },

        ],

      }
    },

  };
</script>

