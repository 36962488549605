<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row general-info__align-items">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title">
            <h1 class="text-TTDrugs-Bold-40 color-green left text-uppercase">Иностранным гражданам</h1>
          </div>
          <div class="general-info__block-row">
            <div class="">
              <h2>Медицинские услуги иностранным гражданам</h2>
              <p>В перинатальном центре «ДАР» предоставляются медицинские услуги иностранным гражданам по всем направлениям: акушерство, гинекология, ЭКО.</p>
              <p>С перечнем доступных медицинских услуг можно ознакомиться на странице <a href="URL_to_paid_services_page">Платных услуг</a>.</p>

              <h3>Порядок обслуживания иностранных граждан:</h3>
              <ol class="under-department-content__list">
                <li>Иностранному пациенту необходимо обратиться непосредственно в регистратуру центра;</li>
                <li>Регистратор консультирует и направляет к специалисту по профилю;</li>
                <li>Врач устанавливает: какая медпомощь необходима (скорая, неотложная, плановая), статус иностранного гражданина (временное проживание или вид на жительство, гражданство, наличие страхового полиса) и объем медицинской помощи;</li>
                <li>Согласовываются способы оплаты: личные средства, страховые компании и др. Заключается договор и вносится оплата;</li>
                <li>Медицинские услуги оказываются в соответствии с действующим законодательством;</li>
                <li>Если есть необходимость дополнительного обследования в других медицинских организациях, пациента направляют на дальнейшее лечение и обследование.</li>
              </ol>

              <h3>Контактная информация:</h3>
              <p><strong>Адрес:</strong> г. Барнаул, ул.Фомина, 154.</p>
              <p><strong>Телефон отдела платных услуг:</strong> 8 (3852) 591-130</p>
              <p><strong>Электронная почта:</strong> <a href="mailto:darmedturizm@mail.ru">darmedturizm@mail.ru</a></p>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>
<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  export default {
    name: "AppForeigners",
    components: {  DynamicMenu },
    metaInfo() {
      return {
        title: 'Иностранным гражданам | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Подробная информация о Алтайском краевом клиническом перинатальном центре ДАР'
          },
          {
            name: 'keywords',
            content: 'пациентам, алтайский краевой клинический перинатальный центр, перинатальный центр, дар'
          }
        ]
      };
    },
    data() {
      return {
        nameDepartment: 'Иностранным гражданам',
        linksDepartment: 'foreigners',
        menuItems: [
          { text: "Услуги ОМС", link: "/oms", active: false },
          { text: "Правила записи на прием", link: "/rules", active: false },
          { text: "Анализы", link: "/tests", active: false },
          { text: "Госпитализация", link: "/hospitalization", active: false },
          { text: "Иностранным гражданам", link: "/foreigners", active: true },
          { text: "Справка для налогового вычета", link: "/tax-refund", active: false },
          { text: "График прима граждан", link: "/schedule", active: false },
          { text: "Список продуктов для передачи", link: "/list-product", active: false },
          { text: "Молитвенная комната", link: "/prayer-room", active: false },
          { text: "Медицинский туризм", link: "/medical-tourism", active: false }
        ],

      }
    },

  };
</script>

