<template>
  <div class="article-detail">
    <div class="article-detail__container" >
      <AppBreadCrumbs/>
      <div class="article-item--detail"  v-if="articleItem && !isLoading">
        <div class="article-item__header">
          <p class="text-TTDrugs-bold-24 left color-white">{{articleItem.title}}</p>
        </div>

        <div class="article-item__content">
          <div class="article-item__images" v-if="articleItem.imageUrl !== null">
            <div class="article-item__image--detail"  v-for="(image,index) in articleItem.imageUrl" :key="index">
              <img :src="image" :alt="index" class="under-department-content__image">

            </div>
          </div>
          <p class="text-circe-light-18 left" v-html="content">
          </p>
        </div>
      </div>
      <div class=""  v-else >
        <AppLoader/>
      </div>
    </div>
  </div>
</template>

<script>
  import { actionTypesDepartment } from '@/store/modules/department'
  import { mapState } from 'vuex'
  import AppLoader from '@/components/Loader.vue'

  import AppBreadCrumbs from '@/components/BreadCrumbs.vue'

  export default {
    name: "AppArticleAllPage",
    components: { AppBreadCrumbs, AppLoader },
    props: {

    },
    metaInfo() {
      return {
        title: ' Статьи | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Подробная информация о Алтайском краевом клиническом перинатальном центре ДАР'
          },
          {
            name: 'keywords',
            content: 'пациентам, алтайский краевой клинический перинатальный центр, перинатальный центр, дар'
          }
        ]
      };
    },
    computed: {
      ...mapState({
        articleItem: (state) => state.department.articleItem,
        isLoading: (state) => state.department.isLoading,
      }),
      content() {
        return this.articleItem.content;
      },
    },
    methods: {
      fetchArticleDepartmentItem() {
        this.$store.dispatch(actionTypesDepartment.getArticleItemDepartment, this.$route.params.id)
            .then(() => {
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      }
    },
    mounted() {
      this.fetchArticleDepartmentItem();
    }
    // underItemDepartments
  };
</script>

<style scoped>

</style>
