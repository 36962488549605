import { render, staticRenderFns } from "./Newborn.vue?vue&type=template&id=fca42ea2&scoped=true"
import script from "./Newborn.vue?vue&type=script&lang=js"
export * from "./Newborn.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fca42ea2",
  null
  
)

export default component.exports