<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row general-info__align-items">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title">
            <h1 class="text-TTDrugs-Bold-40 color-green left text-uppercase">Правила записи на прием</h1>
          </div>
          <div class="general-info__block-row">
            <div class="">
              <p>Запись на прием к специалистам консультативно-диагностического отделения Перинатального центра «ДАР» осуществляется по направлению из женских консультаций:</p>
              <ul class="under-department-content__list">
                <li>Через удаленную регистратуру (гинеколог по месту жительства выдает талон для прохождения специалиста).</li>
              </ul>

              <p>Запись на прием к специалистам лучевой и функциональной диагностики (пренатальной диагностики) осуществляется по направлению из женских консультаций:</p>
              <ul class="under-department-content__list">
                <li>Через удаленную регистратуру (гинеколог по месту жительства выдает талон для прохождения обследования).</li>
              </ul>

              <div class="general-info__index-mg-mini">
                <img src="../../assets/img/recordingRules.jpg" alt="recordingRules">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="under-department-content">
        <div class="under-department-content__first-block-info">
          <div class="under-department-content__first-block">
            <p>Самостоятельно без направления врача можно обратиться в регистратуру для получения услуги только на платной основе.</p>

            <h3>Какие документы необходимо иметь при себе:</h3>

            <h4>Для граждан:</h4>
            <ul class="under-department-content__list">
              <li>Паспорт;</li>
              <li>Страховое свидетельство государственного пенсионного страхования (СНИЛС);</li>
              <li>Полис обязательного медицинского страхования (ОМС) или временный полис ОМС;</li>
              <li>Медицинское направление по форме О27/у с указанием специалистов и методов исследования необходимых пациенту;</li>
              <li>Полис добровольного медицинского страхования (при наличии).</li>
            </ul>

            <h4>Для военнослужащих, не имеющих полиса ОМС:</h4>
            <ul class="under-department-content__list">
              <li>Паспорт;</li>
              <li>Удостоверение;</li>
              <li>СНИЛС;</li>
              <li>Направление с места работы, заверенное начальством.</li>
            </ul>

            <h4>Для сотрудников МВД:</h4>
            <ul class="under-department-content__list">
              <li>Паспорт;</li>
              <li>Удостоверение;</li>
              <li>Страховое свидетельство государственного пенсионного страхования (СНИЛС);</li>
              <li>Медицинское направление по форме О27/у из Медсанчасти МВД (г. Барнаул, ул. Анатолия, 66) с указанием финансирования (федеральное или краевое), подписанное начальником медицинской службы с печатью.</li>
            </ul>

            <h4>Документы, необходимые для родов:</h4>
            <ul class="under-department-content__list">
              <li>Паспорт;</li>
              <li>Полис ОМС (+ ксерокопия);</li>
              <li>Обменная карта беременной;</li>
              <li>СНИЛС.</li>
            </ul>
          </div>
<!--          <div class="under-department-content__second-block-50">-->
<!--            <img src="../../assets/img/oms.jpg" alt="oms">-->
<!--          </div>-->
        </div>
      </div>

    </div>
  </div>

</template>
<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  export default {
    name: "AppRecordingRules",
    components: {  DynamicMenu },
    metaInfo() {
      return {
        title: 'Правила записи | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Подробная информация о Алтайском краевом клиническом перинатальном центре ДАР'
          },
          {
            name: 'keywords',
            content: 'пациентам, алтайский краевой клинический перинатальный центр, перинатальный центр, дар'
          }
        ]
      };
    },
    data() {
      return {
        nameDepartment: 'Правила записи',
        linksDepartment: 'rules',
        menuItems: [
          { text: "Услуги ОМС", link: "/oms", active: false },
          { text: "Правила записи на прием", link: "/rules", active: true },
          { text: "Анализы", link: "/tests", active: false },
          { text: "Госпитализация", link: "/hospitalization", active: false },
          { text: "Иностранным гражданам", link: "/foreigners", active: false },
          { text: "Справка для налогового вычета", link: "/tax-refund", active: false },
          { text: "График прима граждан", link: "/schedule", active: false },
          { text: "Список продуктов для передачи", link: "/list-product", active: false },
          { text: "Молитвенная комната", link: "/prayer-room", active: false },
          { text: "Медицинский туризм", link: "/medical-tourism", active: false }
        ],

      }
    },

  };
</script>

