<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row general-info__align-items">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title">
            <h1 class="text-TTDrugs-Bold-40 color-green left text-uppercase">Медицинский туризм</h1>
          </div>
          <div class="general-info__block-row">
            <div class="">
              <p><span class="bold">Перинатальный центр «ДАР»</span> - медицинская организация третьего уровня.</p>
              <p>Наша миссия: в пределах своей профессиональной компетенции, обеспечить каждой семье желаемое количество здоровых детей, сохраняя жизнь и здоровье матери.</p>
              <p>Коллектив центра – это команда высококвалифицированных врачей с большим опытом работы, в нашей команде работает 3 доктора медицинских наук, 12 кандидатов медицинских наук, 59 врачей имеют высшую квалификационную категорию.</p>


              <div class="general-info__index-mg-mini">
                <img src="../../assets/img/poster-index.png" alt="poster">
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="">
        <p>На общей площади 34 тыс.квадратных метров расположено 5900 единиц медицинского оборудования. Такое оборудование позволяет решать самые серьезные проблемы в акушерстве, гинекологии и неонатологии.</p>
        <p>Центр работает в системе обязательного медицинского страхования, наряду с оказанием высокотехнологичной медицинской помощи и оказания помощи в рамках Территориальной программы государственных гарантий, КГБУЗ «Алтайский краевой клинический перинатальный центр» оказывает платные медицинские услуги, в том числе ведется работа по договорам со страховыми компаниями, предприятиями, организациями и учреждениями в рамках Добровольного медицинского страхования.</p>
      </div>
      <section class="statistics-section4" >
        <div class="statistics-section4__container statistics-section4__wrap-index-block">
          <div class="statistics-section4__index-block-white" >
            <div class="statistics-section4__img">
              <img src="../../assets/img/departments/akusherstij-stacioner/akusherstij-stacioner1.jpg" alt="Акушерство">
            </div>
            <div class="statistics-section4__wrap-info-text" >
              <div class="statistics-section4__title">
                <p class="text-TTDrugs-Bold-40 color-green">АКУШЕРСТВО</p>
              </div>
              <div class="statistics-section4__flex">
                <div class="statistics-section4__description">
                  <p class="text-circe-bold-22 color-green">НАБЛЮДЕНИЕ В ТЕЧЕНИЕ ВСЕГО СРОКА БЕРЕМЕННОСТИ</p>
                </div>
                <div class="statistics-section4__text">
                  <ul class="under-department-content__list">
                    <li>Диагностика: УЗИ экспертного класса</li>
                    <li>Консультационный прием ведущих специалистов по вопросам женского здоровья, беременности и родов</li>
                    <li>Прием узких специалистов – генетик, эндокринолог, офтальмолог и др.</li>
                    <li>Единственное в Сибири отделение патологии беременности, специализирующееся на лечении женщин, страдающих сахарным диабетом</li>
                  </ul>

                  <p class="under-department-content__paragraph">Сопровождение родов персональным врачом и акушеркой.</p>

                  <p class="under-department-content__paragraph">Семейная палата послеродового отделения с возможностью круглосуточного пребывания папы (или другого родственника).</p>

                </div>
              </div>
              <div class="statistics-section4__btn" @click="routeGoAkusherskiyStasionar">
                <button class="index-btn">Подробнее</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="statistics-section3">
        <div class="statistics-section3__container">
          <div class="statistics-section3__wrap-index-block-white">
            <div class="statistics-section3__index-block">
              <div class="statistics-section3__title">
                <p class="text-TTDrugs-Bold-40 color-green">Гинекология</p>
              </div>
              <div class="statistics-section3__wrap-info-text">
                <div class="statistics-section3__flex">
                  <div class="statistics-section3__text">
                    <ul class="under-department-content__list">
                      <li>Органосохраняющие операции на матке и придатках с использованием современного эндоскопического оборудования</li>
                      <li>Реконструктивно-пластические операции при пролапсе гениталий</li>
                      <li>Интимная пластика</li>
                      <li>Комплексная программа реабилитации эндометрия у пациенток с бесплодием и привычным невынашиванием беременности</li>
                    </ul>

                    <p class="under-department-content__paragraph">В лечении используются:</p>
                    <ul class="under-department-content__list">
                      <li>современные физиотерапевтические методики</li>
                      <li>гирудотерапия</li>
                      <li>грязелечение</li>
                    </ul>


                  </div>
                  <div class="statistics-section3__btn" @click="routeGoGinecologita">
                    <button class="index-btn">Подробнее</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="statistics-section3__img">
              <img src="../../assets/img/departments/gynecology/gynecology1.jpg" alt="Гинекология">
            </div>
          </div>
        </div>
      </section>
      <section class="statistics-section4" >
        <div class="statistics-section4__container statistics-section4__wrap-index-block">
          <div class="statistics-section4__index-block-white" >
            <div class="statistics-section4__img">
              <img src="../../assets/img/departments/eco/eco2.jpg" alt="Акушерство">
            </div>
            <div class="statistics-section4__wrap-info-text" >
              <div class="statistics-section4__title">
                <p class="text-TTDrugs-Bold-40 color-green">ЦЕНТР РЕПРОДУКЦИИ</p>
              </div>
              <div class="statistics-section4__flex">
                <div class="statistics-section4__text">
                  <p>Высококвалифицированные репродуктологи перинатального центра готовы помочь. Мы стремимся получить максимально возможный результат с сохранением физического и психологического комфорта для пациента. Специалисты подберут индивидуальную схему протокола ЭКО, который обязательно приведет к победе.</p>
                  <ul class="under-department-content__list">
                    <li>Диагностика и лечение всех видов бесплодия</li>
                    <li>ЭКО</li>
                    <li>ПИКСИ</li>
                    <li>ИКСИ</li>
                    <li>Биопсия яичка</li>
                    <li>Иммуногистохимическое исследование эндометрия</li>
                    <li>Внутриматочная инсеминация</li>
                    <li>Вспомогательный хетчинг</li>
                    <li>Криоконсервация половых клеток и эмбрионов</li>
                    <li>Банк донорских ооцитов</li>
                    <li>Программа суррогатного материнства</li>
                  </ul>
                </div>
              </div>
              <div class="statistics-section4__btn" @click="routeGoECO">
                <button class="index-btn">Подробнее</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="under-department-content">
        <div class="under-department-content__first-block-info">
          <div class="under-department-content__first-block">
            <p>Перечень доступных медицинских услуг можно уточнить на странице <a href="/paid-services">Платных услуг</a></p>

            <h3>Порядок обслуживания иностранных граждан:</h3>

            <ul class="under-department-content__list">
              <li>Иностранному пациенту необходимо обратиться непосредственно в регистратуру центра;</li>
              <li>Регистратор консультирует и направляет к специалисту по профилю;</li>
              <li>Врач устанавливает: какая медпомощь необходима (скорая, неотложная, плановая), статус иностранного гражданина (временное проживание или вид на жительство, гражданство, наличие страхового полиса) и объем медицинской помощи.</li>
              <li>Согласовываются способы оплаты: личные средства, страховые компании и др. Заключается договор и вносится оплата.</li>
              <li>Медицинские услуги оказываются в соответствии с действующим законодательством.</li>
              <li>Если есть необходимость дополнительного обследования в других медицинских организациях, пациента направляют на дальнейшее лечение и обследование.</li>
            </ul>

            <h3>Контактная информация:</h3>

            <p>Адрес: г. Барнаул, ул.Фомина, 154.</p>
            <p>Телефон: 8 (3852) 591-130</p>
            <p>Электронная почта: <a href="mailto:akkpc@zdravalt.ru">akkpc@zdravalt.ru</a></p>
          </div>
        </div>
        <div class="under-department-content__icon">
          <div class="under-department-content__icon-mini">
            <router-link to="/medical-tourism-english">
            <img src="../../assets/img/english-language.png" alt="english">
            </router-link>
          </div>
          <div class="under-department-content__icon-mini">
            <a href="https://russiamedtravel.ru/" target="_blank"> <img src="../../assets/img/russiamedtravel.jpg" alt="russiamedtravel"></a>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  export default {
    name: "AppMedicalTourism",
    components: {   DynamicMenu },
    metaInfo() {
      return {
        title: 'Медицинский туризм | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Подробная информация о Алтайском краевом клиническом перинатальном центре ДАР'
          },
          {
            name: 'keywords',
            content: 'пациентам, алтайский краевой клинический перинатальный центр, перинатальный центр, дар'
          }
        ]
      };
    },
    data() {
      return {
        nameDepartment: 'Медицинский туризм',
        linksDepartment: 'medical-tourism"',
        menuItems: [
          { text: "Услуги ОМС", link: "/oms", active: false },
          { text: "Правила записи на прием", link: "/rules", active: false },
          { text: "Анализы", link: "/tests", active: false },
          { text: "Госпитализация", link: "/hospitalization", active: false },
          { text: "Иностранным гражданам", link: "/foreigners", active: false },
          { text: "Справка для налогового вычета", link: "/tax-refund", active: false },
          { text: "График прима граждан", link: "/schedule", active: false },
          { text: "Список продуктов для передачи", link: "/list-product", active: false },
          { text: "Молитвенная комната", link: "/prayer-room", active: false },
          { text: "Медицинский туризм", link: "/medical-tourism", active: true }
        ],

      }
    },
    methods: {
      routeGoAkusherskiyStasionar() {
        this.$router.push('/department/akusherskij-stacionar')
      },
      routeGoGinecologita() {
        this.$router.push('/department/centr-ginekologii')
      },
      routeGoECO() {
        this.$router.push('/family-center')
      }

    }


  };
</script>

