import DepartmentAPi from "@/api/department.js";
const state = {
  isLoading: false, //ожидание запроса
  validationErrors: null, //ошибки
  departments: [],
  underDepartments: [],
  article: [],
  articleItem: [],
  doctors: [],
  underDoctors: [],
  allDoctors: [],
  EMPLOYEES: [],
  MANAGEMENTS: [],
  CHIEF_MEDICAL_OFFICER: [],
  selectedDepartmentId: null,
  underItemDepartments: {},
};

export const mutationTypes = {
  getDepartmentStart: "[department] getDepartmentStart",
  getDepartmentSuccess: "[department] getDepartmentSuccess",
  getDepartmentFailure: "[department] getDepartmentFailure",

  getUnderDepartmentStart: "[department] getUnderDepartmentStart",
  getUnderDepartmentSuccess: "[department] getUnderDepartmentSuccess",
  getUnderDepartmentFailure: "[department] getUnderDepartmentFailure",

  getArticleDepartmentStart: "[department] getArticleDepartmentStart",
  getArticleDepartmentSuccess: "[department] getArticleDepartmentSuccess",
  getArticleDepartmentFailure: "[department] getArticleDepartmentFailure",

  getArticleItemDepartmentStart: "[department] getArticleItemDepartmentStart",
  getArticleItemDepartmentSuccess: "[department] getArticleItemDepartmentSuccess",
  getArticleItemDepartmentFailure: "[department] getArticleItemDepartmentFailure",

  getAllDoctorsStart:"[department] getAllDoctorsStart",
  getAllDoctorsSuccess: "[department] getAllDoctorsSuccess",
  getAllDoctorsFailure: "[department] getAllDoctorsFailure",

  getDoctorsDepartmentStart:"[department] getDoctorsDepartmentStart",
  getDoctorsDepartmentSuccess: "[department] getDoctorsDepartmentSuccess",
  getDoctorsDepartmentFailure: "[department] getDoctorsDepartmentFailure",


  getDoctorsUnderDepartmentStart:"[department] getDoctorsUnderDepartmentStart",
  getDoctorsUnderDepartmentSuccess: "[department] getDoctorsUnderDepartmentSuccess",
  getDoctorsUnderDepartmentFailure: "[department]getDoctorsUnderDepartmentFailure",


  getItemUnderDepartmentStart: "[department] getItemUnderDepartmentStart",
  getItemUnderDepartmentSuccess: "[department] getItemUnderDepartmentSuccess",
  getItemUnderDepartmentFailure: "[department] getItemUnderDepartmentFailure",

  getSearchFioStart: "[department] getSearchFioStart",
  getSearchFioSuccess: "[department] getSearchFioSuccess",
  getSearchFioFailure: "[department]  getSearchFioFailure",

  selectedDepartmentIdSuccess: '[department] selectedDepartmentId'

};
export const actionTypesDepartment = {
  getUnderDepartment: "[department] getInfoCompetitions",
  getDepartment: "[department] getDepartment",
  selectedDepartmentId: " [department] selectedDepartmentId",
  getItemUnderDepartment: "[department] getItemUnderDepartment",
  getArticleDepartment: "[department] getArticleDepartment",
  getArticleItemDepartment: "[department] getArticleItemDepartment",
  getDoctorsDepartment: "[department] getDoctorsDepartment",
  getDoctorsUnderDepartment: "[department] getDoctorsUnderDepartment",
  getAllDoctors: "[department] getAllDoctors",
  getSearchFio: "[department] getSearchFio",
};

const mutations = {
  //поиск по фио  getSearchFio
  [mutationTypes.getSearchFioStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.getSearchFioSuccess](state, data) {
    state.isLoading = false;
    state.EMPLOYEES = data
    state.doctors = data;
  },
  [mutationTypes.getSearchFioFailure](state, payload) {
    state.validationErrors = payload.message;
    state.isLoading = false;
  },
  //Получение отделений
  [mutationTypes.getDepartmentStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getDepartmentSuccess](state, info) {
    state.isLoading = false;
    state.departments = info
  },

  [mutationTypes.getDepartmentFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },

  //Получение статей по отделениям
  [mutationTypes.getArticleDepartmentStart](state) {
    state.isLoading = true;
    state.article = [];
  },
  [mutationTypes.getArticleDepartmentSuccess](state, info) {
    state.isLoading = false;
    state.article = info
  },

  [mutationTypes.getArticleDepartmentFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },


  // Получение статьи по ID
  [mutationTypes.getArticleItemDepartmentStart](state) {
    state.isLoading = true;
    state.articleItem = [];
  },
  [mutationTypes.getArticleItemDepartmentSuccess](state, info) {
    state.isLoading = false;
    state.articleItem = info
  },

  [mutationTypes.getArticleItemDepartmentFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //Получение подотделений по отделению
  [mutationTypes.getUnderDepartmentStart](state) {
    state.isLoading = true;
    state.underDepartments = []
  },
  [mutationTypes.getUnderDepartmentSuccess](state, info) {
    state.isLoading = false;
    state.underDepartments = info
  },
  [mutationTypes.getUnderDepartmentFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  [mutationTypes.selectedDepartmentIdSuccess](state,id) {
    state.selectedDepartmentId = id
  },
  // getItemUnderDepartment
  [mutationTypes.getItemUnderDepartmentStart](state) {
    state.isLoading = true;
    state.underItemDepartments = []
  },
  [mutationTypes.getItemUnderDepartmentSuccess](state, info) {
    state.isLoading = false;
    state.underItemDepartments = info
  },
  [mutationTypes.getItemUnderDepartmentFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // Получение специалистов по отделениям
  [mutationTypes.getDoctorsDepartmentStart](state) {
    state.isLoading = true;
    state.doctors = []
  },
  [mutationTypes.getDoctorsDepartmentSuccess](state, info) {
    state.isLoading = false;
    state.doctors = info
  },
  [mutationTypes.getDoctorsDepartmentFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  //Получение специалистов по Под отделению
  [mutationTypes.getDoctorsUnderDepartmentStart](state) {
    state.isLoading = true;
    state.underDoctors = []
  },
  [mutationTypes.getDoctorsUnderDepartmentSuccess](state, info) {
    state.isLoading = false;
    state.underDoctors = info
  },
  [mutationTypes.getDoctorsUnderDepartmentFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // Получение все спецалистов
  [mutationTypes.getAllDoctorsStart](state) {
    state.isLoading = true;
    state.allDoctors = []
  },
  [mutationTypes.getAllDoctorsSuccess](state, info) {
    state.isLoading = false;
    state.allDoctors = info;
    state.EMPLOYEES = info.EMPLOYEES;
    state.MANAGEMENTS = info.MANAGEMENTS;
    state.CHIEF_MEDICAL_OFFICER = info.CHIEF_MEDICAL_OFFICER;
  },
  [mutationTypes.getAllDoctorsFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
};
const actions = {
  [actionTypesDepartment.getSearchFio](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getSearchFioStart);
      DepartmentAPi
          .getSearchFio(credentials)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.getSearchFioSuccess,
                  response.data.result
              );
              resolve(response.data);
            } else {
              context.commit(mutationTypes.getSearchFioFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.getSearchFioFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  [actionTypesDepartment.getDepartment](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getDepartmentStart);
      DepartmentAPi.getDepartment()
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.getDepartmentSuccess,
                  response.data.result
              );
            } else {
              context.commit(mutationTypes.getDepartmentFailure, response);
            }
            resolve(response.data);
          })
          .catch((response) => {
            context.commit(mutationTypes.getDepartmentFailure, response);
            console.log("ошибка", response);
          });
    });
  },
  [actionTypesDepartment.getArticleDepartment](context,name) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getArticleDepartmentStart);
      DepartmentAPi.getArticleDepartment(name)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.getArticleDepartmentSuccess,
                  response.data.result
              );
            } else {
              context.commit(mutationTypes.getArticleDepartmentFailure, response);
            }
            resolve(response.data);
          })
          .catch((response) => {
            context.commit(mutationTypes.getArticleDepartmentFailure, response);
            console.log("ошибка", response);
          });
    });
  },
  // getArticleItemDepartment
  [actionTypesDepartment.getArticleItemDepartment](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getArticleItemDepartmentStart);
      DepartmentAPi.getArticleItemDepartment(id)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.getArticleItemDepartmentSuccess,
                  response.data.result
              );
            } else {
              context.commit(mutationTypes.getArticleItemDepartmentFailure, response);
            }
            resolve(response.data);
          })
          .catch((response) => {
            context.commit(mutationTypes.getArticleItemDepartmentFailure, response);
            console.log("ошибка", response);
          });
    });
  },
  [actionTypesDepartment.getUnderDepartment](context,id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getUnderDepartmentStart);
      DepartmentAPi.getUnderDepartment(id)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.getUnderDepartmentSuccess,
                  response.data.result
              );
            } else {
              context.commit(mutationTypes.getUnderDepartmentFailure, response);
            }
            resolve(response.data);
          })
          .catch((response) => {
            context.commit(mutationTypes.getUnderDepartmentFailure, response);
            console.log("ошибка", response);
          });
    });
  },
  [actionTypesDepartment.getItemUnderDepartment](context,name) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getItemUnderDepartmentStart);
      DepartmentAPi.getItemUnderDepartment(name)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.getItemUnderDepartmentSuccess,
                  response.data.result
              );
            } else {
              context.commit(mutationTypes.getItemUnderDepartmentFailure, response);
            }
            resolve(response.data);
          })
          .catch((response) => {
            context.commit(mutationTypes.getItemUnderDepartmentFailure, response);
            console.log("ошибка", response);
          });
    });
  },
  // getDoctorsDepartment
  [actionTypesDepartment.getDoctorsDepartment](context,name) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getDoctorsDepartmentStart);
      DepartmentAPi.getDoctorsDepartment(name)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.getDoctorsDepartmentSuccess,
                  response.data.result
              );
            } else {
              context.commit(mutationTypes.getDoctorsDepartmentFailure, response);
            }
            resolve(response.data);
          })
          .catch((response) => {
            context.commit(mutationTypes.getDoctorsDepartmentFailure, response);
            console.log("ошибка", response);
          });
    });
  },
  // getDoctorsUnder
  [actionTypesDepartment.getDoctorsUnderDepartment](context,name) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getDoctorsUnderDepartmentStart);
      DepartmentAPi.getDoctorsUnderDepartment(name)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.getDoctorsUnderDepartmentSuccess,
                  response.data.result
              );
            } else {
              context.commit(mutationTypes.getDoctorsUnderDepartmentFailure, response);
            }
            resolve(response.data);
          })
          .catch((response) => {
            context.commit(mutationTypes.getDoctorsUnderDepartmentFailure, response);
            console.log("ошибка", response);
          });
    });
  },
  // getAllDoctors
  [actionTypesDepartment.getAllDoctors](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getAllDoctorsStart);
      DepartmentAPi.getAllDoctors()
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.getAllDoctorsSuccess,
                  response.data.result
              );
            } else {
              context.commit(mutationTypes.getAllDoctorsFailure, response);
            }
            resolve(response.data);
          })
          .catch((response) => {
            context.commit(mutationTypes.getAllDoctorsFailure, response);
            console.log("ошибка", response);
          });
    });
  },
  [actionTypesDepartment.selectedDepartmentId](context,id) {
    context.commit(mutationTypes.selectedDepartmentIdSuccess, id);
  },


};

export default {
  state,
  mutations,
  actions,
};
