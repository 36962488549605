<template>
  <div class="general-info">
    <div class="general-info__container">
      <AppBreadCrumbs/>
      <div class="general-info__title-left">
        <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">Суррогатное материнство</h1>
      </div>
      <div class="under-department-content">

        <div class="under-department-content__first-block">
          <h2>Суррогатное материнство</h2>

          <p>Существуют медицинские ситуации, когда женщина не может самостоятельно выносить и родить ребенка. В этих случаях возможно проведение ЭКО с привлечением суррогатной матери. Перинатальный центр имеет большой опыт ведения таких программ.</p>

          <h3>К суррогатному материнству прибегают если:</h3>
          <ul class="under-department-content__list">
            <li>отсутствует матка;</li>
            <li>деформирована полость или шейка из-за врожденных патологий или вследствие болезней;</li>
            <li>были неудачные попытки ЭКО (3 и более);</li>
            <li>было 3 и более самопроизвольных выкидыша в анамнезе.</li>
          </ul>

          <p>Суррогатной матерью может быть женщина в возрасте от 25 до 35 лет, которая уже родила одного или нескольких здоровых детей.</p>

          <h3>Основные требования для суррогатных мам:</h3>
          <ul class="under-department-content__list">
            <li>полное физическое и психическое здоровье;</li>
            <li>отсутствие патологий из списка противопоказаний для ЭКО, вынашивания ребенка и родов;</li>
            <li>письменное согласие мужа (при наличии);</li>
            <li>добровольное согласие на медицинские вмешательства.</li>
          </ul>

          <p class="under-department-content__paragraph">В обязательном порядке суррогатная мать проходит полное и тщательное обследование:</p>
          <ul class="under-department-content__list">
            <li>определение группы крови и резус-фактора;</li>
            <li>анализ крови на сифилис, ВИЧ-инфекцию, гепатиты В и С;</li>
            <li>исследование на инфекции: хламидиоз, генитальный герпес, уреаплазмоз, микоплазмоз, цитомегаловирус, краснуха;</li>
            <li>общий анализ мочи;</li>
            <li>клиническое и биохимическое исследования крови;</li>
            <li>флюорография;</li>
            <li>цитологическое исследование шейки матки, цервикального канала, уретры;</li>
            <li>заключение терапевта об отсутствии противопоказаний к вынашиванию беременности;</li>
            <li>ЭКГ;</li>
            <li>УЗИ органов малого таза, щитовидной железы, молочных желез;</li>
            <li>консультация психиатра.</li>
          </ul>

          <h3>Как проходит программа суррогатного материнства поэтапно:</h3>
          <ol class="under-department-content__list">
            <li>Полное медицинское обследование будущих родителей.</li>
            <li>Подбор суррогатной мамы.</li>
            <li>Заключение договора, защищающего интересы всех участников.</li>
            <li>ЭКО: половые клетки пациентки оплодотворяются спермой супруга, культивируются эмбрионы, лучшие из них замораживаются методом витрификации.</li>
            <li>Подготовка организма суррогатной мамы к имплантации и перенос до 2 размороженных эмбрионов.</li>
            <li>Через 10-14 дней после переноса эмбрионов - проведение анализа крови на ХГЧ.</li>
            <li>При наличии беременности, на протяжении всего срока производится наблюдение за здоровьем женщины и плода, проводятся все необходимые диагностические и лечебные процедуры.</li>
          </ol>

          <p>Наша цель - сделать суррогатное материнство понятным и прозрачным для всех участников процесса. Мы за честность и добрые отношения.</p>

          <p>Присоединяйтесь к нашим программам. Подарите долгожданное счастье семьям, мечтающим стать родителями!</p>

          <p class="bold">Чтобы записаться на прием к репродуктологу или составить более точное представление о программе, пожалуйста, свяжитесь с нами. Сделать это можно по номерам телефонов горячей линии: 8 (3852) 591-130, 8 (963) 536-54-08 или оставив заявку на сайте.</p>

        </div>
<!--        <div class="under-department-content__second-block">-->
<!--          <div class="under-department-content__second-block-image" >-->
<!--            <img src="../../assets/img/eco/eco4.jpg" alt="Суррогатное материнство" class="under-department-content__image" />-->
<!--          </div>-->
<!--          &lt;!&ndash;          <div class="under-department-content__second-block-text" v-html="contentTwo">&ndash;&gt;-->
<!--          &lt;!&ndash;          </div>&ndash;&gt;-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
  import AppBreadCrumbs from '@/components/BreadCrumbs.vue'

  export default {
    name: "AppSurrogacy",
    components: { AppBreadCrumbs },
    props: {

    },
    metaInfo() {
      return {
        title: 'Суррогатное материнство | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Узнайте все об услугах экстракорпорального оплодотворения (ЭКО) в Алтайском краевом клиническом перинатальном центре ДАР. Мы предлагаем современные методы лечения бесплодия с использованием передовых технологий. Доверьте ваше здоровье нашим опытным специалистам.'
          },
          {
            name: 'keywords',
            content: 'эко, экстракорпоральное оплодотворение, методы лечения,  Алтайский краевой клинический перинатальный центр'
          }
        ]
      };
    },
    computed: {

    },
    methods: {

    },
    mounted() {
    }

  };
</script>

<style scoped>

</style>
