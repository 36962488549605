<template>
  <div class="grid-item" style="width: 100%">
  <div class="people-block">
    <div class="people-block__img-mini">
      <img :src="people_item.image" :alt="people_item.name">
    </div>
    <div class="people-block__information">
      <div class="people-block__information-header">
        <p class="text-TTDrugs-regular-20 text-uppercase color-gray">{{people_item.name}}
        </p>
      </div>
      <div class="people-block__information-position">
        <p class="color-black text-TTDrugs-semi-bold-18">{{people_item.position}}</p>
      </div>
      <div class="people-block__information-position" v-if="people_item.mainDepartment !== '' && people_item.mainDepartment ">
        <p class="color-black text-TTDrugs-light-18"> {{people_item.mainDepartment}}</p>
<!--        Отделение:-->
      </div>
      <div class="people-block__information-opening-hours" v-if="people_item.opening !== '' && people_item.opening ">
        <p class="text-circe-20"><span class="text-circe-bold-20">Часы приёма:</span> {{people_item.opening}}</p>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
  export default {
    name: "AppPeopleItem",
    props: {
      people_item: {
        type: Object,
        required: true
      }
    },
    data() {
      return {

      };
    }
  };
</script>
