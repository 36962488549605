<template>
  <transition name="slide-fade" mode="out-in">
    <div v-if="showSearch" class="search-bar">
      <input
          type="text"
          @input="performSearch"
          v-model="query"
          placeholder="Введите для поиска..."
          class="search-input text-TTDrugs-bold-18 color-dark"
      />
      <transition name="slide-fade" mode="out-in">
        <ul v-if="results.length" key="results" class="search-results">
          <li v-for="result in results" :key="result.path" class="result-item">
            <router-link :to="result.path" @click.native="closeSearch" class="result-link">
              {{ result.meta.title }}
            <p class="result-description">{{ result.meta.description }}</p>
            </router-link>
          </li>
        </ul>
      </transition>
      <transition name="slide-fade" mode="out-in">
        <div class="search-results" v-if="query && !results.length">
        <p key="no-results" class="no-results color-dark">Результаты не найдены</p>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
  import router from "@/router";

  export default {
    name: "AppSearch",
    props: {
      showSearch: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        query: "",
        results: []
      };
    },
    methods: {
      closeSearch() {
        this.$emit("close-search");
      },
      performSearch() {
        const lowerCaseQuery = this.query.toLowerCase();

        this.results = router.options.routes.filter(route => {
          return (
              route.meta &&
              (
                  (route.meta.title && route.meta.title.toLowerCase().includes(lowerCaseQuery)) ||
                  (route.meta.description && route.meta.description.toLowerCase().includes(lowerCaseQuery))
              )
          );
        });
      }
    }
  };
</script>

<style>

/* Анимация появления и исчезновения */
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}
.slide-fade-enter, .slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>
