var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"appeal"},[_c('div',{staticClass:"appeal__container"},[_vm._m(0),_c('p',{staticClass:"appeal__text"},[_vm._v(" Работа с обращениями граждан в КГБУЗ «Алтайский краевой клинический перинатальный центр» организована в соответствии с Федеральным законом № 59-ФЗ от 02.05.2006 «О порядке рассмотрений обращений граждан Российской Федерации» и законом Алтайского края № 152-ЗС от 29.12.2006 «О рассмотрении обращений граждан Российской Федерации на территории Алтайского края». ")]),_c('div',{staticClass:"appeal__methods"},[_vm._m(1),_c('ul',{staticClass:"under-department-content__list"},[_vm._m(2),_vm._m(3),_c('li',{},[_c('strong',[_vm._v("В ходе личного приема руководством")]),_vm._v(" перинатального центра. График личного приема главным врачом и заместителями главного врача размещен в разделе "),_c('router-link',{attrs:{"to":"/patients"}},[_vm._v("\"Пациентам\" ")]),_vm._v(" вкладка "),_c('router-link',{attrs:{"to":"/schedule"}},[_vm._v(" \"График приема граждан\"")]),_vm._v(". ")],1),_vm._m(4),_vm._m(5)])]),_vm._m(6),_c('div',{staticClass:"appeal__footer"},[_c('button',{staticClass:"index-btn",on:{"click":_vm.openModal}},[_vm._v("Написать обращение")])])]),(_vm.isModalVisible)?_c('AppModalAskQuestion',{on:{"close":_vm.closeModal}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"appeal__text-wrap"},[_c('h2',{staticClass:"text-TTDrugs-bold-24 center"},[_vm._v("Уважаемые пациенты!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"appeal__text-wrap"},[_c('h3',{staticClass:"text-TTDrugs-bold-24"},[_vm._v("Обращения принимаются:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{},[_c('strong',[_vm._v("В устной форме")]),_vm._v(" по телефонам горячей линии: 8 (3852) 591-130 и 8 (963) 536-54-08. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{},[_c('strong',[_vm._v("При личном обращении")]),_vm._v(" в центр. В ежедневном режиме ведется прием граждан специалистом отдела по работе с обращениями граждан в кабинете № 2007 (второй этаж, кабинет в холле слева от лифта) в рабочие дни с 8-00 до 16-00 без предварительной записи. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{},[_c('strong',[_vm._v("В электронной форме через сайт")]),_vm._v(" с помощью кнопки «Написать обращение». ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{},[_c('strong',[_vm._v("В письменном виде")]),_vm._v(" гражданин может подать обращение по почтовому адресу: 656045, Алтайский край, г. Барнаул, ул. Фомина, д. 154 или по электронной почте "),_c('a',{attrs:{"href":"mailto:akkpc@zdravalt.ru"}},[_vm._v("akkpc@zdravalt.ru")]),_vm._v(". ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"appeal__contacts"},[_c('h3',{staticClass:"appeal__contacts-title"},[_vm._v("Контакты ответственных лиц:")]),_c('p',{staticClass:"appeal__contact"},[_vm._v(" Мазурова Наталья Владимировна, начальник отдела по связям с общественностью и работе с обращениями граждан, тел. 8 (3852) 591-130. ")]),_c('p',{staticClass:"appeal__contact"},[_vm._v(" Натарова Валентина Владимировна, специалист отдела по связям с общественностью и работе с обращениями граждан, тел. 8 (3852) 591-130. ")])])
}]

export { render, staticRenderFns }