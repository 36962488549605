<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title">
            <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">Вакансии</h1>
          </div>
          <div class="general-info__block-row">
            <AppVacancyItem v-for="vacancy in firstOneVacancies" :key="vacancy.id" :item_vacancy="vacancy" class="" />
          </div>
        </div>
      </div>
      <div class="">
        <AppVacancyItem v-for="vacancy in remainingVacancies" :key="vacancy.id" :item_vacancy="vacancy" class="vacancy__item" />
      </div>
    </div>
  </div>
</template>

<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  import AppVacancyItem from '@/components/Vacancies/VacancyItem.vue'
  import { actionTypesVacancies } from '@/store/modules/vacancies'
  import { mapState } from 'vuex'

  export default {
    name: "AppVacancies",
    components: { AppVacancyItem, DynamicMenu },
    metaInfo() {
      return {
        title: 'Вакансии | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Вакансии. Подробная информация о Алтайском краевом клиническом перинатальном центре ДАР.'
          },
          {
            name: 'keywords',
            content: 'Алтайский краевой клинический перинатальный центр'
          }
        ]
      };
    },
    data() {
      return {
        menuItems: [
          { text: "О центре", link: "/about", active: false },
          { text: "Блог главного врача", link: "/about/blog", active: false },
          { text: "Руководство", link: "/about/management", active: false },
          { text: "Специалисты", link: "/about/specialists", active: false },
          { text: "Общие сведения", link: "/about/general-info", active: false },
          { text: "Документы", link: "/documents", active: false },
          { text: "Режим работы", link: "/about/schedule", active: false },
          { text: "Контакты", link: "/about/contacts", active: false },
          { text: "Вакансии", link: "/about/vacancies", active: true },
          { text: "Новости", link: "/about/news", active: false },
          { text: "Отзывы", link: "/about/reviews", active: false },
          { text: "Галерея", link: "/about/gallery", active: false }
        ],
      }
    },
    computed: {
      ...mapState({
        isLoading: (state) => state.vacancies.isLoading,
        vacancies: (state) => state.vacancies.vacancies,
      }),
      firstOneVacancies() {
        return this.vacancies.slice(0, 1);
      },
      remainingVacancies() {
        return this.vacancies.slice(1);
      }
    },
    methods: {},
    mounted() {
      this.$store.dispatch(actionTypesVacancies.getVacancies)
          .then(() => {

          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
    }
  };
</script>

<style scoped>

</style>
