var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"general-info"},[_c('div',{staticClass:"general-info__container"},[_c('div',{staticClass:"general-info__row"},[_c('DynamicMenu',{attrs:{"menuItems":_vm.menuItems}}),_c('div',{staticClass:"general-info__column"},[_vm._m(0),_c('div',{staticClass:"general-info__row"},[_c('div',{staticClass:"general-info__information-contact"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"general-info__information-text-column"},[_c('div',{staticClass:"statistics-section3__btn",on:{"click":_vm.goToSchedule}},[_c('button',{staticClass:"index-btn"},[_vm._v("Узнать режим работы отделений")])])])]),_vm._m(5)])])],1),_c('div',{},[_c('section',{staticClass:"section-map"},[_c('div',{staticClass:"div-blur"}),_c('YandexMap',{attrs:{"center":[53.305432, 83.759842],"zoom":18}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"general-info__title"},[_c('h1',{staticClass:"text-TTDrugs-Bold-40 color-green center text-uppercase"},[_vm._v("Контакты")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"general-info__information-text-column"},[_c('div',{staticClass:"general-info__information-text-title"},[_c('p',{staticClass:"text-TTDrugs-bold-16"},[_vm._v("Справочная:")])]),_c('p',{staticClass:"text-circe-16"},[_vm._v("8 (3852) 591-130")]),_c('p',{staticClass:"text-circe-16"},[_vm._v("8 (963) 536-54-08")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"general-info__information-text-column"},[_c('div',{staticClass:"general-info__information-text-title"},[_c('p',{staticClass:"text-TTDrugs-bold-16"},[_vm._v("Email:")])]),_c('p',{staticClass:"text-circe-16"},[_vm._v("akkpc@zdravalt.ru")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"general-info__information-text-column"},[_c('div',{staticClass:"general-info__information-text-title"},[_c('p',{staticClass:"text-TTDrugs-bold-16"},[_vm._v("Адрес фактический:")])]),_c('p',{staticClass:"text-circe-16"},[_vm._v("656045, Алтайский край, г. Барнаул, ул. Фомина, д. 154")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"general-info__information-text-column"},[_c('div',{staticClass:"general-info__information-text-title"},[_c('p',{staticClass:"text-TTDrugs-bold-16"},[_vm._v("Обособленные структурные подразделения:")])]),_c('p',{staticClass:"text-circe-16"},[_vm._v("659323, Алтайский край, г. Бийск, ул. Садовая, д. 210. тел.: 8 (3854) 37-57-02 "),_c('br'),_vm._v(" 658213 Алтайский край, г. Рубцовск, Ленина проспект, д. 13 тел.: 8 (3855) 79-62-07 "),_c('br'),_vm._v(" 658823, Алтайский край, г. Славгород, с. Славгородское, ул. Ленина, д. 288"),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"general-info__img"},[_c('img',{attrs:{"src":require("../../assets/img/dar-image.jpg"),"alt":""}})])
}]

export { render, staticRenderFns }