<template>
  <div class="people__list grid-container">
      <AppPeopleItem v-for="people in managements" :key="people.id" :people_item="people"/>
  </div>
</template>

<script>
  import AppPeopleItem from '@/components/People/PeopleItem.vue'

  export default {
    name: "AppPeopleList",
    components: { AppPeopleItem },
    props: {
      managements: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        // managements: [
        //   {id:1,name: 'Буркова Татьяна Викторовна',position: 'Заместитель главного врача по акушерско-гинекологической помощи',opening: 'среда 14.00-15.00', image:  require('../../assets/img/byrcova.png') },
        //   {id:2,name: 'Колядо Ольга Викторовна',position: 'Заместитель главного врача по контролю качества медицинской помощи',opening: 'пятница 14.00-15.00', image:  require('../../assets/img/kolydo.png') },
        //   {id:3,name: 'Логиновская Инна Михайловна',position: 'Главный бухгалтер',opening: '', image:  require('../../assets/img/loginovskaya.png') },
        //
        // ]
      };
    }
  };
</script>

<style scoped>

</style>
