<template>
  <div class="item-application__media">
    <div class="media-img__title-wrap">
      <p class="text-TTDrugs-bold-24 color-green">Палаты</p>
    </div>
    <div class="media-img media-grid-4">
      <div
          class="media-img__wrap"
          v-for="(img, index) in images"
          :key="index"
      >
        <img
            :src="img.thumb"
            :alt="img.alt"
            class="media-img__img"
            @click="openGallery(index)"
        />
      </div>
    </div>
    <LightBox
        ref="lightbox"
        :media="images"
        :show-light-box="lightboxVisible"
        :site-loading="siteLoading"
        @close="closeLightbox"
    />
  </div>
</template>

<script>
  import LightBox from "vue-image-lightbox";
  import siteLoading from "../../assets/img/siteloading.gif";
  import 'vue-image-lightbox/dist/vue-image-lightbox.min.css';

  export default {
    name: 'AppSwiperGalleryInside',
    components: {
      LightBox,
    },
    data() {
      return {
        siteLoading,
        images: [
          { src: require('../../assets/img/gallery-chambers/1.jpg'), thumb: require('../../assets/img/gallery-chambers/1.jpg'), alt: 'Image 1', caption: 'Описание изображения 1' },
          { src: require('../../assets/img/gallery-chambers/2.jpg'), thumb: require('../../assets/img/gallery-chambers/2.jpg'), alt: 'Image 2', caption: 'Описание изображения 2' },
          { src: require('../../assets/img/gallery-chambers/3.jpg'), thumb: require('../../assets/img/gallery-chambers/3.jpg'), alt: 'Image 3', caption: 'Описание изображения 3' },
          { src: require('../../assets/img/gallery-chambers/4.jpg'), thumb: require('../../assets/img/gallery-chambers/4.jpg'), alt: 'Image 4', caption: 'Описание изображения 4' },

        ],
        lightboxVisible: false,
        currentImage: null
      };
    },
    methods: {
      openGallery(index) {
        this.$refs.lightbox.showImage(index);
        this.lightboxVisible = true;
      },
      closeLightbox() {
        this.lightboxVisible = false;
      }
    }
  };
</script>

<style>

</style>
