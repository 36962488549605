<template>
  <div class="general-info">
    <div class="general-info__container" v-if="underItemDepartments && !isLoading">
      <AppBreadCrumbs/>
      <div class="general-info__title-left">
        <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">{{this.underItemDepartments.title}}</h1>
      </div>
      <div class="under-department-content">
        <div class="under-department-content__first-block" v-html="content">
        </div>
        <div class="under-department-content__second-block">
          <div class="under-department-content__second-block-image" v-if="this.underItemDepartments.image">
            <AppImageLoader :src="this.underItemDepartments.image"  class="under-department-content__image" />
<!--            <img :src="this.underItemDepartments.image" alt="Консультативно-диагностическое отделение" class="under-department-content__image" />-->
          </div>
          <div class="under-department-content__second-block-text" v-html="contentTwo">
          </div>
        </div>
      </div>
      <div class="general-info-department">
        <div class="general-info-department__info-block">
          <div class="general-info-department__title-left">
            <p class="text-TTDrugs-Bold-40 color-green center text-uppercase">Специалисты</p>
          </div>
          <div class="general-info-department__description">
            <p class="text-circe-20 color-gray">Наши специалисты </p>
          </div>
        </div>
        <div class="general-info-department__thank">
          <BlockThankYou/>
        </div>
      </div>
      <AppDoctorsPeopleList :people_list="underDoctors"/>
    </div>
    <div class=""  v-else >
      <AppLoader/>
    </div>
  </div>
</template>

<script>
  import { actionTypesDepartment } from '@/store/modules/department'
  import { mapState } from 'vuex'
  import AppLoader from '@/components/Loader.vue'
  import AppImageLoader from '@/components/ImageLoader.vue'
  import AppDoctorsPeopleList from '@/components/People/DoctorsPeopleList.vue'
  import BlockThankYou from '@/components/BlockThankYou.vue'
  import AppBreadCrumbs from '@/components/BreadCrumbs.vue'

  export default {
    name: "AppUnderDepartmentPage",
    components: { AppBreadCrumbs, BlockThankYou, AppDoctorsPeopleList, AppImageLoader, AppLoader },
    props: {

    },
    metaInfo() {
      return {
        title: 'Подотделения | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Отделения Алтайского краевого клинического перинатального центра ДАР. Подотделения. Подготовьтесь к родам вместе с Алтайским краевым клиническим перинатальным центром ДАР.'
          },
          {
            name: 'keywords',
            content: ' центр поддержки, роды, сопровождение родов, родильный дом, партнерские роды, послеродовой уход, перинатальный центр ДАР'
          }
        ]
      };
    },
    computed: {
      ...mapState({
        underItemDepartments: (state) => state.department.underItemDepartments,
        underDoctors: (state) => state.department.underDoctors,
        isLoading: (state) => state.department.isLoading,
      }),
      content() {
        return this.underItemDepartments.content;
      },
      contentTwo() {
        return this.underItemDepartments.contentTwo;
      },
    },
    methods: {
    fetchUnderDepartmentItem() {
        this.$store.dispatch(actionTypesDepartment.getItemUnderDepartment, this.$route.params.name)
            .then(() => {
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
    },
      getDoctorsUnderDepartment() {
        this.$store.dispatch(actionTypesDepartment.getDoctorsUnderDepartment, this.$route.params.name)
            .then(() => {
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      }
  },
  mounted() {

    this.fetchUnderDepartmentItem();
    this.getDoctorsUnderDepartment();
  }
    // underItemDepartments
  };
</script>

<style scoped>

</style>
