<template>
  <div class="information-dynamic-block__item image-block-active"  @click="goToArticle" style="cursor: pointer">
    <div class="" >
      <img :src="article_item.image" :alt="article_item.title" class="image-block-active__img">
    </div>
    <div class="image-overlay-active">
      <div class="overlay-content-active">
        <div class="overlay-content-start-active">
          <p class="text-TTDrugs-bold-24 color-white ">{{article_item.title}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "AppArticlePatientsItem",
    props: {
      article_item: {
        type: Object,
        required: true
      },
    },
    methods: {
      goToArticle() {
        this.$router.push({ name: this.article_item.title });
      }
    }
  };
</script>

<style scoped>

</style>
