<template>
  <div class="general-info">
    <div class="general-info__container" v-if="folderDocuments && !isLoading">
      <AppBreadCrumbs/>
      <div class="general-info__title-left">
        <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">{{ this.$route.params.name}}</h1>
      </div>
      <div class="general-info__row">
        <div class="article-list" v-if="folderDocuments.files && folderDocuments.files.length">
          <div :class="dynamicClass">
            <AppItemFiles
                v-for="(item, index) in folderDocuments.files"
                :key="index"
                :file_item="item"
                :current-path="folderDocuments.currentPath"
                :name_folder="folderLink"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <AppLoader/>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import AppLoader from '@/components/Loader.vue';
  import AppBreadCrumbs from '@/components/BreadCrumbs.vue';
  import { actionTypesDocuments } from '@/store/modules/documents';
  import AppItemFiles from '@/components/Documents/ItemFiles.vue';

  export default {
    name: "AppItemFolder",
    components: { AppItemFiles, AppBreadCrumbs, AppLoader },
    metaInfo() {
      return {
        title: 'Документы | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Документы Алтайского краевого клинического перинатального центра ДАР. Подготовьтесь к родам вместе с Алтайским краевым клиническим перинатальным центром ДАР.'
          },
          {
            name: 'keywords',
            content: 'документы, перинатальный центр ДАР'
          }
        ]
      };
    },
    computed: {
      ...mapState({
        folderDocuments: (state) => state.documents.folderDocuments,
        currentFile: (state) => state.documents.currentFile,
        isLoading: (state) => state.documents.isLoading,
      }),
      dynamicClass() {
        const linkCount = this.folderDocuments.files.filter(item => item.docType === 'LINK').length;
        return linkCount > 1 ? 'article-list__mini-file' : 'article-list__item-folder';
      },
      folderLink() {
        const name = this.folderDocuments.department.toLowerCase();
        if (name === 'образование') {
          return 'education';
        } else if (name === 'платные услуги') {
          return 'paid-services';
        } else if (name === 'Документы учереждения') {
          return 'institution';
        } else if (name === 'Пациентам') {
          return 'patients';
        } else if (name === 'Противодействие коррупции') {
          return 'anti-corruption';
        } else {
          return 'anti-corruption'; // или другая ссылка по умолчанию
        }
      }
    },
    methods: {
        fetchGetFileInFolder() {
          let name = this.$route.params.nextName || this.$route.params.name;

          // Проверка, если имя уже содержится в currentPath
          if (this.currentFile.includes(name)) {
            name = '';
          }

          this.$store.dispatch(actionTypesDocuments.getNextFolderDocuments, { name, currentPath: this.currentFile })
              .then(() => {
                // Дополнительная логика после успешного запроса
              })
              .catch((er) => {
                console.log("Ошибка", er);
              });
        }

    },
    watch: {
      '$route.params': {
        handler() {
          this.fetchGetFileInFolder();
        },
        immediate: true
      }
    }
  };
</script>

<style scoped>

</style>
