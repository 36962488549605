<template>
  <div>
    <LottieAnimation :animation-data="animationData" :loop="true" :autoplay="true" />
  </div>
</template>

<script>
  import LottieAnimation from 'lottie-web-vue';
  import animationData from '../../assets/lottie/DAR.json';

  export default {
    name: "AppLottieDar",
    components: {
      LottieAnimation
    },
    data() {
      return {
        animationData
      };
    }
  };
</script>
