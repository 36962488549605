<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title">
            <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">Отзывы</h1>
          </div>
          <div class="general-info__block-row">
            <AppReviewsItem v-for="review in firstOneReview" :key="review.id" :item_review="review" class="" />
          </div>
        </div>
      </div>
      <div class="">
        <AppReviewsItem  v-for="review in remainingReviews" :key="review.id" :item_review="review" class="vacancy__item" />
      </div>
    </div>
  </div>

</template>
<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  import AppReviewsItem from '@/components/Reviews/ReviewsItem.vue'

  import { mapState } from 'vuex'
  import { actionTypesReviews } from '@/store/modules/reviews'


  export default {
    name: "AppReviews",
    components: {  AppReviewsItem,   DynamicMenu },
    metaInfo() {
      return {
        title: 'Отзывы | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Отзывы. Подробная информация о Алтайском краевом клиническом перинатальном центре ДАР.'
          },
          {
            name: 'keywords',
            content: 'Алтайский краевой клинический перинатальный центр'
          }
        ]
      };
    },
    data() {
      return {
        menuItems: [
          { text: "О центре", link: "/about", active: false },
          { text: "Блог главного врача", link: "/about/blog", active: false },
          { text: "Руководство", link: "/about/management", active: false },
          { text: "Специалисты", link: "/about/specialists", active: false },
          { text: "Общие сведения", link: "/about/general-info", active: false },
          { text: "Документы", link: "/documents", active: false },
          { text: "Режим работы", link: "/about/schedule", active: false },
          { text: "Контакты", link: "/about/contacts", active: false },
          { text: "Вакансии", link: "/about/vacancies", active: false },
          { text: "Новости", link: "/about/news", active: false },
          { text: "Отзывы", link: "/about/reviews", active: true },
          { text: "Галерея", link: "/about/gallery", active: false }
        ],
      }
    },
    computed: {
      ...mapState({
        isLoading: (state) => state.reviews.isLoading,
        reviews: (state) => state.reviews.reviews,
      }),
      firstOneReview() {
        return this.reviews.slice(0, 1);
      },
      remainingReviews() {
        return this.reviews.slice(1);
      }
    },
    methods: {
    },
    mounted() {
      this.$store.dispatch(actionTypesReviews.getReviews)
          .then(() => {
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
    }
  };
</script>
