import ReviewsAPi from "@/api/reviews.js";
const state = {
  isLoading: false, //ожидание запроса
  validationErrors: null, //ошибки
  reviews: [],
};

export const mutationTypes = {
  getReviewsStart: "[reviews] getReviewsStart",
  getReviewsSuccess: "[reviews] getReviewsSuccess",
  getReviewsFailure: "[reviews] getReviewsFailure",
};
export const actionTypesReviews = {
  getReviews: "[reviews] getReviews",
};

const mutations = {
  //Получение Платных услуг
  [mutationTypes.getReviewsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getReviewsSuccess](state, info) {
    state.isLoading = false;
    state.reviews = info
  },
  [mutationTypes.getReviewsFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
};
const actions = {
  [actionTypesReviews.getReviews](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getReviewsStart);
      ReviewsAPi.getReviews()
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.getReviewsSuccess,
                  response.data.result
              );
            } else {
              context.commit(mutationTypes.getReviewsFailure, response);
            }
            resolve(response.data);
          })
          .catch((response) => {
            context.commit(mutationTypes.getReviewsFailure, response);
            console.log("ошибка", response);
          });
    });
  },
};

export default {
  state,
  mutations,
  actions,
};
