import { render, staticRenderFns } from "./SectionItemReviews.vue?vue&type=template&id=3475087a&scoped=true"
import script from "./SectionItemReviews.vue?vue&type=script&lang=js"
export * from "./SectionItemReviews.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3475087a",
  null
  
)

export default component.exports