import ModalAPi from "@/api/modal.js";
const state = {
  isLoading: false, //ожидание запроса
  validationErrorsModal: null, //ошибки
};

export const mutationTypes = {
  postAppealStart: "[modal]  postAppealStart",
  postAppealSuccess: "[modal]  postAppealSuccess",
  postAppealFailure: "[modal]  postAppealFailure",

};
export const actionTypesAppeal = {
  postAppeal: "[modal]  postAppeal",
};

const mutations = {
  //Получение Списка новостей
  [mutationTypes.postAppealStart](state) {
    state.isLoading = true;
    state.validationErrorsModal = null;
  },
  [mutationTypes.postAppealSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.postAppealFailure](state, payload) {
    state.isLoading = false;
    state.validationErrorsModal = payload;
  },

};
const actions = {
  [actionTypesAppeal.postAppeal](context,item) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.postAppealStart);
      ModalAPi.postAppeal(item)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.postAppealSuccess,
                  response.data.result
              );
            } else {
              context.commit(mutationTypes.postAppealFailure, response);
            }
            resolve(response.data);
          })
          .catch((response) => {
            context.commit(mutationTypes.postAppealFailure, response);
            console.log("ошибка", response);
          });
    });
  },
};

export default {
  state,
  mutations,
  actions,
};
