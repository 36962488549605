var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"modal-mask"},[_c('div',{staticClass:"modal-wrapper"},[_c('div',{staticClass:"modal-container max-width"},[_c('div',{staticClass:"modal-header"},[_c('p',{staticClass:"text-circe-bold-20 color-green"},[_vm._v("Задать вопрос")]),_c('div',{staticClass:"modal-header__cross",on:{"click":function($event){return _vm.$emit('close')}}},[_c('img',{attrs:{"src":require("../../assets/svg/CrossModal.svg"),"alt":"cross"}})])]),_c('div',{staticClass:"error"},[(_vm.validationErrorsModal)?_c('AppValidationErrors',{attrs:{"validation-errors":_vm.validationErrorsModal}}):_vm._e()],1),(_vm.isSubmitted && this.$v.$invalid)?_c('div',{staticClass:"error"},[_c('p',{staticClass:"error-text"},[_vm._v("*Заполните все пустые поля")])]):_vm._e(),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"modal-body__wrap-input"},[_c('label',{staticClass:"label-for-input",attrs:{"for":"fullName"}},[_vm._v("ФИО")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fullName),expression:"fullName"}],staticClass:"input-text",class:{
                'error-border-input': _vm.isSubmitted && _vm.$v.fullName.$error,
              },attrs:{"type":"text","name":"fullName","placeholder":"Введите ФИО"},domProps:{"value":(_vm.fullName)},on:{"input":function($event){if($event.target.composing)return;_vm.fullName=$event.target.value}}})]),_c('div',{staticClass:"modal-body__wrap-input"},[_c('label',{staticClass:"label-for-input",attrs:{"for":"phone"}},[_vm._v("Телефон")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],staticClass:"input-text",class:{
                'error-border-input': _vm.isSubmitted && _vm.$v.phone.$error,
              },attrs:{"type":"text","name":"phone","placeholder":"Введите телефон"},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing)return;_vm.phone=$event.target.value}}})]),_c('div',{staticClass:"modal-body__wrap-input"},[_c('label',{staticClass:"label-for-input",attrs:{"for":"postalCode"}},[_vm._v("Почтовый индекс")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.postalCode),expression:"postalCode"}],staticClass:"input-text",class:{
                'error-border-input': _vm.isSubmitted && _vm.$v.postalCode.$error,
              },attrs:{"type":"text","name":"postalCode","placeholder":"Введите почтовый индекс"},domProps:{"value":(_vm.postalCode)},on:{"input":function($event){if($event.target.composing)return;_vm.postalCode=$event.target.value}}})]),_c('div',{staticClass:"modal-body__wrap-input"},[_c('label',{staticClass:"label-for-input",attrs:{"for":"address"}},[_vm._v("Улица, номер дома, квартиры")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.address),expression:"address"}],staticClass:"input-text",class:{
                'error-border-input': _vm.isSubmitted && _vm.$v.address.$error,
              },attrs:{"type":"text","name":"address","placeholder":"Введите адрес"},domProps:{"value":(_vm.address)},on:{"input":function($event){if($event.target.composing)return;_vm.address=$event.target.value}}})]),_c('div',{staticClass:"modal-body__wrap-input"},[_c('label',{staticClass:"label-for-input",attrs:{"for":"city"}},[_vm._v("Населенный пункт")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.city),expression:"city"}],staticClass:"input-text",class:{
                'error-border-input': _vm.isSubmitted && _vm.$v.city.$error,
              },attrs:{"type":"text","name":"city","placeholder":"Введите населенный пункт"},domProps:{"value":(_vm.city)},on:{"input":function($event){if($event.target.composing)return;_vm.city=$event.target.value}}})]),_c('div',{staticClass:"modal-body__wrap-input"},[_c('label',{staticClass:"label-for-input",attrs:{"for":"email"}},[_vm._v("E-mail")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"input-text",class:{
                'error-border-input': _vm.isSubmitted && _vm.$v.email.$error,
              },attrs:{"type":"email","name":"email","placeholder":"Введите e-mail"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]),_c('div',{staticClass:"modal-body__wrap-input"},[_c('label',{staticClass:"label-for-input",attrs:{"for":"subject"}},[_vm._v("Тема обращения")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.subject),expression:"subject"}],staticClass:"input-text",class:{
                'error-border-input': _vm.isSubmitted && _vm.$v.subject.$error,
              },attrs:{"type":"text","name":"subject","placeholder":"Введите тему обращения"},domProps:{"value":(_vm.subject)},on:{"input":function($event){if($event.target.composing)return;_vm.subject=$event.target.value}}})]),_c('div',{staticClass:"modal-body__wrap-input"},[_c('label',{staticClass:"label-for-input",attrs:{"for":"message"}},[_vm._v("Текст обращения")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],staticClass:"textarea-text",class:{
                'error-border-input': _vm.isSubmitted && _vm.$v.message.$error,
              },attrs:{"name":"message","placeholder":"Введите текст обращения"},domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing)return;_vm.message=$event.target.value}}})]),_c('div',{staticClass:"modal-body__wrap-input-column"},[_c('label',{staticClass:"label-for-input",attrs:{"for":"file-upload"}},[_vm._v("Загрузка файлов "),(_vm.fileLimit)?_c('span',{staticClass:"color-gray text-circe-12"},[_vm._v("ТОЛЬКО ИЗОБРАЖЕНИЯ(JPG,PNG), НЕ БОЛЕЕ: 2")]):_vm._e()]),_c('div',{staticClass:"custom-file-input"},[_c('label',{staticClass:"custom-file-label",attrs:{"for":"file-upload"}},[_vm._v(" "+_vm._s(_vm.fileNames.length ? _vm.fileNames.join(", ") : "Выберите файлы")+" ")]),_c('input',{staticClass:"input-file-hidden",attrs:{"type":"file","id":"file-upload","name":"file","accept":"image/*","multiple":""},on:{"change":_vm.onFileChange}})]),(_vm.filePreviews.length)?_c('div',{staticClass:"file-previews"},_vm._l((_vm.filePreviews),function(preview,index){return _c('div',{key:index,staticClass:"file-preview"},[_c('img',{staticClass:"file-preview-image",attrs:{"src":preview,"alt":"preview"}})])}),0):_vm._e()]),_c('div',{staticClass:"modal-body__wrap-input-column"},[( _vm.isSubmitted && _vm.$v.consent.$error)?_c('div',{},[_c('p',{staticClass:"error-text"},[_vm._v("*Нет согласия на обработку персональных данных")])]):_vm._e(),_c('label',{staticClass:"label-for-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.consent),expression:"consent"}],class:{
                  'error-border-input': _vm.isSubmitted && _vm.$v.consent.$error,
                },attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.consent)?_vm._i(_vm.consent,null)>-1:(_vm.consent)},on:{"change":function($event){var $$a=_vm.consent,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.consent=$$a.concat([$$v]))}else{$$i>-1&&(_vm.consent=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.consent=$$c}}}}),_vm._v(" Я даю свое согласие на обработку персональных данных ")])]),_c('div',{},[_c('p',{staticClass:"color-gray text-circe-14"},[_vm._v("С информацией о персональных данных можно ознакомиться по ссылке "),_c('router-link',{staticClass:"color-green text-circe-14",staticStyle:{"text-decoration":"underline"},attrs:{"to":"/appeal/consent"}},[_vm._v("СОГЛАСИЕ")])],1)])]),_c('div',{staticClass:"modal-footer"},[_c('div',{staticClass:"modal-footer__btn"},[_c('button',{staticClass:"index-btn lite-color",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Отменить ")])]),_c('div',{staticClass:"modal-footer__btn"},[_c('button',{staticClass:"index-btn index-color",attrs:{"type":"button"},on:{"click":_vm.onSubmitForm}},[_vm._v(" Отправить ")])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }