<template>
  <div class="table-container">
    <table class="schedule-table">
      <thead>
      <tr>
        <th class="text-circe-20">№</th>
        <th class="text-circe-20">Отделение</th>
        <th class="text-circe-20">Режим работы</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(department, index) in departments" :key="index">
        <td class="text-circe-18">{{ index + 1 }}</td>
        <td class="text-circe-18">{{ department.name }}</td>
        <td class="text-circe-semi-bold-16">{{ department.schedule }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    name: "AppScheduleTable",
    data() {
      return {
        departments: [
          { name: "Консультативно-диагностическое отделение (поликлиника)", schedule: "с 08-00 до 16-20 (по будням)" },
          { name: "Отделения лучевой и функциональной диагностики", schedule: "с 08-00 до 18-00 (по будням)" },
          { name: "Краевой центр охраны здоровья семьи и репродукции", schedule: "с 08-00 до 16-00 (по будням)" },
          { name: "Дневной стационар", schedule: "с 08-00 до 16-00 (по будням)" },
          { name: "Акушерский стационар", schedule: "Круглосуточно" },
          { name: "Гинекологическое отделение", schedule: "Круглосуточно" },
          { name: "Неонатологический стационар", schedule: "Круглосуточно" },
          { name: "Платные услуги отделения лучевой и функциональной диагностики", schedule: "с 08-00 до 18-00 (по будням)" }
        ]
      }
    }
  };
</script>

<style>

</style>
