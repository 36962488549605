<template>
  <nav aria-label="breadcrumbs">
    <ol class="breadcrumbs">
      <li v-for="(breadcrumb, index) in breadcrumbs" :key="index" class="breadcrumb-item">
        <router-link v-if="breadcrumb.path && !breadcrumb.name.includes('Статья')" :to="breadcrumb.path" class="text-TTDrugs-regular-14 color-gray">
          {{ breadcrumb.name }}<img src="../assets/svg/arrow-mini.svg" alt="Arrow" />
        </router-link>
        <span class="text-TTDrugs-regular-14 color-gray" v-else>{{ breadcrumb.name }}</span>
      </li>
    </ol>
  </nav>
</template>

<script>
  export default {
    name: 'Breadcrumbs',
    computed: {
      breadcrumbs() {
        const route = this.$route;
        const breadcrumbs = [];
        let accumulatedPath = ''; // Инициализируем путь как пустую строку

        // Словарь соответствий английских и русских названий
        const departmentNames = {
          'policlinika': 'Поликлиника',
          'akusherskij-stacionar': 'Акушерский стационар',
          'neonatologicheskij-stacionar': 'Неонатологический стационар',
          'centr-ginekologii': 'Центр гинекологии',
          'laboratornye-i-instrumentalnye-metody-obsledovaniya': 'Лаборатория',
          'support-center': 'Центр поддержки',
          'kabinet-katamnesticheskogo-nablyudeniya-za-detmi-s-perinatalnoj-patologiej': 'Кабинет катамнестического наблюдения',
          'family-center': 'ЭКО',
          'newborn': 'Плод и новорожденный',
          'pregnancy': 'Беременность',
          'childbirth': 'Роды',
          'education': 'Образование',
          'patients': 'Пациентам',
          'paid-services': 'Платные услуги',
          'anti-corruption':'Противодействие коррупции'
          // Добавьте остальные соответствия
        };

        route.matched.forEach((match, index) => {
          if (match.meta && match.meta.breadcrumbs) {
            const parts = match.path.split('/');

            parts.forEach((part, i) => {
              if (i > 0 || index > 0) {
                accumulatedPath += `/${part}`;
              } else {
                accumulatedPath = '/';
              }

              if (match.meta.breadcrumbs[i]) {
                let breadcrumbName = match.meta.breadcrumbs[i];
                let breadcrumbPath = accumulatedPath;

                // Заменяем параметры маршрута в имени и пути
                Object.keys(route.params).forEach(param => {
                  breadcrumbName = breadcrumbName.replace(`:${param}`, route.params[param]);
                  breadcrumbPath = breadcrumbPath.replace(`:${param}`, route.params[param]);
                });

                // Замена английских названий на русские
                if (departmentNames[breadcrumbName]) {
                  breadcrumbName = departmentNames[breadcrumbName];
                }

                breadcrumbs.push({
                  name: breadcrumbName,
                  path: breadcrumbName.includes('Статья') ? null : breadcrumbPath.replace(/\/:.*$/, '') // Удаление динамических параметров
                });
              }
            });
          }
        });

        return breadcrumbs.map(breadcrumb => {
          breadcrumb.path = breadcrumb.path ? breadcrumb.path.replace(/\/+/g, '/') : null; // Удаляем повторяющиеся слэши
          return breadcrumb;
        });
      }
    }
  };
</script>

<style scoped>
.breadcrumbs {
  padding: 10px 0;
  font-size: 14px;
  margin-bottom: 20px;
  color: #666;
  display: flex;
  @media (max-width: 780px) {
   flex-wrap: wrap;
  }
}

.breadcrumbs ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.breadcrumbs li {
  margin-right: 5px;
  @media (max-width: 780px) {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.breadcrumbs li.is-active span {
  color: #000;
}

.breadcrumbs a {
  text-decoration: none;
  color: #666;
  display: flex;
  align-items: center;
}

.breadcrumbs img {
  margin-left: 10px;
}

.breadcrumbs a:hover {
  text-decoration: underline;
}
</style>
