<template>
  <div class="review-block">
    <div class="review-block__title">
      <div class="review-block__img-green"></div>
      <div class="review-block__info-user">
        <div class="review-block__info-user-name">
          <p class="text-circe-18">{{item_review.name}}</p>
        </div>
        <p class="text-circe-14 color-gray">{{item_review.date}}</p>
      </div>
    </div>
    <div class="review-block__text">
      <p class="text-circe-16-28"> {{ item_review.review }} </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AppReviewsItem',
    data() {
      return {

      }
    },
    props: {
      item_review: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    methods: {

    }
  }
</script>



