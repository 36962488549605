<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row general-info__align-items">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title">
            <h1 class="text-TTDrugs-Bold-40 color-green left text-uppercase">СПИСОК ПРОДУКТОВ ДЛЯ ПЕРЕДАЧИ</h1>
          </div>
          <div class="general-info__block-row">
            <div class="">
              <h2>Правила приема передач:</h2>
              <p>Прием передач осуществляется ежедневно с 11.00 до 13.00 и с 16.00 до 18.00 на контрольно-пропускном пункте № 2.</p>
              <p>Содержимое передачи должно соответствовать требованиям Санитарно-эпидемиологических правил и норм: СанПиН 2.1.3678-20 "Санитарно-эпидемиологические требования к эксплуатации помещений, зданий, сооружений, оборудования и транспорта, а также условиям деятельности хозяйствующих субъектов, осуществляющих продажу товаров, выполнение работ или оказание услуг" и СанПиН 2.3.2.1324-03 "Гигиенические требования к срокам годности и условиям хранения пищевых продуктов».</p>
              <p>Передача должна быть упакована в прозрачный полиэтиленовый пакет с указанием ФИО пациента, наименования структурного подразделения, номера палаты, контактного номера телефона пациентки, списка передаваемых вещей.</p>
              <div class="general-info__index-mg-mini">
                <img src="../../assets/img/kpp.jpg" alt="recordingRules">
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="under-department-content">
        <div class="under-department-content__first-block-info">
          <div class="under-department-content__first-block">
            <h2>Перечень разрешенных продуктов для передачи пациентам в отделения:</h2>
            <ul class="under-department-content__list">
              <li>мясо, язык, печень отварная (не более 12 часов с момента приготовления);</li>
              <li>птица (тушками) отварная (200 г);</li>
              <li>колбасы вареные, мясные хлебы, колбасы из мяса птиц: высшего, первого, второго сортов (300 г);</li>
              <li>масло сливочное в промышленной упаковке (не более 200 г);</li>
              <li>молочные и кисломолочные продукты, фасованные в заводской упаковке – молоко (до 1 л), сливки (не более 500 мл), творог крестьянский 5 % (250 г), сыры твердых сортов (200 г), кефир/ряженка/йогурт (0,5 л);</li>
              <li>овощи отварные очищенные: морковь, свекла, картофель (не более 500 г каждого наименования);</li>
              <li>фрукты мытые: яблоки, бананы, груши, апельсины (не более 500 г каждого наименования);</li>
              <li>печенье крекер (1-2 пачки);</li>
              <li>булочки с повидлом или без начинки в вакуумной упаковке (3-4 шт);</li>
              <li>яйцо отварное (1 шт);</li>
              <li>безалкогольные газированные напитки, минеральная вода, соки в заводской упаковке (0,5 – 1 л).</li>
            </ul>

            <h2>Перечень запрещенных продуктов для передачи пациентам в отделения:</h2>
            <ul class="under-department-content__list">
              <li>паштет, студень, заливное (мясные или рыбные);</li>
              <li>пельмени;</li>
              <li>блины;</li>
              <li>беляши с мясом;</li>
              <li>заправленные салаты (овощные, мясные, рыбные);</li>
              <li>кондитерские изделия с заварным кремом или кремом из сливок;</li>
              <li>простокваша (самоквас);</li>
              <li>сырые яйца.</li>
            </ul>

          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  export default {
    name: "AppListProduct",
    components: {   DynamicMenu },
    metaInfo() {
      return {
        title: 'Список продуктов для передачи | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Подробная информация о Алтайском краевом клиническом перинатальном центре ДАР'
          },
          {
            name: 'keywords',
            content: 'пациентам, алтайский краевой клинический перинатальный центр, перинатальный центр, дар'
          }
        ]
      };
    },
    data() {
      return {
        nameDepartment: 'Список продуктов для передачи',
        linksDepartment: 'list-product',
        menuItems: [
          { text: "Услуги ОМС", link: "/oms", active: false },
          { text: "Правила записи на прием", link: "/rules", active: false },
          { text: "Анализы", link: "/tests", active: false },
          { text: "Госпитализация", link: "/hospitalization", active: false },
          { text: "Иностранным гражданам", link: "/foreigners", active: false },
          { text: "Справка для налогового вычета", link: "/tax-refund", active: false },
          { text: "График прима граждан", link: "/schedule", active: false },
          { text: "Список продуктов для передачи", link: "/list-product", active: true },
          { text: "Молитвенная комната", link: "/prayer-room", active: false },
          { text: "Медицинский туризм", link: "/medical-tourism", active: false }
        ],

      }
    },

  };
</script>

