<template>
  <div class="article-list">
    <div class="article-list__grid" v-if="underDepartments && underDepartments.length">
      <AppUnderDepartmentItem v-for="item in underDepartments" :key="item.id" :under_department_item="item" :linksDepartment="linksDepartment" />
    </div>
  </div>
</template>

<script>
  import AppUnderDepartmentItem from '@/components/UnderDepartment/UnderDepartmentItem.vue'
  import { mapState } from 'vuex'

  export default {
    name: "AppUnderDepartmentList",
    data() {
      return {
      };
    },
    props: {
      linksDepartment: {
        type: String,
        required: true
      }
    },
    components: {
      AppUnderDepartmentItem,
    },
    computed: {
      ...mapState({
        underDepartments: (state) => state.department.underDepartments,
      }),
    },


  };
</script>

<style scoped>

</style>
