<template>
  <div class="general-info">
    <div class="general-info__container">
      <AppBreadCrumbs/>
      <div class="general-info__row general-info__align-items">
        <div class="general-info__column">
          <div class="general-info__title">
            <h1 class="text-TTDrugs-Bold-40 color-green left text-uppercase">Волонтерство</h1>
          </div>
          <div class="general-info__block-row">
            <div class="">
              <h2>По зову сердца</h2>

              <p>Особое внимание в краевом перинатальном центре уделяют работе с будущим поколением медицинских работников. В частности, здесь активно развивают волонтерское движение. В команде добровольцев - студенты Алтайского государственного университета (направление Клиническая психология), Барнаульского базового медицинского колледжа и Института клинической медицины Алтайского государственного медицинского университета.</p>
              <p>Вначале волонтеры осваивают специальную программу подготовки, разработанную экспертами центра. Далее, на практике развивают профессиональные навыки по оказанию помощи пациенткам:</p>

              <ul class="under-department-content__list">
                <li>оказывают поддержку в родах женщинам, не способным совладать со страхом и тревогой;</li>
                <li>проводят арт-терапевтические групповые занятия с женщинами, переживающими стресс рождения ребенка раньше срока, для компенсации негативных переживаний;</li>
                <li>проводят мониторинг и социальное патронирование на дому семей женщин, родивших ребенка раньше положенного срока.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="under-department-content">
        <div class="under-department-content__first-block-info">
          <div class="under-department-content__first-block">

            <p>Полезные для будущей профессии навыки студенты получают, участвуя в интересных проектах центра «ДАР»: «Мы рядом!», «Капли Жизни», «Жаворонки» и других. Так, в проекте «Мы рядом!» волонтеры помогают создать благоприятную обстановку, находясь с женщиной в родовой палате. Используя знания психологии и акушерства, они поддерживают будущую маму, успокаивают, облегчают ее моральное состояние. А в проекте «Капли жизни» студенты, основываясь на рекомендациях Всемирной организации здравоохранения и Политике грудного вскармливания, принятой в «ДАР», рассказывают пациенткам о преимуществах грудного вскармливания.</p>
            <p>Если ты готов приносить пользу другим, мечтаешь больше узнать о медицине и попробовать себя в профессии уже сегодня, присоединяйся к команде волонтеров «ДАР»!</p>


            <h3>Как стать волонтером центра «ДАР»</h3>

            <p>Присоединяйся к нам:</p>

            <ul class="under-department-content__list">
              <li>если ты являешься студентом КГБПОУ «Барнаульский базовый медицинский колледж», ФГБОУ ВО «АлтГУ», ФГБОУ ВО «АГМУ» и тебе небезразличны чужие трудности;</li>
              <li>если у тебя есть желание приносить пользу другим;</li>
              <li>если ты мечтаешь больше узнать о медицине и попробовать себя в профессии уже сегодня.</li>
            </ul>

            <p>Записаться в команду волонтеров центра «ДАР» можно на своей кафедре.</p>

            <h3>Документы, необходимые для приема:</h3>

            <ul class="under-department-content__list">
              <li>ксерокопия паспорта;</li>
              <li>СНИЛС;</li>
              <li>флюорография;</li>
              <li>справка от инфекциониста об отсутствии контакта с инфекционными больными или прививочный сертификат;</li>
              <li>ксерокопия студенческого билета.</li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>
<script>
  import AppBreadCrumbs from '@/components/BreadCrumbs.vue'

  export default {
    name: "AppVolunteering",
    components: { AppBreadCrumbs },
    // components: {  DynamicMenu },
    metaInfo() {
      return {
        title: 'Волонтерство | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Узнайте все об услугах образования в Алтайском краевом клиническом перинатальном центре ДАР.'
          },
          {
            name: 'keywords',
            content: 'образование, алтайский краевой клинический перинатальный центр, перинатальный центр, дар'
          }
        ]
      };
    },
    data() {
      return {
        nameDepartment: 'Правила записи',
        linksDepartment: 'rules',
        menuItems: [
          { text: "Документы", link: "/", active: false },
          { text: "Документы", link: "/", active: false },
          { text: "Документы", link: "/", active: false },
          { text: "Школа будущих родителей", link: "/school-of-parents", active: false },
          { text: "Волонтерская деятельность", link: "/volunteer-activities", active: true },

        ],

      }
    },

  };
</script>

