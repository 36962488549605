<template>
  <div class="general-info">
    <div class="general-info__container">
      <AppBreadCrumbs/>
      <div class="general-info__row general-info__align-items-start">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title">
            <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">Образование</h1>
          </div>
          <div class="general-info__row">
            <div class="article-list">
              <div class="article-list__mini-file" >
                <AppItemFiles v-for="(item,index) in folderDocuments.files" :key="index" :file_item="item" :current-path="folderDocuments.currentPath" :name_folder="link"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  import { actionTypesDocuments } from '@/store/modules/documents'
  import { mapState } from 'vuex'
  import AppItemFiles from '@/components/Documents/ItemFiles.vue'
  import AppBreadCrumbs from '@/components/BreadCrumbs.vue'

  export default {
    name: "AppDocumentsInstitution",

    components: { AppBreadCrumbs, AppItemFiles,    DynamicMenu },
    metaInfo() {
      return {
        title: 'Образование | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Документы Алтайского краевого клинического перинатального центра ДАР. Образование. Подготовьтесь к родам вместе с Алтайским краевым клиническим перинатальным центром ДАР.'
          },
          {
            name: 'keywords',
            content: 'образование, документы, перинатальный центр ДАР'
          }
        ]
      };
    },
    data() {
      return {
        name: 'Образование',
        link: 'education',
        path: '/app/documents',
        menuItems: [
          { text: "Документы учреждения", link: "/documents/institution", active: false },
          { text: "Образование", link: "/documents/education", active: true },
          { text: "Пациентам", link: "/documents/patients", active: false },
          { text: "Платные услуги", link: "/documents/paid-services", active: false },
          { text: "Противодействие коррупции", link: "/documents/anti-corruption", active: false },

        ],
      }
    },
    computed: {
      ...mapState({
        folderDocuments: (state) => state.documents.folderDocuments,
        isLoading: (state) => state.documents.isLoading,
      }),
    },
    methods: {
      fetchGetFileInFolder() {
        this.$store.dispatch(actionTypesDocuments.getNextFolderDocuments, {name: this.name, currentPath: this.path })
            .then(() => {
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      }
    },
    mounted() {
    this.fetchGetFileInFolder()
    }
  };
</script>
