<template>
  <div class="general-info">
    <div class="general-info__container">
      <AppBreadCrumbs/>
      <div class="general-info__title-left">
        <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">ДИАГНОСТИКА ПРИЧИН БЕСПЛОДИЯ</h1>
      </div>
      <div class="under-department-content">
        <div class="under-department-content__first-block">
          <h2><span class="bold">Существует два типа бесплодия у женщин и мужчин:</span></h2>

          <p><span class="bold">Первичное</span> – такой диагноз ставится, когда пациентка никогда не была беременна, и в течение года при регулярной половой жизни без контрацепции беременность не наступает;<br>
            <span class="bold">Вторичное</span> - когда ранее беременности были, но в настоящее время попытки зачать ребенка не приводят к успеху.</p>

          <p>Если в течение года у женщины при регулярных незащищенных половых контактах не получается забеременеть, нужно пройти обследование и ей и ее партнеру.</p>

          <p>Признаки инфертильности не всегда заметны - большая часть заболеваний и патологий характеризуется отсутствием симптомов.</p>

          <p>В нашем центре можно пройти весь комплекс необходимых обследований.</p>

          <p><span class="bold">При подозрении на женское бесплодие:</span></p>

          <ul class="under-department-content__list">
            <li>консультация репродуктолога;</li>
            <li>ультразвуковое исследование органов малого таза;</li>
            <li>гистеросальпингография;</li>
            <li>эхогистеросальпингоскопия;</li>
            <li>анализы на инфекции;</li>
            <li>исследование гормонального профиля;</li>
            <li>биохимия и содержание гормонов;</li>
            <li>иногда при необходимости - гистероскопия, лапароскопия и др.</li>
          </ul>

          <p><span class="bold">При подозрении на мужское бесплодие:</span></p>

          <ul class="under-department-content__list">
            <li>консультация уролога-андролога;</li>
            <li>спермограмма;</li>
            <li>иммунологический тест (MAR-тест);</li>
            <li>оценка гормонального статуса;</li>
            <li>генетическое исследование;</li>
            <li>анализы на инфекции;</li>
            <li>УЗИ простаты.</li>
          </ul>

          <p>Перинатальный центр предоставляет полный комплекс услуг в области лабораторных, функциональных, ультразвуковых, эндоскопических и компьютерных исследований. В лаборатории осуществляется более 4000 различных анализов всех биологических сред организма. Часть их них проводится в режиме «CITO» - в течение 1 часа.</p>

          <p><span class="bold">Записаться на прием к специалистам можно по будням с 8:00 до 16:00 в регистратуре при личном обращении или по номерам телефонов: 8 (3852) 59-11-30, 8(963) 536-54-08</span></p>

          <p>При обращении к специалисту необходимо иметь при себе: полис ОМС, паспорт, СНИЛС, результаты более ранних обследований - при наличии.</p>

        </div>
<!--        <div class="under-department-content__second-block">-->
<!--          <div class="under-department-content__second-block-image" >-->
<!--             <img src="../../assets/img/eco/eco2.jpg" alt="Диагностика причин бесплодия" class="under-department-content__image" />-->
<!--          </div>-->
<!--&lt;!&ndash;          <div class="under-department-content__second-block-text" v-html="contentTwo">&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
  import AppBreadCrumbs from '@/components/BreadCrumbs.vue'

  export default {
    name: "AppDiagnostics",
    components: { AppBreadCrumbs },
    metaInfo() {
      return {
        title: 'Диагностика причин бесплодия | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Узнайте все об услугах экстракорпорального оплодотворения (ЭКО) в Алтайском краевом клиническом перинатальном центре ДАР. Мы предлагаем современные методы лечения бесплодия с использованием передовых технологий. Доверьте ваше здоровье нашим опытным специалистам.'
          },
          {
            name: 'keywords',
            content: 'эко, перинатальный центр ДАР'
          }
        ]
      };
    },
    props: {

    },
    computed: {

    },
    methods: {

    },
    mounted() {
    }

  };
</script>

<style scoped>

</style>
