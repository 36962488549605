<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title">
            <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">Документы</h1>
          </div>
            <div class="article-list">
              <div class="article-list__grid-mini">
                <div class="document-item"  v-for="(item,index) in files" :key="index" @click="goToDocumentsFolder(item)">
                  <div class="document-item__text">
                    <p class="text-TTDrugs-bold-18 color-gray center">{{ item.name }}</p>
                  </div>

                  <div class="document-item__svg"  v-if="item.isFolder">
                    <img src="../../assets/svg/folder.svg" alt="folder">
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  import { actionTypesDocuments } from '@/store/modules/documents'
  import { mapState } from 'vuex'

  export default {
    name: "AppDocuments",
    components: {   DynamicMenu },
    metaInfo() {
      return {
        title: 'Документы | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Просмотрите и скачайте необходимые документы, связанные с медицинским обслуживанием в Алтайском краевом клиническом перинатальном центре ДАР. Здесь вы найдете все актуальные нормативные документы, формы заявлений и другие важные файлы.'
          },
          {
            name: 'keywords',
            content: 'документы, документы учреждения, образование, платные услуги, противодействияе коррупции, нормативные документы, формы заявлений, медицинское обслуживание, Алтайский краевой клинический перинатальный центр'
          }
        ]
      };
    },

    data() {
      return {
        menuItems: [
          { text: "О центре", link: "/about", active: false },
          { text: "Блог главного врача", link: "/about/blog", active: false },
          { text: "Руководство", link: "/about/management", active: false },
          { text: "Специалисты", link: "/about/specialists", active: false },
          { text: "Общие сведения", link: "/about/general-info", active: false },
          { text: "Документы", link: "/documents", active: true },
          { text: "Режим работы", link: "/about/schedule", active: false },
          { text: "Контакты", link: "/about/contacts", active: false },
          { text: "Вакансии", link: "/about/vacancies", active: false },
          { text: "Новости", link: "/about/news", active: false },
          { text: "Отзывы", link: "/about/reviews", active: false },
          { text: "Галерея", link: "/about/gallery", active: false }
        ],
        files: [
          {
            name: "Документы учреждения",
            nameLink: "Документы учреждения",
            isFolder: true
          },
          {
            name: "Образование",
            nameLink: "Образование",
            isFolder: true
          },
          {
            name: "Пациентам",
            nameLink: "Документы пациентам",
            isFolder: true
          },
          {
            name: "Платные услуги",
            nameLink: "Документы платные услуги",
            isFolder: true
          },
          {
            name: "Противодействие коррупции",
            nameLink: "Противодействие коррупции",
            isFolder: true
          }
        ]
      }
    },
    computed: {
      ...mapState({
        documents: (state) => state.documents.documents,
        isLoading: (state) => state.documents.isLoading,
      }),
    },
    methods: {
      goToDocumentsFolder(item) {
        if (item.isFolder) {
          //ПРОХОЖУ ДАЛЬШЕ В ПАПКУ
          this.$router.push({ name: item.nameLink});

        } else {
          console.log('Request download')
        }
      }
    },
    mounted() {
      this.$store.dispatch(actionTypesDocuments.getDocuments)
          .then(() => {
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
    }
  };
</script>
