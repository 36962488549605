<template>
  <div class="general-info">
    <div class="general-info__container">
      <AppBreadCrumbs/>
      <div class="general-info__title-left">
        <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">ЭКО С ДОНОРСКОЙ СПЕРМОЙ</h1>
      </div>
      <div class="under-department-content">

        <div class="under-department-content__first-block">
          <h2>Доноры спермы</h2>

          <p>Доноры спермы предоставляют биоматериал семьям для преодоления бесплодия, и не берут на себя родительские обязанности по отношению к будущему ребенку.</p>

          <h3>ЭКО с донорской спермой показано при:</h3>
          <ul class="under-department-content__list">
            <li>азооспермии;</li>
            <li>патоспермии;</li>
            <li>наследственных заболеваниях у мужчины.</li>
          </ul>

          <p>Все кандидаты проходят обязательное медицинское обследование, а именно:</p>
          <ul class="under-department-content__list">
            <li>спермограмму;</li>
            <li>клиническое и биохимическое исследование крови;</li>
            <li>определение группы крови и резус-фактора;</li>
            <li>исследование на венерические заболевания;</li>
            <li>осмотр узких специалистов при необходимости.</li>
          </ul>

          <p>Донорская сперма не используется сразу (“свежей”). Сначала биоматериал проходит криоконсервацию и хранится 6 месяцев в специальных условиях. После разморозки сперма заново проверяется на наличие всевозможных заболеваний и только после этого используется для ЭКО.</p>

          <h3>Как стать донором спермы</h3>

          <h3>Требования к кандидатам:</h3>
          <ul class="under-department-content__list">
            <li>совершеннолетний возраст до 35 лет;</li>
            <li>привлекательная внешность и хорошие физические данные;</li>
            <li>отсутствие ярко выраженного фенотипа;</li>
            <li>отсутствие генетических отклонений, наследственных заболеваний;</li>
            <li>наличие высшего образования (не обязательно).</li>
          </ul>

          <h3>Что получают доноры спермы при прохождении программы в перинатальном центре:</h3>
          <ul class="under-department-content__list">
            <li>Полная анонимность - в анкете указываются только физиологические данные и возраст. Все остальное - сугубо конфиденциально.</li>
            <li>Бесплатные клинические, лабораторные, генетические исследования.</li>
            <li>Комфортные условия - процедуры проводятся в удобный день и время.</li>
            <li>Гарантированная достойная оплата.</li>
          </ul>

          <p>После проведения всех обследований, если все в порядке, оформляется карта донора и подписывается официальное согласие. Затем донорская сперма попадает в банк центра, где хранится до момента применения.</p>

          <p>Если вы хотите стать донором ооцитов, помочь бездетным парам, и получить вознаграждение, приходите на консультацию в наш центр.</p>

          <p>Записаться на прием к специалистам можно по будням с 8:00 до 16:00 в регистратуре при личном обращении или по номерам телефонов горячей линии: 8 (3852) 591-130, 8 (963) 536-54-08.</p>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import AppBreadCrumbs from '@/components/BreadCrumbs.vue'

  export default {
    name: "AppECOBoys",
    components: { AppBreadCrumbs },
    metaInfo() {
      return {
        title: 'ЭКО с донорской спермой | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Узнайте все об услугах экстракорпорального оплодотворения (ЭКО) в Алтайском краевом клиническом перинатальном центре ДАР. Мы предлагаем современные методы лечения бесплодия с использованием передовых технологий. Доверьте ваше здоровье нашим опытным специалистам.'
          },
          {
            name: 'keywords',
            content: 'эко, экстракорпоральное оплодотворение, методы лечения,  Алтайский краевой клинический перинатальный центр'
          }
        ]
      };
    },
    props: {

    },
    computed: {

    },
    methods: {

    },
    mounted() {
    }

  };
</script>

<style scoped>

</style>
