<template>
  <div class="blog-page">
    <div class="blog-page__container">
      <div class="blog-page__row">
      <DynamicMenu :menuItems="menuItems" />
      <div class="blog-page__content">
        <div class="blog-page-info">
          <div class="blog-page-info__title"><h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">Блог Главного <br> Врача</h1></div>
          <div class="blog-page-info__subtitle"><h2 class="text-circe-bold-40 color-green center">Ирина Владимировна <br>  Молчанова</h2></div>
          <div class="blog-page-info__description"><h3 class="text-circe-regular-24 color-black center">Кандидат медицинских наук, отличник здравоохранения, врач акушер-гинеколог высшей категории</h3></div>
        </div>
        <div class="blog-page-img">
          <img src="../../assets/img/main-doctor.png" class="blog-page-img__img" alt="Главный врач">
        </div>
      </div>
      </div>
      <div class="blog-page-preview">
        <div class="blog-page-preview__title">
          <p class="text-TTDrugs-Bold-40 color-green">Колонка главного врача</p>
        </div>
        <div class="blog-page-preview__subtitle">
          <p class="text-circe-bold-24 color-gray">Новый мир</p>
        </div>
        <div class="blog-page-preview__text">
          <p class="text-circe-20">
            Здравствуйте, дорогие посетители нашего сайта! 2021 год мы открываем новой версией официальной страницы «ДАРа». Этому долгожданному моменту предшествовала кропотливая работа многих людей, которая объединила в творческом процессе врачей, журналистов, дизайнеров, программистов. В процессе работы нам пришлось изучить много новых правил и законов, погрузиться в удивительную науку лингвистику, и все это для того, чтобы представить более полную, понятную и правильно воспринимаемую информацию о нашем перинатальном центре, нашем коллективе и каждом его члене, как с профессиональной точки зрения, так и человеческих качеств. И опять же, главными экспертами в процессе создания нашего сайта были вы – наши пациенты. Мы использовали богатый опыт нашего общения на страницах ВКонтакте (ссылка). Нам стало очевидно, что наши с Вами отношения выросли для дополнительного серьезного формата общения, поэтому мы постарались стать для Вас максимально открытыми и интересными.
          </p>
        </div>
        <div class="blog-page-preview__text">
          <p class="text-circe-20">Дорогие наши пациенты, коллеги! Мы надеемся, что представленная информация позволит вам осознанно подойти к процессу получения той или иной медицинской услуги, стать партнером медицинского персонала в непростом процессе собственного лечения. Мы благодарны вам за обратную связь, за мнение каждого, за подсказки в отношении тех событий, которые, возможно, мы упустили из виду. Надеюсь, наш коллектив заработал репутацию организации, которая быстро и эффективно реагирует на мнение каждого, а площадка нашего нового сайта позволит вывести эту работу на новый уровень, с более обширным определением точек приложения усилий в направлении повышения качества медицинской помощи.</p>
        </div>
      </div>
<!--      Здесь статьи главного врача, на время убрала, если понадобится раскоментировать-->

<!--      <div class="blog-page-article">-->
<!--        <div class="blog-page-article__title">-->
<!--          <p class="text-TTDrugs-Bold-40 color-green">Статьи</p>-->
<!--        </div>-->
<!--        <AppArticleList/>-->
<!--      </div>-->

    </div>

  </div>
</template>

<script>
  import DynamicMenu from "@/components/DynamicMenu.vue";
  // import AppArticleList from '@/components/Article/ArticleList.vue'

  export default {
    name: "AppBlogMainDoctor",
    components: {
      // AppArticleList,
      DynamicMenu
    },
    metaInfo() {
      return {
        title: 'Блог главного врача | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Блог главного врача. Подробная информация о Алтайском краевом клиническом перинатальном центре ДАР.'
          },
          {
            name: 'keywords',
            content: 'Алтайский краевой клинический перинатальный центр'
          }
        ]
      };
    },
    data() {
      return {
        menuItems: [
          { text: "О центре", link: "/about", active: false },
          { text: "Блог главного врача", link: "/about/blog", active: true },
          { text: "Руководство", link: "/about/management", active: false },
          { text: "Специалисты", link: "/about/specialists", active: false },
          { text: "Общие сведения", link: "/about/general-info", active: false },
          { text: "Документы", link: "/documents", active: false },
          { text: "Режим работы", link: "/about/schedule", active: false },
          { text: "Контакты", link: "/about/contacts", active: false },
          { text: "Вакансии", link: "/about/vacancies", active: false },
          { text: "Новости", link: "/about/news", active: false },
          { text: "Отзывы", link: "/about/reviews", active: false },
          { text: "Галерея", link: "/about/gallery", active: false }
        ]
      };
    }
  };
</script>

<style scoped>

</style>
