<template>
  <div class="consent-page">
    <div class="consent-page__container">
      <Breadcrumbs/>
    <h1 class="text-circe-regular-24 color-black center">КГБУЗ «Алтайский краевой клинический перинатальный центр»</h1>
    <p class="text-circe-regular-20 color-black center">г. Барнаул, ул. Фомина, д. 154</p>

    <h2 class="text-circe-regular-24 color-black center">СОГЛАСИЕ</h2>
    <h3 class="text-circe-regular-20 color-black center">субъекта на обработку персональных данных</h3>

    <p>
      В соответствии с требованиями статьи 9 Федерального закона от 27.07.2006г. № 152-ФЗ
      «О персональных данных» настоящим выражаю свое согласие на обработку КГБУЗ
      «Алтайский краевой клинический перинатальный центр», расположенного по адресу:
      г. Барнаул, ул. Фомина, д. 154 (далее - Оператор) моих (представляемого мной лица)
      персональных данных, (в том числе, специальной категории персональных данных,
      касающейся состояния здоровья, биометрических персональных данных), включающих:
      фамилию, имя, отчество, пол, дату рождения, адрес места жительства, гражданство,
      контактный телефон, место работы, реквизиты документа, удостоверяющего личность,
      реквизиты полиса ОМС (ДМС), страховой номер индивидуального лицевого счета в
      Пенсионном фонде России (СНИЛС), сведения о состоянии моего (представляемого мной
      лица) здоровья, заболеваниях, случаях обращения за медицинской помощью, сведения о
      диагностических мероприятиях, назначенном и проведенном лечении, данных
      рекомендациях, в целях оказания мне (представляемому мной лицу) медицинских и
      медико-социальных услуг, в медико-профилактических целях, в целях установления
      медицинского диагноза, при условии, что обработка персональных данных, о факте
      обращения за оказанием медицинской помощи, состоянии здоровья и диагнозе, а также
      иные сведения, полученные при медицинском обследовании и лечении осуществляется
      лицом, профессионально занимающимся медицинской деятельностью и обязанным в
      соответствии с законодательством РФ сохранять врачебную тайну.
    </p>

    <p>
      В процессе оказания Оператором мне (представляемому мной лицу) медицинской помощи я
      предоставляю право медицинским работникам передавать мои персональные данные
      (персональные данные представляемого мной лица), содержащие сведения,
      составляющие врачебную тайну, другим должностным лицам Оператора и третьим лицам,
      а также должностным лицам органов государственной власти Алтайского края в сфере
      охраны здоровья, образования и социальной защиты, обязанным сохранять охраняемую
      законом тайну, в интересах моего (представляемого мной лица) обследования, лечения
      и социального сопровождения.
    </p>

    <p>
      Предоставляю Оператору право осуществлять все действия (операции) или совокупность
      действий (операций), совершаемых с использованием средств автоматизации или без
      использования таких средств, с моими персональными данными (персональными данными
      представляемого мной лица), включая сбор, запись, систематизацию, накопление,
      хранение, уточнение (обновление, изменение), извлечение, использование, передачу
      (распространение, предоставление, доступ), обезличивание, блокирование, удаление,
      уничтожение персональных данных.
    </p>

    <p>
      Оператор вправе обрабатывать мои (представляемого мной лица) персональные данные
      посредством внесения их в электронную базу данных, включения в списки (реестры) и
      отчетные формы, предусмотренные документами, регламентирующими представление
      отчетных данных (документов) по ОМС (договором ДМС). Оператор имеет право во
      исполнение своих обязательств по работе в системе ОМС (по договору ДМС) на обмен
      (прием и передачу) моими персональными данными со страховой медицинской
      организацией и территориальным фондом ОМС с использованием машинных носителей или
      по каналам связи, с соблюдением мер, обеспечивающих их защиту от несанкционированного
      доступа, при условии, что их прием и обработка будут осуществляться лицом,
      обязанным сохранять профессиональную тайну.
    </p>

    <p>
      Я подтверждаю, что осведомлен(а) о внедрении системы видеонаблюдения на территории
      Оператора в целях обеспечения безопасности рабочего процесса, предупреждения
      возникновения чрезвычайных ситуаций и обеспечения объективности расследования в
      случаях их возникновения.
    </p>

    <p>
      Мне известно, что медицинская организация, на основании данного мною согласия,
      вправе обрабатывать и передавать для обработки другим медицинским организациям мои
      (представляемого мной лица) персональные данные и данные медицинских исследований,
      необходимые для проведения очных/дистанционных (телемедицинских) консультаций без
      специального уведомления меня об этом.
    </p>

    <p>
      Я подтверждаю, что согласен(а) на проведение врачебных обходов и консилиумов
      врачей, в ходе которых в присутствии других пациентов, находящихся со мной в
      палате, будут озвучены мои персональные данные, а также информация, составляющая
      врачебную тайну: сведения о моем (представляемого лица) диагнозе и иная информация
      о состоянии моего здоровья.
    </p>

    <p>
      Настоящее согласие на обработку персональных данных действует на срок, необходимый
      для обработки и хранения персональных данных, не дольше, чем этого требуют цели
      обработки персональных данных, если срок хранения персональных данных не
      установлен федеральным законом, договором, стороной которого является субъект
      персональных данных. Обрабатываемые персональные данные подлежат уничтожению либо
      обезличиванию по достижении целей обработки или в случае утраты необходимости в
      достижении этих целей, если иное не предусмотрено действующим законодательством.
    </p>

    <p>
      Я оставляю за собой право отозвать свое (представляемого мной лица) согласие на
      обработку персональных данных (полностью или частично) посредством составления
      соответствующего письменного документа, который может быть направлен мной в адрес
      Оператора по почте заказным письмом с уведомлением о вручении либо вручен лично под
      расписку представителю Оператора.
    </p>

    <p>
      Я осведомлен(а), что мой отказ от предоставления согласия на использование моих
      персональных данных (персональных данных представляемого мной лица) не повлияет на
      качество медицинских услуг, которые мне (представляемому мной лицу) будут
      предоставлены.
    </p>

    <p>
      В случае получения моего письменного заявления об отзыве настоящего согласия на
      обработку персональных данных, Оператор обязан прекратить их обработку в течение
      периода времени, необходимого для завершения взаиморасчетов по оплате, оказанной мне
      (представляемому мной лицу) до этого медицинской помощи.
    </p>

    <p>
      Мне разъяснено Оператором, что в случае отзыва субъектом персональных данных
      согласия на обработку персональных данных Оператор вправе продолжить обработку
      персональных данных без согласия субъекта персональных данных при наличии оснований,
      указанных в пунктах 2-11 части 1 статьи 6, части 2 статьи 10 и части 2 статьи 11
      Федерального закона от 27.07.2006 №152-ФЗ «О персональных данных».
    </p>

    <p>
      Я подтверждаю, что права и обязанности в области защиты персональных данных мне
      известны.
    </p>
    </div>
  </div>
</template>

<script>
  import Breadcrumbs from '@/components/BreadCrumbs.vue'

  export default {
    name: "ConsentPage",
    metaInfo() {
      return {
        title: 'Согласие | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Согласие на обработку персональных данныйх'
          },
          {
            name: 'keywords',
            content: 'пациентам, алтайский краевой клинический перинатальный центр, перинатальный центр, дар'
          }
        ]
      };
    },
    components: { Breadcrumbs },
  };
</script>

<style scoped>
.consent-page {
  color: #333;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

</style>
