<template>
  <div class="" style="width: 100%">
    <div class="">
      <div class="news-block">
        <div class="news-block__info">
          <div class="news-block__title">
            <p class="text-TTDrugs-bold-24">{{news_item.title}}</p>
          </div>
          <div class="news-block__date">
            <p class="news-title color-gray">Дата публикации:  {{news_item.dateTime}}</p>
          </div>
          <div class="news-block__description">
            <p class="text-circe-20 color-gray" v-html="content"></p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>


  export default {
    name: "AppSectionNewsDetails",
    props: {
      news_item: {
        type: Object,
        required: true
      }
    },
    data() {
      return {

      };
    },
    computed: {
      content() {
        return this.news_item.content;
      },
    },
  };
</script>
