<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row general-info__align-items">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title">
            <h1 class="text-TTDrugs-Bold-40 color-green left text-uppercase">График прима граждан</h1>
          </div>
          <div class="general-info__block-row">
            <div class="">
              <AppScheduleTableDoctors/>
              <h2>Предварительная запись на личный прием</h2>
              <p>Предварительная запись на личный прием осуществляется по телефонам:</p>
              <p><strong>8 (3852) 591-130</strong> и <strong>8 (963) 536-54-08</strong></p>

            </div>

          </div>
        </div>
      </div>
      <div class="under-department-content">
        <div class="under-department-content__first-block-info">
          <div class="under-department-content__first-block">

            <h3>Прием граждан</h3>
            <p>В ежедневном режиме ведется прием граждан специалистом отдела по работе с обращениями граждан Натаровой Валентиной Владимировной в кабинете № 2007 (второй этаж, кабинет в холле слева от лифта) в рабочие дни с 8.00 до 16.00 без предварительной записи.</p>

            <h3>Обращения граждан и организаций</h3>
            <p>Обращения граждан и организаций в адрес перинатального центра также можно направить по почтовому адресу:</p>
            <p><strong>656045, Алтайский край, г. Барнаул, ул. Фомина, д. 154</strong></p>
            <p>Обращения в электронной форме направляются через кнопку обратной связи «Задать вопрос» на сайте <a href="http://дар22.рф">дар22.рф</a> или по электронной почте <a href="mailto:akkpc@zdravalt.ru">akkpc@zdravalt.ru</a>.</p>

          </div>
        </div>
      </div>

    </div>
  </div>

</template>
<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  import AppScheduleTableDoctors from '@/components/SheduleTableDoctors.vue'
  export default {
    name: "AppSchedulePatients",
    components: { AppScheduleTableDoctors,   DynamicMenu },
    metaInfo() {
      return {
        title: 'График прима граждан | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Подробная информация о Алтайском краевом клиническом перинатальном центре ДАР'
          },
          {
            name: 'keywords',
            content: 'пациентам, алтайский краевой клинический перинатальный центр, перинатальный центр, дар'
          }
        ]
      };
    },
    data() {
      return {
        nameDepartment: 'График прима граждан',
        linksDepartment: 'schedule',
        menuItems: [
          { text: "Услуги ОМС", link: "/oms", active: false },
          { text: "Правила записи на прием", link: "/rules", active: false },
          { text: "Анализы", link: "/tests", active: false },
          { text: "Госпитализация", link: "/hospitalization", active: false },
          { text: "Иностранным гражданам", link: "/foreigners", active: false },
          { text: "Справка для налогового вычета", link: "/tax-refund", active: false },
          { text: "График прима граждан", link: "/schedule", active: true },
          { text: "Список продуктов для передачи", link: "/list-product", active: false },
          { text: "Молитвенная комната", link: "/prayer-room", active: false },
          { text: "Медицинский туризм", link: "/medical-tourism", active: false }
        ],

      }
    },

  };
</script>

