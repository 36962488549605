<template>
  <div class="department">
    <div class="department__card">
      <div class="department__img">
        <AppImageLoader :src="item_department.image" :alt="item_department.id" class="department__image"/>
      </div>
      <div class="department__content">
        <div class="department__title">
          <p class="text-TTDrugs-bold-20">{{ item_department.name }}</p>
        </div>
        <ul class="department__list">
          <li v-for="item in item_department.items" :key="item" class="department__item text-circe-light-16" @click="goRouterUnderDepartment(item)">
            <span class="circle"></span>
            {{ item }}
          </li>
        </ul>
        <div class="department__footer" @click="goRouter()">
          <span class="text-circe-18 color-green">ПОДРОБНЕЕ</span>
          <div class="news-block-index__btn-img"><img src="../../assets/svg/arrow-right.svg" alt="Подробнее"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { actionTypesDepartment } from '@/store/modules/department';
  import AppImageLoader from '@/components/ImageLoader.vue';

  export default {
    name: "AppDepartmentItem",
    components: { AppImageLoader },
    data() {
      return {
        hover: false
      }
    },
    props: {
      item_department: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    methods: {
      findRouteByName() {
        return this.$router.options.routes.find(route => route.name === this.item_department.links);
      },
      extractNameDepartment(path) {
        const parts = path.split('/');
        return parts.length > 2 ? parts[2] : '';
      },
      goRouter() {
        this.$store.dispatch(actionTypesDepartment.selectedDepartmentId, this.item_department.name);
        this.$router.push({ name: this.item_department.links });
      },
      goRouterUnderDepartment(item) {
        const route = this.findRouteByName();
        if (route) {
          const nameDepartment = this.extractNameDepartment(route.path);
          this.$router.push({ name: 'UnderDepartment', params: { nameDepartment: nameDepartment, name: item } });
        } else {
          console.error('Route not found');
        }
      }
    }
  };
</script>

<style scoped>
/* Добавьте ваши стили здесь */
</style>
