import { render, staticRenderFns } from "./DoctorsPeopleList.vue?vue&type=template&id=2f4421da&scoped=true"
import script from "./DoctorsPeopleList.vue?vue&type=script&lang=js"
export * from "./DoctorsPeopleList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f4421da",
  null
  
)

export default component.exports