<template>
  <div class="dynamic-menu">
    <ul>
      <li v-for="item in menuItems" :key="item.text" :class="{'active': item.active}">
        <router-link :to="item.link" class="text-TTDrugs-regular-16">{{ item.text }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: "AppDynamicMenu",
    props: {
      menuItems: {
        type: Array,
        required: true
      }
    }
  };
</script>

<style scoped>

</style>
