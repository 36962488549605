<template>
  <div class="general-info">
    <div class="general-info__container">
      <AppBreadCrumbs/>
      <div class="general-info__title-left">
        <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">ЭКО С ДОНОРСКИМИ ЯЙЦЕКЛЕТКАМИ</h1>
      </div>
      <div class="under-department-content">

        <div class="under-department-content__first-block">
          <h2>ЭКО с донорскими яйцеклетками</h2>

          <p>В половине случаев пара не может иметь детей из-за проблем с репродуктивным здоровьем женщины. Причиной тому может быть снижение овариального резерва (запаса яйцеклеток в яичниках), связанное с различными факторами: возраст женщины, хирургические операции на органах малого таза с повреждением яичников, химио- и лучевая терапия при онкологических заболеваниях и др. В настоящее время такая медицинская ситуация – это не приговор, т.к. есть возможность использовать яйцеклетки донора.</p>

          <h3>В каких случаях прибегают к ЭКО с донорскими яйцеклетками:</h3>
          <ul class="under-department-content__list">
            <li>При отсутствии яичников (врожденное или после оперативного удаления) и аномалии их развития;</li>
            <li>При состоянии после овариэктомии (хирургического удаления яичников), радио-, химиотерапии;</li>
            <li>При менопаузе, преждевременном истощении яичников или резистентности;</li>
            <li>Если были многократные безуспешные попытки ЭКО;</li>
            <li>При врожденных аномалиях развития репродуктивной системы, при которых яичники отсутствуют или недоразвиты.</li>
          </ul>

          <p>Эффективность программ с использованием ооцитов донора достигает 60-70% на цикл стимуляции донора, что является самым эффективным показателем из всей совокупности методик ЭКО.</p>

          <h3>Как стать донором ооцитов</h3>

          <h3>Требования к кандидатам в доноры:</h3>
          <ul class="under-department-content__list">
            <li>Возраст от 18 до 35 лет;</li>
            <li>Физическое и психическое здоровье;</li>
            <li>Наличие хотя бы одного здорового ребенка;</li>
            <li>Официальное согласие на процедуру;</li>
            <li>Отсутствие генетических нарушений и наследственных болезней;</li>
            <li>Отсутствие вредных привычек;</li>
            <li>Высшее образование (желательно);</li>
            <li>Согласие на прохождение медицинских исследований.</li>
          </ul>

          <h3>Что получают доноры яйцеклеток в нашем центре:</h3>
          <ul class="under-department-content__list">
            <li>Официальный договор, полностью защищающий интересы донора;</li>
            <li>Гарантированная оплата (в течение 10 дней после манипуляции);</li>
            <li>100% конфиденциальность;</li>
            <li>Дорогостоящее обследование за счет нашего центра репродукции.</li>
          </ul>

          <p>Желающих стать донором, помочь бездетным парам и получить вознаграждение приглашаем на консультацию.</p>

          <p>Записаться на прием к специалистам можно по будням с 8:00 до 16:00 в регистратуре при личном обращении или по номерам телефонов горячей линии: 8 (3852) 591-130, 8 (963) 536-54-08.</p>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import AppBreadCrumbs from '@/components/BreadCrumbs.vue'

  export default {
    name: "AppECOGirls",
    components: { AppBreadCrumbs },
    metaInfo() {
      return {
        title: 'ЭКО с донорскими яйцеклетками | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Узнайте все об услугах экстракорпорального оплодотворения (ЭКО) в Алтайском краевом клиническом перинатальном центре ДАР. Мы предлагаем современные методы лечения бесплодия с использованием передовых технологий. Доверьте ваше здоровье нашим опытным специалистам.'
          },
          {
            name: 'keywords',
            content: 'эко, экстракорпоральное оплодотворение, методы лечения,  Алтайский краевой клинический перинатальный центр'
          }
        ]
      };
    },
    props: {

    },
    computed: {

    },
    methods: {

    },
    mounted() {
    }

  };
</script>

<style scoped>

</style>
