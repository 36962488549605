import PaidServicesAPi from "@/api/paidServices.js";
const state = {
  isLoading: false, //ожидание запроса
  validationErrors: null, //ошибки
  paid: [],
  allData: {},
};

export const mutationTypes = {
  getPaidStart: "[vacancies] getPaidStart",
  getPaidSuccess: "[vacancies] getPaidSuccess",
  getPaidFailure: "[vacancies] getPaidFailure",

  getStatisticsStart: "[appeals] getStatisticsStart",
  getStatisticsSuccess: "[appeals] getStatisticsSuccess",
  getStatisticsFailure: "[appeals] getStatisticsFailure",

};
export const actionTypesPaid = {
  getPaid: "[paid services] getPaid",
  getStatistics: "[appeals] getStatistics",
};

const mutations = {
  //Получение Платных услуг
  [mutationTypes.getPaidStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getPaidSuccess](state, info) {
    state.isLoading = false;
    state.paid = info
  },
  [mutationTypes.getPaidFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // получение статистики
  [mutationTypes.getStatisticsStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.getStatisticsSuccess](state, data) {
    state.isLoading = false;
    state.allData = data;
  },
  [mutationTypes.getStatisticsFailure](state, payload) {
    state.validationErrors = payload.message;
    state.isLoading = false;
  },
};
const actions = {
  [actionTypesPaid.getPaid](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getPaidStart);
      PaidServicesAPi.getPaid()
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(
                  mutationTypes.getPaidSuccess,
                  response.data.result
              );
            } else {
              context.commit(mutationTypes.getPaidFailure, response);
            }
            resolve(response.data);
          })
          .catch((response) => {
            context.commit(mutationTypes.getPaidFailure, response);
            console.log("ошибка", response);
          });
    });
  },
  [actionTypesPaid.getStatistics](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getStatisticsStart);
      PaidServicesAPi
          .getStatistics()
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.getStatisticsSuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.getStatisticsFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.getStatisticsFailure, err);
            console.log("ошибка", err);
          });
    });
  },
};

export default {
  state,
  mutations,
  actions,
};
