<template>
  <div class="new-detail">
    <div class="new-detail__container">
      <Breadcrumbs/>
      <div class="general-info__column">
        <AppSectionNewsDetails :news_item="itemNews"/>
      </div>
    </div>
  </div>
</template>

<script>

  import { mapState } from "vuex";

  import Breadcrumbs from '@/components/BreadCrumbs.vue'
  import { actionTypesNews } from '@/store/modules/news'
  import AppSectionNewsDetails from '@/components/News/SectionNewsDetails.vue'


  export default {
    components: {
      AppSectionNewsDetails,
      Breadcrumbs,
    },
    metaInfo() {
      return {
        title: 'Новости | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Новости перенатального центра ДАР'
          },
          {
            name: 'keywords',
            content: 'пациентам, алтайский краевой клинический перинатальный центр, перинатальный центр, дар'
          }
        ]
      };
    },
    name: "AppItemNewsDetail",
    data() {
      return {
        searchFIO: "",
      };
    },
    props: {

    },
    computed: {
      ...mapState({
        itemNews: (state) => state.news.itemNews,
        isLoading: (state) => state.news.isLoading,
      }),

    },

    mounted() {
      this.$store.dispatch(actionTypesNews.getNewsItem, this.$route.params.id);
    },
  };
</script>
