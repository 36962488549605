<template>
  <main >
    <section class="main-content">
      <img src="../assets/img/poster-index-2.jpg" alt="poster" class="main-content__img">
    <div class="main-content__overlay">
      <h1 class="text-TTDrugs-Bold-40 main-content__text-overlay color-white">КГБУЗ "Алтайский краевой клинический перинатальный центр" ДАР</h1>
    </div>
    </section>
    <section class="main-content-video hide-in-accessibility" >
      <div class="main-content-video__container">
        <div class="main-content-video__wrap">
          <div class="main-content-video__information">
            <div class="main-content-video__title">
              <p class="text-TTDrugs-Bold-40 color-green">О ПЕРИНАТАЛЬНОМ
                ЦЕНТРЕ</p>
            </div>
            <div class="main-content-video__description">
              <p class="text-circe-regular-24">В настоящее время «ДАР» представляет собой законченный цикл медицинских технологий, начиная с планирования беременности, ее вынашивания, родоразрешения и завершая выхаживанием ребенка до тех пор, пока мама и малыш не будут готовы к выписке.</p>
            </div>
          </div>
          <div class="main-content-video__wrap-video " >
            <video autoplay muted loop id="background-video" class="main-content-video__video" preload="metadata" poster="../assets/img/poster-index.png">
              <source src="../assets/video/index.mp4" type="video/mp4">
            </video>
          </div>
        </div>
      </div>
    </section>
    <section class="main-content-section1 hide-in-accessibility">
      <img src="../assets/img/section1-index-remove31.png" alt="section1-index" class="main-content-section1__img">
      <div class="main-content-section1__container main-content-section1__wrap">
        <div class="intro-text">
          <div class="intro-text__title-wrap">
          <p class="text-TTDrugs-Bold-40 color-gray">Вспоминайте о родах, как об одном из самых счастливых моментов своей жизни</p>
          </div>
        </div>
        <div class="main-content-section1__block-info">
        <div class="stats-block__item">
          <div class="stats-block__item-title">
            <p class="text-TTDrugs-Bold-60 color-gray" v-if="allData !== {}">{{allData.countOpen}}</p>
            <p class="text-TTDrugs-Bold-60 color-gray" v-else>0</p>
          </div>
          <p class="text-TTDrugs-regular-18">Родов с момента открытия</p>
        </div>
        <div class="stats-block__item">
          <div class="stats-block__item-title">
            <p class="text-TTDrugs-Bold-60 color-gray" v-if="allData !== {}">{{allData.countStartYear}}</p>
            <p class="text-TTDrugs-Bold-60 color-gray" v-else>0</p>
          </div>
          <p class="text-TTDrugs-regular-18">Родов с начала года</p>
        </div>
        <div class="stats-block__item">
          <div class="stats-block__item-title">
            <p class="text-TTDrugs-Bold-60 color-gray"  v-if="allData !== {}">{{allData.countDay}}</p>
            <p class="text-TTDrugs-Bold-60 color-gray" v-else>0</p>
          </div>
          <p class="text-TTDrugs-regular-18">Родов за сутки</p>
        </div>
        </div>
      </div>
    </section>
    <div class="wrap-information-block">
    <section class="statistics-section4" >
      <div class="statistics-section4__container statistics-section4__wrap-index-block">
        <div class="statistics-section4__index-block" >
          <div class="statistics-section4__img">
            <img src="../assets/img/section3-index.png" alt="Секция 3">
          </div>
          <div class="statistics-section4__wrap-info-text" >
            <div class="statistics-section4__title">
              <p class="text-TTDrugs-Bold-40 color-green">Ведение беременности</p>
            </div>
            <div class="statistics-section4__flex">
              <div class="statistics-section4__subtitle">
                <p class="text-circe-bold-22">КОМПЛЕКСНОЕ НАБЛЮДЕНИЕ ЗА ЗДОРОВЬЕМ БУДУЩЕЙ МАМЫ И МАЛЫША, ДЕЛИКАТНЫЙ ПОДХОД, ВЫСОКИЙ УРОВЕНЬ ПРОФЕССИОНАЛИЗМА
                </p>
              </div>
              <div class="statistics-section4__description">
                <p class="text-circe-bold-22 color-green">НАБЛЮДЕНИЕ В ТЕЧЕНИЕ ВСЕГО СРОКА БЕРЕМЕННОСТИ</p>
              </div>
              <div class="statistics-section4__text">
                <p class="text-circe-20">Мы оказываем помощь уже на этапе планирования беременности, предлагаем полный спектр обследований. При необходимости, в ведении беременности принимают участие профильные специалисты: кардиолог, уролог, невролог, эндокринолог, психолог.</p>
              </div>
            </div>
            <div class="statistics-section4__btn" data-aos="fade-up"  data-aos-duration="1000" @click="routeGoPregnancy">
              <button class="index-btn">Подробнее</button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="statistics-section3">
      <div class="statistics-section3__container">
      <div class="statistics-section3__wrap-index-block">
        <div class="statistics-section3__index-block">
          <div class="statistics-section3__title">
            <p class="text-TTDrugs-Bold-40 color-green">РОДЫ</p>
          </div>
          <div class="statistics-section3__wrap-info-text">
          <div class="statistics-section3__flex">
          <div class="statistics-section3__info">
            <div class="statistics-section3__subtitle">
              <p class="text-circe-bold-22">КОМФОРТНЫЕ И БЕЗОПАСНЫЕ</p>
            </div>
          </div>
          </div>
          <div class="statistics-section3__flex">
            <div class="statistics-section3__description">
              <p class="text-circe-bold-22 color-green">ПОЧЕМУ ВЫБИРАЮТ НАС? </p>
            </div>
            <div class="statistics-section3__text">
              <p class="text-circe-20">Почему выбирают нас? Центр имеет всё необходимое для того, чтобы наши пациенты получали качественную и безопасную медицинскую помощь. Каждый специалист центра стремится сделать так, чтобы Вы чувствовали себя как дома.</p>
            </div>
            <div class="statistics-section3__btn" data-aos="fade-up"  data-aos-duration="1000" @click="routeGoChildbirth">
              <button class="index-btn">Подробнее</button>
            </div>
          </div>
          </div>
        </div>
        <div class="statistics-section3__img">
          <img src="../assets/img/section4-index.png" alt="Секция 4">
        </div>
      </div>
      </div>
    </section>
    <section class="statistics-section4">
      <div class="statistics-section4__container statistics-section4__wrap-index-block">
        <div class="statistics-section4__index-block">
          <div class="statistics-section4__img statistics-background">
            <img src="../assets/img/section5-index.png" alt="Секция 5">
          </div>
          <div class="statistics-section4__wrap-info-text" >
            <div class="statistics-section4__title">
              <p class="text-TTDrugs-Bold-40 color-green">ЭКО</p>
            </div>
            <div class="statistics-section4__flex">
              <div class="statistics-section4__subtitle">
                <p class="text-circe-bold-22">ПОЗНАТЬ РОДИТЕЛЬСКОЕ СЧАСТЬЕ
                </p>
              </div>
              <div class="statistics-section4__description">
                <p class="text-circe-bold-22 color-green">ЧТО ТАКОЕ ЭКО?</p>
              </div>
              <div class="statistics-section4__text">
                <p class="text-circe-16">Экстракорпоральное оплодотворение является высокоэффективной процедурой, после которой выносить ребенка удается даже женщинам, недонократно сталкивавшимся с неудачами. В нашем центре будущие родители получат полное сопровождение: от момента планируемой беременности до долгожданной выписки домой. Наши специалисты лаборатории клинической эмбриологии успешно применяют на практике последние достижения в области ЭКО</p>
              </div>
            </div>
            <div class="statistics-section4__btn"  data-aos="fade-up"  data-aos-duration="1000" @click="routeGoECO">
              <button class="index-btn">ПОДРОБНЕЕ</button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="statistics-section3">
      <div class="statistics-section3__container">
        <div class="statistics-section3__wrap-index-block">
          <div class="statistics-section3__index-block">
            <div class="statistics-section3__title">
              <p class="text-TTDrugs-Bold-40 color-green">ПЛОД И НОВОРОЖДЕННЫЙ</p>
            </div>
            <div class="statistics-section3__wrap-info-text">
              <div class="statistics-section3__flex">
                <div class="statistics-section3__info">
                  <div class="statistics-section3__subtitle">
                    <p class="text-circe-bold-22">ВЫХАЖИВАНИЕ ПРЕЖДЕВРЕМЕННО РОЖДЕННЫХ ДЕТЕЙ</p>
                  </div>
                </div>
              </div>
              <div class="statistics-section3__flex">
                <div class="statistics-section3__description">
                  <p class="text-circe-bold-22 color-green">КАКУЮ ПОМОЩЬ МЫ ОКАЗЫВАЕМ?</p>
                </div>
                <div class="statistics-section3__text">
                  <p class="text-circe-20">Неонатологический стационар готов оказать многоплановую медицинскую помощь самым маленьким и тяжелым пациентам. Он оснащен современным медицинским оборудованием, позволяющим оказывать помощь детям с низкой и экстремально низкой массой тела при рождении, проводить восстановительную терапию в соответствии с международными требованиями, а также осуществлять реабилитационные мероприятия.</p>
                </div>
                <div class="statistics-section3__btn" data-aos="fade-up"  data-aos-duration="1000" @click="routeGoNewborn">
                  <button class="index-btn">Подробнее</button>
                </div>
              </div>
            </div>
          </div>
          <div class="statistics-section3__img" >
            <img src="../assets/img/section7-index.png" alt="Секция 7">
          </div>
        </div>
      </div>
    </section>


    <AppSwiperIndexView class="hide-in-accessibility"/>
    </div>
    <div class="reviews__container ">
    <SectionReviews/>
    </div>
<!--    Секция с новостями-->
    <SectionNews/>
<!--Секция ВАМ_КОМФОРТ-->
    <section class="statistics-section8">
      <div class="statistics-section8__container">
        <div class="statistics-section8__title">
          <p class="text-TTDrugs-Bold-40 color-green">ВАМ-КОМФОРТ</p>
        </div>
        <div class="statistics-section8__block information-dynamic-block">
            <div class="information-dynamic-block__list">
              <div class="information-dynamic-block__item image-block-active">
                <img src="../assets/img/section1-index.png" alt="Забота о вас" class="image-block-active__img">
                  <div class="image-overlay-active">
                    <div class="overlay-content-active">
                      <div class="overlay-content-start-active">
                          <p class="text-circe-28 color-white ">Забота о вас</p>
                      </div>
                    <p class="text-circe-18 color-white ">Мы обеспечиваем маму и малыша всем необходимым для комфортного пребывания в центре: медикаментами, палатной одеждой, 4-х разовым питанием. В отделении есть палаты совместного пребывания родителей и новорожденного: при желании папа или близкие родственники смогут быть с вами круглосуточно</p>
                    </div>
                </div>
              </div>
              <div class="information-dynamic-block__item  image-block-active">
                <img src="../assets/img/section8-1-index.jpg" alt="Забота о вас" class="image-block-active__img">
                <div class="image-overlay-active">
                  <div class="overlay-content-active">
                    <div class="overlay-content-start-active">
                      <p class="text-circe-28 color-white ">Уход после родов</p>
                    </div>
                    <p class="text-circe-18 color-white ">После родов мама и новорожденнный круглосуточно наблюдаются специалистами, маме помогают в уходе за ребенком и консультируют по грудному вскармливанию</p>
                  </div>
                </div>
              </div>
              <div class="information-dynamic-block__item  image-block-active">
                <img src="../assets/img/section8-2-index.png" alt="Забота о вас" class="image-block-active__img">
                <div class="image-overlay-active">
                  <div class="overlay-content-active">
                    <div class="overlay-content-start-active">
                      <p class="text-circe-28 color-white ">Безопасность мамы и малыша</p>
                    </div>
                    <p class="text-circe-18 color-white ">Передовое оборудование, инновационные подходы и, конечно же, высококвалифицированный персонал создают медицинскую безопасность и психологический комфорт</p>
                  </div>
                </div>
              </div>
              <div class="information-dynamic-block__item image-block-active">
                <img src="../assets/img/section8-3-index.jpg" alt="Забота о вас" class="image-block-active__img">
                <div class="image-overlay-active">
                  <div class="overlay-content-active">
                    <div class="overlay-content-start-active">
                      <p class="text-circe-28 color-white ">Выписка из роддома</p>
                    </div>
                    <p class="text-circe-18 color-white ">Передовое оборудование, инновационные подходы и, конечно же, высококвалифицированный персонал создают медицинскую безопасность и психологический комфорт</p>
                  </div>
                </div>
              </div>
            </div>
          <div class="statistics-section8__btn" @click="routeGoChildbirth">
            <button class="index-btn">ПОДРОБНЕЕ О РОДАХ В НАШЕМ ЦЕНТРЕ</button>
          </div>
        </div>
      </div>
    </section>

    <section class="section-map hide-in-accessibility">
      <div class="div-blur"></div>
      <YandexMap :center="[53.305432, 83.759842]" :zoom="18" />
    </section>

  </main>
</template>

<script>
import SectionNews from '@/components/News/SectionNews.vue'
import AppSwiperIndexView from '@/components/Gallery/SwiperIndexView.vue'
// import { VueperSlides, VueperSlide } from 'vueperslides'
// import 'vueperslides/dist/vueperslides.css'
import SectionReviews from '@/components/Rewiews/SectionReviews.vue'
import YandexMap from '@/components/Map/MapYandex.vue'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { mapState } from 'vuex'
import  { actionTypesPaid } from '@/store/modules/paidServices'

export default {
  name: "AppIndex",
  data() {
    return {
      parallax: 1,
      parallaxFixedContent: false,
      slide: {
        id: 1, image: require('../assets/img/cloud-mini.png')
      },
      slideFull: {
        id: 1, image: require('../assets/img/cloud.png')
      }
    }
  },
  computed: {
    ...mapState({
      allData: (state) => state.paidServices.allData,
    }),
  },
  metaInfo() {
    return {
      title: 'Главная | Алтайский краевой клинический перинатальный центр ДАР',
      meta: [
        {
          name: 'description',
          content: 'Подробная информация о Алтайском краевом клиническом перинатальном центре ДАР. Узнайте о наших ценностях и истории центра.'
        },
        {
          name: 'keywords',
          content: 'главная, беременность, роды,Алтайский краевой клинический перинатальный центр'
        }
      ]
    };
  },
  methods: {
    routeGoPregnancy() {
      this.$router.push('/pregnancy')
    },
    routeGoNewborn() {
      this.$router.push('/newborn')
    },
    routeGoECO() {
      this.$router.push('/family-center')
    },
    routeGoChildbirth() {
      this.$router.push('/childbirth')
    },

  },
  components: { YandexMap, SectionReviews, AppSwiperIndexView, SectionNews },
  mounted() {
    AOS.init();
      this.$store.dispatch(actionTypesPaid.getStatistics)
          .then(() => {
            // Дополнительная логика после успешного запроса
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
  }
};
</script>
<style scoped>

</style>
